import { Button, InputNumber } from "antd";
import React, { useState } from "react";
import { setTeamCapacity } from "../../api/billing.service";
import { Modal } from "../modal/modal";
import styles from "./set-account-team-cap-modal.module.scss";

interface SetAccountTeamCapacityModalProps {
  accountId: string;
  defaultValue: number;
  closeModal: (reload?: boolean) => void;
}
export const SetAccountTeamCapacityModal: React.FC<SetAccountTeamCapacityModalProps> =
  ({ accountId, defaultValue, closeModal }) => {
    const [capacity, setCapacity] = useState<number>(defaultValue);

    const onSetCapacityChange = (n: number | undefined) => {
      setCapacity(n || 0);
    };

    const onSetCapacity = () => {
      setTeamCapacity(accountId, {
        capacity
      });
      closeModal(true);
    };

    return (
      <Modal onDismiss={closeModal} className={styles["modal"]} hasCloseIcon>
        <h5 className="mb-3">Set account teammate capacity</h5>

        <label style={{ width: "100%" }}>
          Team Capacity:
          <br />
          <InputNumber
            min={0}
            onChange={onSetCapacityChange}
            value={capacity}
          />
        </label>

        <div className="d-flex align-items-center justify-content-end mt-5">
          <Button
            className="d-block flex-fill mr-3"
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
          <Button className="d-block flex-fill" onClick={onSetCapacity}>
            Set Capacity
          </Button>
        </div>
      </Modal>
    );
  };
