import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AccountListItem, fetchAccountList } from "../../api/account.service";
import { accountQuery } from "../../state/account/account.query";
import {
  accountStore,
  AccountTypeEnum
} from "../../state/account/account.store";
import AdminLayout from "../layouts/adminLayout";
import { ApiAccountsTable } from "./api-accounts-table/api-accounts-table";

const ApiAccounts: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [accountsList, setAccountsList] = useState<AccountListItem[]>();

  const history = useHistory();

  useEffect(() => {
    accountStore.updateParams({
      filter: "account",
      search: "",
      accountType: AccountTypeEnum.API
    });

    fetchAccountList();

    const unsubcribe$ = new Subject();

    accountQuery
      .select()
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ list }) => {
        setLoading(list.loading);
        setAccountsList(list.accountList);
      });

    return () => unsubcribe$.next();
  }, []);

  const onSelect = (accountId: string) => {
    history.push(`/api/${accountId}`);
  };

  const onResetSearch = () => {
    history.push("/api");
  };

  const onSearch = (filter: string, search: string) => {
    let url = `/api/${search}`;

    if (filter !== "accounts") {
      url = url + `?filter=${filter}`;
    }

    history.push(url);
  };

  return (
    <AdminLayout parent="Lists" title="API Account List">
      <ApiAccountsTable
        loading={loading}
        accountList={accountsList}
        onSelect={onSelect}
        onSearch={onSearch}
        onResetSearch={onResetSearch}
      />
    </AdminLayout>
  );
};

export default ApiAccounts;
