import React from "react";
import { UserDetailsDatabaseJob } from "../../../api/user.service";
import { Table } from "antd";

interface MediaJobTableProps {
  jobs: UserDetailsDatabaseJob[];
}

export const MediaJobTable: React.FC<MediaJobTableProps> = ({ jobs }) => {
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status"
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message"
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate"
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate"
    }
  ];

  return (
    <Table
      scroll={{ x: true }}
      dataSource={jobs.map((j) => {
        return { key: j.id, ...j };
      })}
      columns={columns}
    />
  );
};
