import React from "react";
import { useHistory } from "react-router-dom";
import {
  editUserProfile,
  editUserProfilePicture,
  forgotPassword,
  ForgotPasswordParams,
  providerSignIn,
  removeUserProfilePicture,
  resetPassword,
  ResetPasswordParams,
  signIn,
  SignInParams,
  signOut,
  signUp,
  SignUpParams,
  verifyUser
} from "../api";
import config from "../config";
import { User } from "../interfaces/user";
import {
  getStorageKey,
  LocalStorage,
  removeStorage,
  setStorageKey
} from "../services/local-storage.service";

export const saveAuthToLocalstorage = (data: any) => {
  const userAuth = data.data.auth;
  const userDetails = data.data.user;

  setStorageKey(LocalStorage.UserAuth, userAuth);
  setStorageKey(LocalStorage.UserDetails, userDetails);
};

interface QueryResult {
  success: boolean;
  loading: boolean;
  useAlert: boolean;
  alertMessage: string;
  data?: any;
}

interface AuthContext {
  isAuthenticated: () => boolean;
  login: (params: SignInParams) => Promise<QueryResult>;
  oauthLogin: (code: string) => Promise<QueryResult>;
  logout: () => Promise<void>;
  editProfile: (values: any) => Promise<QueryResult>;
  uploadProfilePicture: (file: File) => Promise<QueryResult>;
  removeProfilePicture: () => Promise<QueryResult>;
  signUp: (values: SignUpParams) => Promise<QueryResult>;
  resetPassword: (values: ResetPasswordParams) => Promise<QueryResult>;
  forgotPassword: (values: ForgotPasswordParams) => Promise<QueryResult>;
  getUser: () => User;
  verifyUser: (code: string, id: string) => Promise<QueryResult>;
}

const AuthContext = React.createContext<AuthContext>({
  isAuthenticated: () => false,
  login: () => ({} as any),
  oauthLogin: () => ({} as any),
  logout: () => ({} as any),
  editProfile: () => ({} as any),
  uploadProfilePicture: () => ({} as any),
  removeProfilePicture: () => ({} as any),
  signUp: () => ({} as any),
  resetPassword: () => ({} as any),
  forgotPassword: () => ({} as any),
  getUser: () => ({} as User),
  verifyUser: () => ({} as any)
});

const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const saveUserProfile = (user: any) => {
    const userDetails = getStorageKey(LocalStorage.UserDetails);
    const newUserDetails = { ...userDetails, ...user };

    setStorageKey(LocalStorage.UserDetails, newUserDetails);
  };

  const handleCatchError = (error: any): QueryResult => {
    const message =
      error.response && error.response.data ? error.response.data.message : "";

    return {
      success: false,
      loading: false,
      useAlert: true,
      alertMessage: message || error.message
    };
  };

  const handleSuccess = (data: any, status: number): QueryResult => {
    if (status !== 200) {
      throw new Error(data.message);
    }

    return {
      success: true,
      loading: false,
      useAlert: false,
      alertMessage: "",
      data: data
    };
  };

  // Context functions
  const isAuthenticated = () => {
    const userAuth = getStorageKey(LocalStorage.UserAuth);

    if (!userAuth) {
      return false;
    }

    return !!userAuth.accessToken;
  };

  const login = async (params: SignInParams): Promise<QueryResult> => {
    try {
      const { data, status } = await signIn(params);

      saveAuthToLocalstorage(data);

      return handleSuccess(data, status);
    } catch (e) {
      return handleCatchError(e);
    }
  };

  const oauthLogin = async (code: string) => {
    try {
      const { data, status } = await providerSignIn({
        code,
        redirect_uri: config.oauth.google
      });

      saveAuthToLocalstorage(data);

      return handleSuccess(data, status);
    } catch (e) {
      return handleCatchError(e);
    }
  };

  const logout = async (): Promise<void> => {
    try {
      removeStorage();
      await signOut();
    } catch (e) {}

    history.push("/");
  };

  const editProfile = async (values: any): Promise<QueryResult> => {
    try {
      const { data, status } = await editUserProfile(values);

      const result = handleSuccess(data, status);

      saveUserProfile(data.data.user);

      return result;
    } catch (e) {
      return handleCatchError(e);
    }
  };

  const uploadProfilePicture = async (file: File): Promise<QueryResult> => {
    try {
      const { data, status } = await editUserProfilePicture(file);

      const result = handleSuccess(data, status);

      saveUserProfile(data.data.user);

      return result;
    } catch (e) {
      return handleCatchError(e);
    }
  };

  const removeProfilePicture = async (): Promise<QueryResult> => {
    try {
      const { data, status } = await removeUserProfilePicture();

      const result = handleSuccess(data, status);

      saveUserProfile(data.data.user);

      return result;
    } catch (e) {
      return handleCatchError(e);
    }
  };

  const handleSignUp = async (values: SignUpParams): Promise<QueryResult> => {
    try {
      const { data, status } = await signUp(values);
      return handleSuccess(data, status);
    } catch (e) {
      return handleCatchError(e);
    }
  };

  const handleResetPassword = async (params: ResetPasswordParams) => {
    try {
      const { data, status } = await resetPassword(params);
      return handleSuccess(data, status);
    } catch (e) {
      return handleCatchError(e);
    }
  };

  const handleForgotPassword = async (params: ForgotPasswordParams) => {
    try {
      const { data, status } = await forgotPassword(params);
      return handleSuccess(data, status);
    } catch (e) {
      return handleCatchError(e);
    }
  };

  const getUser = (): User => {
    return getStorageKey(LocalStorage.UserDetails);
  };

  const handleVerifyUser = async (
    code: string,
    id: string
  ): Promise<QueryResult> => {
    try {
      const { data, status } = await verifyUser({ code, id });
      return handleSuccess(data, status);
    } catch (e) {
      return handleCatchError(e);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        oauthLogin,
        editProfile,
        uploadProfilePicture,
        removeProfilePicture,
        signUp: handleSignUp,
        logout,
        resetPassword: handleResetPassword,
        forgotPassword: handleForgotPassword,
        getUser,
        verifyUser: handleVerifyUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
const AuthConsumer = AuthContext.Consumer;
export { AuthProvider, AuthConsumer, AuthContext };
