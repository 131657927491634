import React from "react";
import { WithLayout } from ".";

interface AdminLayoutProps {
  parent: string;
  title: string;
  buttons?: JSX.Element;
}
const AdminLayout: React.FC<AdminLayoutProps> = ({
  parent,
  title,
  buttons,
  children
}) => {
  return (
    <div className="main-content" style={{ padding: "15px" }}>
      <div className="container">
        <div className="row">
          <div
            className="col-12"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "20px 0"
            }}
          >
            <div className="title">
              <span className="parent">{parent}</span>
              <h1>{title}</h1>
            </div>
            {buttons}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default WithLayout(AdminLayout);
