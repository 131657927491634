import { Store, StoreConfig } from "@datorama/akita";
import { IDeal } from "../../../interfaces/deal";

export interface DealListParamsState {
  filter?: string;
  search?: string;
}

export interface DealListState {
  deals: IDeal[];
  loading: boolean;
  params: DealListParamsState;
}

@StoreConfig({ name: "deal-list" })
export class DealListStore extends Store<DealListState> {
  constructor() {
    super({});
  }

  updateParams(params: DealListParamsState) {
    this.update((s) => ({
      ...s,
      params: {
        ...s.params,
        ...params
      }
    }));
  }

  updateState(data: Partial<DealListState>): void {
    this.update((s) => ({
      ...s,
      data
    }));
  }
}

export const dealListStore = new DealListStore();
