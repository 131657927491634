import { DateTime } from "luxon";
import { IAccount } from "./account";

export interface IDeal {
  id: string;
  account?: IAccount;
  accountId: string;
  invoiceId: string;
  status: DealStatus;
  description: string;
  isPaid: boolean;
  isTrial: boolean;
  teamCapacity: number;
  creditSeconds: number;
  createdAt: DateTime;
  updatedAt: DateTime;
  startedAt?: DateTime;
  endingAt?: DateTime;
}

export interface IDealWithInvoice extends IDeal {
  invoice: {
    id: string;
  };
}

export enum DealStatus {
  Draft = "DRAFT",
  Active = "ACTIVE",
  Archived = "ARCHIVED",
  Void = "VOID",
  Deleted = "DELETED"
}

export interface FinaliseDealParams {
  accountId: string;
  dealId: string;
  // startedAt: DateTime;
  endingAt: DateTime;
  isTrial: boolean;
}

export interface CreateInvoiceParams {
  applicationFeeAmount?: number;
  autoAdvance?: boolean;
  customFields?: {
    name: string;
    value: string;
  }[];
  defaultPaymentMethod?: string;
  defaultSource?: string;
  defaultTaxRates?: string[];
  description: string;
  discounts?: Discounts;
  footer?: string;
  metadata?: Metadata;
  statementDescriptor?: string;
  taxPercent?: number;
  items: CreateInvoiceItemParams[];
}

export interface CreateInvoiceItemParams {
  amount: number;
  currency: string;
  description: string;
  discountable?: boolean;
  discounts?: Discounts;
  metadata?: Metadata;
  quantity: number;
  taxRates?: string[];
  unitAmount: number;
}

export interface UpdateInvoiceParams
  extends Omit<CreateInvoiceParams, "customer" | "items"> {
  newItems: CreateInvoiceItemParams[];
  updateItems: UpdateInvoiceItemParams[];
  deleteItems: string[];
}

export interface UpdateInvoiceItemParams {
  id: string;
  amount: number;
  description: string;
  discountable?: boolean;
  discounts?: Discounts;
  metadata?: Metadata;
  quantity: number;
  taxRates?: string[];
  unitAmount: number;
}

export interface TaxRate {
  id: string;
  region: string;
  name: string;
  description: string;
  percentage: number;
  inclusive: boolean;
  metadata: Metadata;
}

export interface Coupon {
  id: string;
  name: string;
  valid: boolean;
  currency: string;
  amount_off: number;
  percent_off: number;
  duration: "forever" | "once" | "repeating";
  duration_in_months: number;
  applies_to: { products: string[] };
  metadata: Metadata;
}

export type Discounts = {
  coupon?: string;
  discount?: string;
}[];

export type Metadata = { [name: string]: string | number | null };
