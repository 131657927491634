import { Button, notification } from "antd";
import React, { useState } from "react";
import axios from "axios";
import config from "../../../config";
import { getAccessToken } from "../../../api/auth.service";
import { EN } from "../../../assets/i18n/en";
import { MediaDetails } from "../../../api/media.service";
import { sortBy } from "lodash";
import { JobType, MediaStatus } from "../../../interfaces/media";

interface MediaModalBurnProps {
  mediaDetails: MediaDetails | undefined;
}
export const MediaModalBurn: React.FC<MediaModalBurnProps> = ({
  mediaDetails
}) => {
  const [isBurning, setIsBurning] = useState(false);

  const sortedJobs = sortBy(mediaDetails?.jobs, (x) => x.startDate).reverse();

  const successfulTranscribeJob = sortedJobs.find(
    (x) => x.type === JobType.Transcribe && x.status === MediaStatus.Ready
  );

  const onClick = async () => {
    try {
      setIsBurning(true);

      const result = await axios.post(
        `${config.apiUrl}/api/v1/admin/transcriptions/media/${mediaDetails?.mediaId}/burn`,
        {},
        {
          headers: { "x-access-token": await getAccessToken() }
        }
      );

      if (result.status !== 200) {
        notification.error({
          message: "Burn failed",
          description: result?.data?.message || EN.error.defaultMessage,
          duration: 10
        });
      }

      notification.info({
        message: "Burn started",
        duration: 10
      });
    } catch (err) {
      console.log(err);
      notification.error({
        message: "Burn failed",
        description: (err as any)?.message || EN.error.defaultMessage,
        duration: 10
      });
    } finally {
      setIsBurning(false);
    }
  };

  return !mediaDetails || !successfulTranscribeJob ? null : (
    <Button
      type="primary"
      className="btn btn-primary float-left"
      onClick={onClick}
      loading={isBurning}
      disabled={isBurning}
    >
      {isBurning ? "Burning" : `Burn`}
    </Button>
  );
};
