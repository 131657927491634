import { Table } from "antd";
import React, { useState } from "react";
import { DealStatus, IDeal } from "../../../interfaces/deal";
import { formatDate } from "../../../utils/date-formatter";

interface AccountDealListProps {
  accountId: string | undefined;
  deals?: IDeal[];
  loading: boolean;
  onSelect: (value: { accountId: string; dealId: string }) => void;
}

export const AccountDealList: React.FC<AccountDealListProps> = ({
  accountId,
  deals,
  loading,
  onSelect
}) => {
  const [hideDeleted, setHideDeleted] = useState(true);
  const [hideVoid, setHideVoid] = useState(true);

  if (loading || !accountId) {
    return <span>Loading Deals</span>;
  }

  return (
    <>
      <Table
        size="small"
        bordered
        loading={loading}
        dataSource={(deals || []).filter((d) => {
          if (hideDeleted && d.status === DealStatus.Deleted) {
            return false;
          }
          if (hideVoid && d.status === DealStatus.Void) {
            return false;
          }
          return true;
        })}
        pagination={false}
        rowKey="id"
        onRow={(record) => ({
          onClick: () => {
            onSelect({ accountId, dealId: record.id });
          }
        })}
        columns={[
          {
            title: "ID",
            dataIndex: "id",
            key: "id"
          },
          {
            title: "Description",
            dataIndex: "description",
            key: "description"
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status"
          },
          {
            title: "Paid?",
            dataIndex: "isPaid",
            key: "isPaid",
            render: (value) => {
              return <>{value || false ? "YES" : "NO"}</>;
            }
          },
          {
            title: "Trial?",
            dataIndex: "isTrial",
            key: "isTrial",
            render: (value: boolean) => {
              return <>{value || false ? "YES" : "NO"}</>;
            }
          },
          {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (value) => {
              return <>{formatDate(value)}</>;
            }
          },
          {
            title: "Started At",
            dataIndex: "startedAt",
            key: "startedAt",
            render: (value) => {
              return <>{formatDate(value)}</>;
            }
          },
          {
            title: "Ending At",
            dataIndex: "endingAt",
            key: "endingAt",
            render: (value) => {
              return <>{formatDate(value)}</>;
            }
          }
        ]}
      />
      <label style={{ width: "100%" }}>
        Hide deleted:
        <input
          type="checkbox"
          defaultChecked={hideDeleted}
          onChange={(e) => setHideDeleted(!hideDeleted)}
        />
      </label>
      <label style={{ width: "100%" }}>
        Hide void:
        <input
          type="checkbox"
          defaultChecked={hideVoid}
          onChange={(e) => setHideVoid(!hideVoid)}
        />
      </label>
    </>
  );
};
