import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { takeUntil } from "rxjs/internal/operators/takeUntil";
import { Subject } from "rxjs/internal/Subject";
import {
  fetchUserList,
  fetchUserGroups,
  UserListItem
} from "../../api/user.service";
import { usersQuery } from "../../state/users/users.query";
import { UserListParamsState, usersStore } from "../../state/users/users.store";
import AdminLayout from "../layouts/adminLayout";
import { UsersTable } from "./users-table/users-table";
import { USERS_PATH } from "../../routes";
import { getSingleQueryStringParameterValue } from "../../utils/querystring";
import { ModalContext, ModalType } from "../../contexts/modal.context";
import { UsersModal } from "./users-modal/users-modal";

export interface UserRouteParams {
  id: string;
}

const Users: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState<UserListItem[]>();
  const [params, setParams] = useState<UserListParamsState>();
  const { showModal, hideModal } = React.useContext(ModalContext);

  let { id } = useParams<UserRouteParams>();

  const filterQueryStringParameter = getSingleQueryStringParameterValue(
    window.location.search,
    "filter"
  );
  const searchQueryStringParameter = getSingleQueryStringParameterValue(
    window.location.search,
    "search"
  );

  const history = useHistory();

  const onSelect = (username: string) =>
    showModal(
      ModalType.User,
      <UsersModal
        username={username}
        onClose={() => {
          hideModal(ModalType.User);
          fetchUserList();
        }}
      />
    );
  const onResetSearch = () => {
    usersStore.updatePagination(undefined);
    history.push(USERS_PATH);
  };

  const onSearch = (filter: string, search: string) => {
    usersStore.updatePagination(undefined);

    const url =
      filter === "username"
        ? `${USERS_PATH}/${search}`
        : `${USERS_PATH}?filter=${filter}&search=${search}`;

    history.push(url);
  };

  useEffect(() => {
    let params = {
      filter: "",
      search: ""
    };

    if (id != null) {
      params.filter = "username";
      params.search = id;
    } else {
      if (
        filterQueryStringParameter !== null &&
        searchQueryStringParameter !== null
      ) {
        params.filter = filterQueryStringParameter;
        params.search = searchQueryStringParameter;
      }
    }

    setParams(params);

    usersStore.updateParams(params);

    fetchUserList();
    fetchUserGroups();

    const unsubcribe$ = new Subject();
    usersQuery
      .select()
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ list, params }) => {
        setLoading(list.loading);
        setUserList(list.userList);

        setParams(params);
        if (
          params.filter === "username" &&
          list.userList.length === 1 &&
          params.search
        ) {
          // onSelect(params.search);
        }
      });

    return () => unsubcribe$.next();
  }, [searchQueryStringParameter, id, filterQueryStringParameter]);

  return (
    <AdminLayout parent="Lists" title="Users List">
      <UsersTable
        loading={loading}
        params={params}
        userList={userList}
        onSelect={onSelect}
        onSearch={onSearch}
        onResetSearch={onResetSearch}
      />
    </AdminLayout>
  );
};
export default Users;
