import React from "react";
import classNames from "classnames";
import { uniqueId } from "lodash";

import {
  NewTableColumn,
  NewTableItem,
  NewTableProvider,
  useNewTableContext
} from "./new-table.context";
import { ArrowDownLineIcon, ArrowUpLineIcon } from "../icons/icons";

type ColumnWidth = number | string | undefined | null;
interface ContainerProps {
  columns: NewTableColumn[];
  items: NewTableItem[];
  columnsWidth?: ColumnWidth[];
  children: React.ReactNode;
  className?: string;
}
const Container: React.FC<ContainerProps> = ({
  columns,
  items,
  children,
  className
}) => {
  const parsedColumnsWidth: ColumnWidth[] = columns.map((column) => {
    if (column.width === undefined || column.width === null) {
      return "1fr";
    } else if (typeof column.width === "number") {
      return `${column.width}px`;
    }

    return column.width;
  });

  const columnWidthVar = parsedColumnsWidth.join(" ");

  return (
    <NewTableProvider columns={columns} items={items}>
      <div
        className={classNames(
          className,
          "tw-grid tw-grid-cols-[var(--table-columns-width)] tw-rounded-[8px] tw-border tw-border-neutral-200"
        )}
        style={
          {
            "--table-columns": columns.length,
            "--table-columns-width": columnWidthVar
          } as React.CSSProperties
        }
      >
        {children}
      </div>
    </NewTableProvider>
  );
};

interface NewHeaderProps {
  children: (column: NewTableColumn[]) => React.ReactElement[];
}
const Header: React.FC<NewHeaderProps> = ({ children }) => {
  const { columns } = useNewTableContext();

  return <>{children?.(columns)}</>;
};

interface HeaderCellProps extends SortingProps, TableComponentProps {
  sortable?: boolean;
  onClickSort?: () => void;
}
const HeaderCell: React.FC<HeaderCellProps> = ({
  sortable = false,
  isSorting = false,
  onClickSort,
  sortDirection,
  children,
  className,
  onClick,
  ...props
}) => {
  return (
    <div
      className={classNames(
        "tw-flex tw-h-12 tw-items-center tw-gap-1 tw-p-2 tw-text-sm tw-font-medium tw-text-neutral-900",
        { "tw-group tw-cursor-pointer": sortable },
        className
      )}
      onClick={(e) => {
        if (sortable) onClickSort?.();
        onClick?.(e);
      }}
      {...props}
    >
      {children}
      {sortable && (
        <Sorting isSorting={isSorting} sortDirection={sortDirection} />
      )}
    </div>
  );
};

interface RowProps {
  children: (
    item: NewTableItem,
    columns: NewTableColumn[],
    i: number
  ) => React.ReactNode;
}
const Rows: React.FC<RowProps> = ({ children }) => {
  const { items, columns } = useNewTableContext();

  return (
    <>
      {items.map((item: NewTableItem, i: number) => (
        <React.Fragment key={i}>{children(item, columns, i)} </React.Fragment>
      ))}
    </>
  );
};

type TableComponentProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

interface SortingProps {
  isSorting?: boolean;
  sortDirection?: "asc" | "desc";
}
const Sorting: React.FC<SortingProps> = ({ isSorting, sortDirection }) => {
  if (!isSorting) {
    return (
      <ArrowDownLineIcon className="tw-h-4 tw-w-4 tw-opacity-0 group-hover:tw-opacity-50" />
    );
  }

  if (sortDirection === "asc") {
    return (
      <ArrowUpLineIcon className="tw-h-4 tw-w-4 group-hover:tw-opacity-50" />
    );
  }

  return (
    <ArrowDownLineIcon className="tw-h-4 tw-w-4 group-hover:tw-opacity-50" />
  );
};

type CellProps = {
  hoverRowClassName?: string;
  children?: React.ReactNode;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;
const Cell: React.FC<CellProps> = ({
  className,
  children,
  hoverRowClassName,
  ...props
}) => {
  const { registerCell, unregisterCell, setHoveredCell, hoveredRowCells } =
    useNewTableContext();
  const [id] = React.useState(uniqueId("cell-"));

  React.useEffect(() => {
    registerCell(id);

    return () => {
      unregisterCell(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isHovered = React.useMemo(() => {
    return hoveredRowCells.includes(id);
  }, [hoveredRowCells, id]);

  return (
    <div
      className={classNames(
        "tw-flex tw-items-center tw-gap-1 tw-border-t tw-border-neutral-200 tw-p-2 tw-text-sm tw-text-neutral-700",
        isHovered && hoverRowClassName,
        className
      )}
      {...props}
      onMouseOver={(e) => {
        setHoveredCell(id);
        props?.onMouseOver?.(e);
      }}
      onMouseLeave={(e) => {
        setHoveredCell(null);
        props?.onMouseLeave?.(e);
      }}
    >
      {children}
    </div>
  );
};

interface SummaryProps extends TableComponentProps {
  visible?: boolean;
}
const Summary: React.FC<SummaryProps> = ({
  className,
  onClick,
  children,
  ...props
}) => {
  return (
    <div
      className={classNames(
        "tw-col-span-full tw-flex tw-flex-col tw-gap-2",
        className
      )}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(e);
      }}
      {...props}
    >
      {children}
    </div>
  );
};

export const NewTable = Object.assign(Container, {
  Header,
  HeaderCell,
  Rows,
  Cell,
  Summary
});
