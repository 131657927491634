import React from "react";
import config from "../../../config";
import { useLocation } from "react-router-dom";
import "./google-button.scss";

export const GoogleButton: React.FC = ({ children }) => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const state = query.get("redirect");

  const href = `${config.apiUrl}/api/v1/admin/auth/google/authorize?redirect_uri=${config.oauth.google}&state=${state}`;

  return (
    <a href={href}>
      <div className="customGPlusSignIn">
        <span className="icon"></span>
        <span className="buttonText">{children}</span>
      </div>
    </a>
  );
};
