import { Button, InputNumber } from "antd";
import React, { useState } from "react";
import { setCredit } from "../../api/billing.service";
import { IAccountCredit } from "../../interfaces/account";
import { parseSecondsToText } from "../../utils/time";
import { Modal } from "../modal/modal";
import styles from "./set-account-credit-modal.module.scss";

interface SetAccountCreditModalProps {
  accountId: string;
  defaultValue: IAccountCredit;
  closeModal: (reload?: boolean) => void;
}
export const SetAccountCreditModal: React.FC<SetAccountCreditModalProps> = ({
  accountId,
  defaultValue,
  closeModal
}) => {
  return (
    <Modal onDismiss={closeModal} className={styles["modal"]} hasCloseIcon>
      <SetAccountCreditContainer
        accountId={accountId}
        defaultValue={defaultValue}
        closeModal={closeModal}
      ></SetAccountCreditContainer>
    </Modal>
  );
};

interface SetAccountCreditModalProps {
  accountId: string;
  defaultValue: IAccountCredit;
  closeModal: (reload?: boolean) => void;
}
export const SetAccountCreditContainer: React.FC<SetAccountCreditModalProps> =
  ({ accountId, defaultValue, closeModal }) => {
    const [creditFreeSeconds, setCreditFreeSeconds] = useState<number>(
      defaultValue.creditFreeSeconds
    );
    const [creditPaidSeconds, setCreditPaidSeconds] = useState<number>(
      defaultValue.creditPaidSeconds
    );
    const [creditPaygSeconds, setCreditPaygSeconds] = useState<number>(
      defaultValue.creditPaygSeconds
    );
    const [creditExtraSeconds, setCreditExtraSeconds] = useState<number>(
      defaultValue.creditExtraSeconds
    );

    const onCreditFreeSecondsChange = (n: number | undefined) => {
      setCreditFreeSeconds(n || 0);
    };
    const onCreditPaidSecondsChange = (n: number | undefined) => {
      setCreditPaidSeconds(n || 0);
    };
    const onCreditPaygSecondsChange = (n: number | undefined) => {
      setCreditPaygSeconds(n || 0);
    };
    const onCreditExtraSecondsChange = (n: number | undefined) => {
      setCreditExtraSeconds(n || 0);
    };

    const onSetCredit = () => {
      setCredit(accountId, {
        creditFreeSeconds,
        creditPaidSeconds,
        creditPaygSeconds,
        creditExtraSeconds
      });
      closeModal(true);
    };

    return (
      <>
        <h5 className="mb-3">Set account credits</h5>

        <label style={{ width: "100%" }}>
          Free credit: ({parseSecondsToText(creditFreeSeconds)})
          <br />
          <InputNumber
            min={0}
            onChange={onCreditFreeSecondsChange}
            value={creditFreeSeconds}
          />
        </label>
        <label style={{ width: "100%" }}>
          Paid credit: ({parseSecondsToText(creditPaidSeconds)})
          <br />
          <InputNumber
            min={0}
            onChange={onCreditPaidSecondsChange}
            value={creditPaidSeconds}
          />
        </label>
        <label style={{ width: "100%" }}>
          PAYG credit: ({parseSecondsToText(creditPaygSeconds)})
          <br />
          <InputNumber
            min={0}
            onChange={onCreditPaygSecondsChange}
            value={creditPaygSeconds}
          />
        </label>
        <label style={{ width: "100%" }}>
          Extra credit: ({parseSecondsToText(creditExtraSeconds)})
          <br />
          <InputNumber
            min={0}
            onChange={onCreditExtraSecondsChange}
            value={creditExtraSeconds}
          />
        </label>

        <div className="d-flex align-items-center justify-content-end mt-5">
          <Button
            className="d-block flex-fill mr-3"
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
          <Button className="d-block flex-fill" onClick={onSetCredit}>
            Set Credit
          </Button>
        </div>
      </>
    );
  };
