import numeral from "numeral";

export interface FormatOptions {
  format?: string;
  factor?: number;
}
export const format = (value?: number, options?: FormatOptions) => {
  const format = options?.format ?? "0,0";
  const factor = options?.factor ?? 1;

  if (!value) {
    return numeral(0).format(format);
  }

  const factoredValue = value / factor;

  return numeral(factoredValue).format(format);
};
