import { Table } from "antd";
import React from "react";
import { JobDetails } from "../../../api/job.service";
import { MediaDetails, MediaJob } from "../../../api/media.service";
import { MediaModalJobCancel } from "./media-modal-job-cancel";

interface MediaModalJobDetailsProps {
  mediaDetails: MediaDetails | undefined;
  handleCancel: (job: JobDetails) => void;
}

export const MediaModalJobDetails: React.FC<MediaModalJobDetailsProps> = ({
  mediaDetails,
  handleCancel
}) => {
  const jobs = mediaDetails?.jobs || [];

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status"
    },
    {
      title: "Start date",
      dataIndex: "startDate",
      key: "startDate"
    },
    {
      title: "End date",
      dataIndex: "endDate",
      key: "endDate"
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message"
    },
    {
      title: "Copy Process",
      key: "process",
      dataIndex: "process",
      width: "20px",
      render: (value: string, row: MediaJob) => {
        if (row.type === "BURN") {
          return (
            <p
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigator.clipboard.writeText(JSON.stringify(value))
              }
            >
              Copy
            </p>
          );
        }
        return <></>;
      }
    },
    {
      dataIndex: "id",
      key: "start_date", //to avoid duplicate key error
      width: "150px",
      render: (value: string, row: MediaJob) => {
        return <MediaModalJobCancel job={row} handleCancel={handleCancel} />;
      }
    }
  ];

  return mediaDetails ? (
    <>
      <Table
        rowKey="id"
        dataSource={jobs.map((f) => {
          return { key: f.id, ...f };
        })}
        columns={columns}
      />
    </>
  ) : (
    <span>"Details missing"</span>
  );
};
