import React, { useState, useEffect } from "react";
import { Button, Spin, Tabs } from "antd";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { accountQuery } from "../../../state/account/account.query";
import {
  AccountDetails,
  fetchAccountDetails,
  switchAccountType
} from "../../../api/account.service";
import { AccountModalAccountDetails } from "./account-modal-account-details";
import { AccountModalTransactionDetails } from "./account-modal-transaction-details";
import { ModalContext, ModalType } from "../../../contexts/modal.context";
import { NewTrialModal } from "../../new-trial-modal/new-trial-modal";
import { Modal } from "../../modal/modal";
import styles from "./account-modal.module.scss";
import { fetchProducts } from "../../../api/billing.service";
import { productListQuery } from "../../../state/billing/product-list/product-list.query";
import { SetAccountCreditModal } from "../../set-account-credit-modal/set-account-credit-modal";
import { DestroyAccountModal } from "../../destroy-account-modal/destroy-account-modal";
import { AccountType } from "../../../interfaces/account";
import { AccountModalAssetDetails } from "./account-modal-asset-details";

interface AccountModalProps {
  accountId?: string;
  onClose: () => void;
}
export const AccountModal: React.FC<AccountModalProps> = ({
  accountId,
  onClose
}) => {
  const { TabPane } = Tabs;
  const title = `Account details (${accountId})`;

  const [loading, setLoading] = useState(false);
  // const [productsLoading, setProductsLoading] = useState(false);
  const [products, setProducts] = useState<any[]>([]);
  const [accountDetails, setAccountDetails] = useState<AccountDetails>();
  const { showModal, hideModal } = React.useContext(ModalContext);

  useEffect(() => {
    if (!accountId) {
      return;
    }

    fetchAccountDetails(accountId);
    fetchProducts();

    const unsubcribe$ = new Subject();
    accountQuery
      .select()
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ details }) => {
        setLoading(details.loading);
        setAccountDetails(details.accountDetails);
      });

    productListQuery
      .select()
      .pipe(takeUntil(unsubcribe$))
      .subscribe((res) => {
        // setProductsLoading(res?.loading);
        setProducts(res?.products);
      });

    return () => unsubcribe$.next();
  }, [accountId]);

  return (
    <Modal onDismiss={onClose} className={styles["modal"]} hasCloseIcon>
      <h5 className="mb-3">{title}</h5>
      {loading ? (
        <Spin />
      ) : (
        <Tabs defaultActiveKey="1">
          <TabPane tab="Account" key="1">
            <AccountModalAccountDetails accountDetails={accountDetails} />
          </TabPane>
          <TabPane tab="Transactions" key="2">
            <AccountModalTransactionDetails
              transactions={accountDetails?.transactions || []}
            />
          </TabPane>
          <TabPane tab="Assets" key="3">
            <AccountModalAssetDetails assets={accountDetails?.assets || []} />
          </TabPane>
        </Tabs>
      )}
      <div className="d-flex align-items-center justify-content-end mt-5">
        {accountDetails && (
          <>
            <Button
              type="primary"
              className="btn btn-primary"
              onClick={() => {
                if (accountId) {
                  switchAccountType(accountId, AccountType.Business);
                  onClose();
                }
              }}
            >
              Switch to Business
            </Button>
            <Button
              type="primary"
              className="btn btn-primary"
              onClick={() =>
                showModal(
                  ModalType.SetAccountCredit,
                  <SetAccountCreditModal
                    accountId={accountDetails.accountId}
                    defaultValue={accountDetails}
                    closeModal={(reload?: boolean) => {
                      hideModal(ModalType.SetAccountCredit);
                      if (reload) {
                        fetchAccountDetails(accountDetails.accountId);
                      }
                    }}
                  />
                )
              }
            >
              Set Credit
            </Button>
            <Button
              type="primary"
              className="btn btn-danger"
              onClick={() =>
                showModal(
                  ModalType.DeleteAccount,
                  <DestroyAccountModal
                    accountId={accountDetails.accountId}
                    defaultValue={accountDetails}
                    closeModal={(reload?: boolean) => {
                      hideModal(ModalType.DeleteAccount);
                    }}
                  />
                )
              }
            >
              Delete Account
            </Button>
          </>
        )}
        {accountDetails && products && (
          <Button
            type="primary"
            className="btn btn-primary"
            onClick={() =>
              showModal(
                ModalType.NewTrial,
                <NewTrialModal
                  products={products}
                  accountId={accountDetails.accountId}
                  closeModal={(reload?: boolean) => {
                    hideModal(ModalType.NewTrial);
                    if (reload) {
                      fetchAccountDetails(accountDetails.accountId);
                    }
                  }}
                />
              )
            }
          >
            Add Trial
          </Button>
        )}
        <Button
          type="primary"
          className="btn btn-primary"
          onClick={() => onClose()}
        >
          OK
        </Button>
      </div>
    </Modal>
  );
};
