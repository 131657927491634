import { Drawer } from "antd";
import React from "react";
import MediaQuery from "react-responsive";

interface SideDrawerProps {
  toggleDrawer: () => void;
  hasSideDrawer: boolean;
}
export const SideDrawer: React.FC<SideDrawerProps> = ({
  hasSideDrawer,
  toggleDrawer,
  children
}) => {
  const title = "Upload a new video";

  return (
    <>
      <MediaQuery maxDeviceWidth={767}>
        <Drawer
          width={300}
          title={title}
          onClose={toggleDrawer}
          visible={hasSideDrawer}
        >
          {children}
        </Drawer>
      </MediaQuery>
      <MediaQuery minDeviceWidth={768}>
        <Drawer
          width={720}
          title={title}
          onClose={toggleDrawer}
          visible={hasSideDrawer}
        >
          {children}
        </Drawer>
      </MediaQuery>
    </>
  );
};
