import { Store, StoreConfig } from "@datorama/akita";
import { IDeal } from "../../../interfaces/deal";

export interface DealModalState {
  loading: boolean;
  params?: { accountId: string; dealId: string };
  deal?: IDeal;
}

@StoreConfig({ name: "deal-modal" })
export class DealModalStore extends Store<DealModalState> {
  constructor() {
    super({
      loading: false
    });
  }

  updateState(data: Partial<DealModalState>): void {
    this.update((s) => ({
      ...s,
      data
    }));
  }
}

export const dealModalStore = new DealModalStore();
