import { Query } from "@datorama/akita";
import {
  usersStore,
  UsersStore,
  UsersState,
  UserListState,
  UserDetailsState,
  UserGroupsState,
  UserListParamsState
} from "./users.store";

export class UsersQuery extends Query<UsersState> {
  get list(): UserListState {
    return this.getValue().list;
  }

  get group(): UserGroupsState {
    return this.getValue().group;
  }

  get details(): UserDetailsState {
    return this.getValue().details;
  }

  get userListParams(): UserListParamsState {
    return this.getValue().params;
  }

  constructor(protected store: UsersStore) {
    super(store);
  }
}

export const usersQuery = new UsersQuery(usersStore);
