import React, { useState, useEffect } from "react";
import { Button, Spin } from "antd";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  deleteDeal,
  fetchDealDetails,
  markAsPaidDeal,
  voidDeal
} from "../../../api/deal.service";
import { DealStatus, IDeal } from "../../../interfaces/deal";
import { dealModalQuery } from "../../../state/deal/deal-modal/deal-modal.query";
import { FinaliseDealModal } from "../finalise-deal-modal/finalise-deal-modal";
import { Modal } from "../../modal/modal";
import { ModalContext, ModalType } from "../../../contexts/modal.context";
import styles from "./deal-modal.module.scss";

interface DealModalProps {
  params?: { accountId: string; dealId: string };
  onClose: (reload?: boolean) => void;
}
export const DealModal: React.FC<DealModalProps> = ({ params, onClose }) => {
  const { showModal, hideModal } = React.useContext(ModalContext);

  const title = `Deal details (${params?.dealId})`;

  const [loading, setLoading] = useState(false);
  const [deal, setDeal] = useState<IDeal>();

  useEffect(() => {
    if (!params) {
      return;
    }

    fetchDealDetails(params);

    const unsubcribe$ = new Subject();
    dealModalQuery
      .select()
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ deal, loading }) => {
        setLoading(loading);
        setDeal(deal);
      });

    return () => unsubcribe$.next();
  }, [params]);

  const onFinalise = (params: { accountId: string; dealId: string }) =>
    showModal(
      ModalType.FinaliseDeal,
      <FinaliseDealModal
        params={params}
        onClose={(reload?: boolean) => {
          hideModal(ModalType.FinaliseDeal);
          if (reload) {
            fetchDealDetails(params);
          }
        }}
      />
    );

  const draftButtons = (
    <>
      <Button
        type="primary"
        className="btn btn-primary"
        onClick={() => onClose()}
      >
        Edit
      </Button>
      <Button
        type="primary"
        className="btn btn-primary"
        onClick={() =>
          onFinalise({
            accountId: deal?.accountId || "",
            dealId: deal?.id || ""
          })
        }
      >
        Finalise
      </Button>
      <Button
        type="primary"
        className="btn btn-primary"
        onClick={() => {
          deleteDeal({
            accountId: deal?.accountId || "",
            dealId: deal?.id || ""
          });
          onClose(true);
        }}
      >
        Delete
      </Button>
    </>
  );

  const activeButtons = deal?.isPaid ? (
    <>
      <Button
        type="primary"
        className="btn btn-primary"
        onClick={() => {
          voidDeal({
            accountId: deal?.accountId || "",
            dealId: deal?.id || ""
          });
          onClose(true);
        }}
      >
        Void
      </Button>
    </>
  ) : (
    <>
      <Button
        type="primary"
        className="btn btn-primary"
        onClick={() => {
          markAsPaidDeal({
            accountId: deal?.accountId || "",
            dealId: deal?.id || ""
          });
          onClose(true);
        }}
      >
        Mark as Paid
      </Button>
      <Button
        type="primary"
        className="btn btn-primary"
        onClick={() => {
          voidDeal({
            accountId: deal?.accountId || "",
            dealId: deal?.id || ""
          });
          onClose(true);
        }}
      >
        Void
      </Button>
    </>
  );

  return (
    <Modal onDismiss={onClose} className={styles["modal"]} hasCloseIcon>
      <h5 className="mb-3">{title}</h5>
      {loading ? (
        <Spin />
      ) : (
        <>
          <h6>Description: {deal?.description}</h6>
          <h6>Status: {deal?.status}</h6>
          <h6>
            Invoice Id:
            <a
              href={`https://dashboard.stripe.com/invoices/${deal?.invoiceId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {deal?.invoiceId}
            </a>
          </h6>
          <h6>Is Paid: {deal?.isPaid || false ? "YES" : "NO"}</h6>
          <h6>Is Trial: {deal?.isTrial || false ? "YES" : "NO"}</h6>
          <h6>Team capacity: {deal?.teamCapacity}</h6>
          <h6>Credit seconds: {deal?.creditSeconds}</h6>
          <h6>Starting At: {deal?.startedAt}</h6>
          <h6>Ending At: {deal?.endingAt}</h6>
        </>
      )}

      <div className="d-flex align-items-center justify-content-end mt-5">
        <>
          {deal?.status === DealStatus.Draft && draftButtons}
          {deal?.status === DealStatus.Active && activeButtons}
          <Button
            type="primary"
            className="btn btn-primary"
            onClick={() => onClose()}
          >
            Close
          </Button>
        </>
      </div>
    </Modal>
  );
};
