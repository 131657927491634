import axios from "axios";
import jwt from "jsonwebtoken";
import config from "../config";

function isExpired(token: string) {
  const decode = jwt.decode(token);

  if (!decode || typeof decode === "string" || !decode.exp) {
    return false;
  }

  const nowTimestamp = Math.floor(new Date().getTime() / 1000);
  const expTimestamp = decode.exp;

  return nowTimestamp > expTimestamp;
}

interface RefreshTokenResponse {
  data: {
    auth: {
      accessToken: string;
      expiresIn: number;
      tokenType: string;
    };
  };
}
const refreshAccessToken = async (refreshToken: string, uid: string) => {
  return await axios.post<RefreshTokenResponse>(
    `${config.apiUrl}/api/v1/admin/auth/refresh-token`,
    {
      refreshToken: refreshToken,
      uid: uid
    }
  );
};

export const getAccessToken = async () => {
  const auth = JSON.parse(sessionStorage.getItem("user_auth") || "{}");
  const user = JSON.parse(sessionStorage.getItem("user_details") || "{}");

  const accessToken = auth.accessToken;
  const refreshToken = auth.refreshToken;

  if (!isExpired(accessToken)) {
    return accessToken;
  }

  try {
    const { data } = await refreshAccessToken(refreshToken, user.id);
    auth.accessToken = data.data.auth.accessToken;

    sessionStorage.setItem("user_auth", JSON.stringify(auth));

    return auth.accessToken;
  } catch (error) {
    sessionStorage.removeItem("user_auth");
    sessionStorage.removeItem("user_details");
    window.location.reload();
  }
};
