import { Button, notification } from "antd";
import React, { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { EN } from "../../../assets/i18n/en";
import cx from "classnames";
import Select from "react-select";
import { SelectOption } from "../../../utils/select";
import { Roles } from "../../../utils/roles";
import { Modal } from "../../modal/modal";
import styles from "./new-business-account-modal.module.scss";
import {
  InviteAccountMember,
  InviteParams,
  Invitation
} from "../../../api/account.service";

interface NewBusinessAccountInviteModalProps {
  accountName: string;
  onClose: () => void;
}

interface InvitationFormItem {
  email: string;
  role: SelectOption;
}

export interface CreateInviteForm {
  invitations: InvitationFormItem[];
}

export const NewBusinessAccountInviteModal: React.FC<NewBusinessAccountInviteModalProps> =
  ({ onClose, accountName }) => {
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, errors, control, getValues } =
      useForm<CreateInviteForm>();

    const {
      fields: invitationItemFields,
      append: appendInvitationItem,
      remove: removeInvitationItem
    } = useFieldArray<InvitationFormItem>({
      name: "invitations",
      control
    });

    const onSubmit = async ({ invitations }: CreateInviteForm) => {
      setLoading(true);

      try {
        const invitationParams: Invitation[] = invitations.map((x) => ({
          email: x.email,
          role: x.role.value
        }));
        const params: InviteParams = {
          accountName,
          invitations: invitationParams
        };

        await InviteAccountMember(params);
        notification.success({
          message: `You have invited a new member(s)`,
          duration: 10
        });

        setLoading(false);
        onClose();
      } catch (e) {
        const description = (e as any)?.message || EN.error.defaultMessage;

        notification.error({
          message: "Something went wrong",
          description,
          duration: 10
        });

        setLoading(false);
      }
    };

    const roles = Object.values(Roles);

    const state = getValues() as unknown as CreateInviteForm;

    return (
      <Modal onDismiss={onClose} className={styles["modal"]} hasCloseIcon>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-container container">
              <div className="row">
                <div className="col-lg-10">
                  <h4>Invite new members</h4>
                </div>
                <div className="col-lg-2">
                  <Button
                    type="primary"
                    className="btn btn-primary"
                    style={{ height: "auto" }}
                    onClick={() => {
                      appendInvitationItem({});
                    }}
                  >
                    Add
                  </Button>
                </div>
              </div>

              {invitationItemFields.map((field, index) => {
                const fieldName = `invitations[${index}]`;
                const itemErrors = errors.invitations
                  ? errors.invitations[index]
                  : undefined;

                return (
                  <div key={fieldName} className="row m-t-20">
                    <div className="col-lg-7">
                      <fieldset key={`${fieldName}.email`} name={fieldName}>
                        <Controller
                          as={
                            <label
                              htmlFor={`${fieldName}.email`}
                              style={{ width: "100%" }}
                            >
                              Email
                              <input
                                type="text"
                                name={`${fieldName}.email`}
                                defaultValue={
                                  (state as any)[`${fieldName}.email`]
                                }
                                className={cx("form-control", {
                                  "is-invalid": itemErrors?.email
                                })}
                                ref={register({ required: true })}
                              />
                            </label>
                          }
                          name={`${fieldName}.email`}
                          defaultValue={(state as any)[`${fieldName}.email`]}
                          control={control}
                        />
                      </fieldset>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor={`${fieldName}.role`}>Role</label>
                      <Controller
                        as={
                          <Select
                            maxMenuHeight={150}
                            placeholder="Role"
                            className={cx("country-select", {
                              "is-invalid": itemErrors?.role
                            })}
                            options={roles.map((r) => ({
                              value: r,
                              label: r
                            }))}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 4,
                              colors: {
                                ...theme.colors,
                                primary: "var(--purple)"
                              }
                            })}
                          />
                        }
                        control={control}
                        rules={{ required: true }}
                        name={`${fieldName}.role`}
                        defaultValue={(state as any)[`${fieldName}.role`]}
                      />
                    </div>
                    <div className="col-lg-1">
                      <label />
                      <Button onClick={() => removeInvitationItem(index)}>
                        Remove
                      </Button>
                    </div>
                  </div>
                );
              })}
              <div className="row m-t-20">
                <div className="col-lg new-business-account-form-actions">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block mt-3"
                    style={{ width: 120 }}
                  >
                    {loading ? "Saving" : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    );
  };
