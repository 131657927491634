import { Button, notification } from "antd";
import React, { useState } from "react";
import { getAccessToken } from "../../../api/auth.service";
import { EN } from "../../../assets/i18n/en";
import config from "../../../config";
import axios, { AxiosRequestConfig } from "axios";
import { MediaStatus } from "../../../interfaces/media";
import { MediaJob } from "../../../api/media.service";
import { JobDetails } from "../../../api/job.service";

interface MediaModalJobCancelProps {
  job: MediaJob;
  handleCancel: (job: JobDetails) => void;
}

export const MediaModalJobCancel: React.FC<MediaModalJobCancelProps> = ({
  job,
  handleCancel
}) => {
  const [isCancelling, setIsCancelling] = useState(false);
  const [hasCancelled, setHasCancelled] = useState(false);

  const onClick = async () => {
    setIsCancelling(true);

    try {
      const axiosConfig: AxiosRequestConfig = {
        url: `${config.apiUrl}/api/v1/admin/jobs/${job.id}/cancel`,
        method: "POST",
        headers: { "x-access-token": await getAccessToken() }
      };

      const result = await axios.request<JobDetails>(axiosConfig);

      if (result.status !== 200) {
        notification.error({
          message: "Cancellation failed",
          description: result?.data?.message || EN.error.defaultMessage,
          duration: 10
        });
      }

      setHasCancelled(true);

      notification.info({
        message: "Cancellation successful",
        duration: 10
      });

      handleCancel(result.data);
    } catch (err) {
      console.log(err);
      notification.error({
        message: "Cancellation failed",
        description: (err as any)?.message || EN.error.defaultMessage,
        duration: 10
      });
    } finally {
      setIsCancelling(false);
    }
  };

  if (hasCancelled) {
    return <>Cancelled</>;
  }

  return job.status === MediaStatus.Pending ? (
    <>
      <Button
        type="primary"
        className="btn btn-primary"
        onClick={onClick}
        loading={isCancelling}
        disabled={isCancelling}
      >
        Cancel
      </Button>
    </>
  ) : null;
};
