import React, { useEffect, useState } from "react";
import { takeUntil } from "rxjs/internal/operators/takeUntil";
import { Subject } from "rxjs/internal/Subject";
import { fetchJobList } from "../../api/job.service";
import AdminLayout from "../layouts/adminLayout";
import { JobsTable } from "./jobs-table/jobs-table";
import { jobsQuery } from "../../state/jobs/jobs.query";
import { JobListItem } from "../../api/job.service";

const Jobs: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [jobList, setJobList] = useState<JobListItem[]>();

  useEffect(() => {
    fetchJobList();

    const unsubcribe$ = new Subject();
    jobsQuery
      .select()
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ list }) => {
        setLoading(list.loading);
        setJobList(list.jobList);
      });

    return () => unsubcribe$.next();
  }, []);

  return (
    <AdminLayout parent="Lists" title="Jobs List">
      <JobsTable loading={loading} jobList={jobList} />
    </AdminLayout>
  );
};

export default Jobs;
