import React from "react";
import { MediaDetails } from "../../../api/media.service";

interface MediaModalMediaDetailsProps {
  mediaDetails: MediaDetails | undefined;
}

export const MediaModalMediaDetails: React.FC<MediaModalMediaDetailsProps> = ({
  mediaDetails
}) => {
  const accountUrl = `/accounts/${mediaDetails?.accountId}`;

  return mediaDetails ? (
    <>
      {mediaDetails.name} <br />
      status: {mediaDetails.status}
      <br />
      accountId: <a href={accountUrl}>{mediaDetails.accountId}</a>
      <br />
      sizeBytes: {mediaDetails.sizeBytes}
      <br />
      extension: {mediaDetails.extension}
      <br />
      durationSeconds: {mediaDetails.durationSeconds}
      <br />
      height: {mediaDetails.height}
      <br />
      width: {mediaDetails.width}
      <br />
      createdBy: {mediaDetails.createdBy}
      <br />
      createdAt: {mediaDetails.createdAt}
      <br />
      updatedAt: {mediaDetails.updatedAt}
      <br />
      downloadedAt: {mediaDetails.downloadedAt}
      <br />
      burnedAt: {mediaDetails.burnedAt}
      <br />
      language: {mediaDetails.language}
    </>
  ) : (
    <span>"Details missing"</span>
  );
};
