import { Query } from "@datorama/akita";
import {
  jobsStore,
  JobsStore,
  JobsState,
  JobListState,
  JobDetailsState
} from "./jobs.store";

export class JobsQuery extends Query<JobsState> {
  get list(): JobListState {
    return this.getValue().list;
  }

  get details(): JobDetailsState {
    return this.getValue().details;
  }

  constructor(protected store: JobsStore) {
    super(store);
  }
}

export const jobsQuery = new JobsQuery(jobsStore);
