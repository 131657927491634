import { Query } from "@datorama/akita";
import { DealListState, dealListStore, DealListStore } from "./deal-list.store";

export class DealListQuery extends Query<DealListState> {
  constructor(protected store: DealListStore) {
    super(store);
  }
}

export const dealListQuery = new DealListQuery(dealListStore);
