import React, { useState, useEffect } from "react";
import { Button, Spin, Tabs } from "antd";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  deleteMediaShare,
  downloadArchive,
  fetchMediaDetails,
  MediaDetails
} from "../../../api/media.service";
import { mediaQuery } from "../../../state/media/media.query";
import { MediaModalBurn } from "./media-modal-burn";
import { MediaModalTranscribe } from "./media-modal-transcribe";
import { MediaModalMediaDetails } from "./media-modal-media-details";
import { MediaModalFileDetails } from "./media-modal-file-details";
import { MediaModalJobDetails } from "./media-modal-job-details";
import produce from "immer";
import { JobDetails } from "../../../api/job.service";
import { Modal } from "../../modal/modal";
import styles from "./media-modal.module.scss";
import { MediaModalShareDetails } from "./media-modal-share-details";
import { ModalContext, ModalType } from "../../../contexts/modal.context";
import { NewMediaShareModal } from "../new-media-share-modal/new-media-share-modal";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";

interface MediaModalProps {
  mediaId?: string;
  onClose: () => void;
}
export const MediaModal: React.FC<MediaModalProps> = ({ mediaId, onClose }) => {
  const { showModal, hideModal } = React.useContext(ModalContext);
  const { TabPane } = Tabs;
  const title = `Media details (${mediaId})`;

  const [loading, setLoading] = useState(false);
  const [mediaDetails, setMediaDetails] = useState<MediaDetails>();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleCancel = (job: JobDetails) => {
    const updatedMediaDetails = produce(mediaDetails, (draft) => {
      const updatedjob = draft && draft.jobs.find((x) => x.id === job.id);

      if (updatedjob !== undefined) {
        updatedjob.endDate = job.endDate;
        updatedjob.message = job.message;
        updatedjob.status = job.status;
      }
    });

    setMediaDetails(updatedMediaDetails);
  };

  useEffect(() => {
    if (!mediaId) {
      return;
    }

    fetchMediaDetails(mediaId);

    const unsubcribe$ = new Subject();
    mediaQuery
      .select()
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ details }) => {
        setLoading(details.loading);
        setMediaDetails(details.mediaDetails);
      });

    return () => unsubcribe$.next();
  }, [mediaId]);

  const handleDownloadArchive = async () => {
    if (!mediaId) {
      return;
    }

    try {
      setIsDownloading(true);

      const url = await downloadArchive(mediaId);

      if (url) {
        saveAs(url, `${mediaId}.zip`);
      }
    } catch (error) {
      console.error("Failed to download archive:", error);
      toast.error("Failed to download archive: " + (error as any).message);
    } finally {
      setIsDownloading(false);
    }
  };

  if (!mediaId) {
    return <Spin />;
  }

  return (
    <Modal onDismiss={onClose} className={styles["modal"]} hasCloseIcon>
      <h5 className="mb-3">{title}</h5>
      {loading ? (
        <Spin />
      ) : (
        <Tabs defaultActiveKey="1">
          <TabPane tab="Media" key="1">
            <MediaModalMediaDetails mediaDetails={mediaDetails} />
          </TabPane>
          <TabPane tab="Media Share" key="2">
            <MediaModalShareDetails
              mediaDetails={mediaDetails}
              openNewMediaShareModal={() =>
                showModal(
                  ModalType.NewMediaShare,
                  <NewMediaShareModal
                    mediaId={mediaId}
                    onClose={() => hideModal(ModalType.NewMediaShare)}
                  />
                )
              }
              removeMediaShare={(id) => deleteMediaShare(id)}
            />
          </TabPane>
          <TabPane tab="Files" key="3">
            <MediaModalFileDetails mediaDetails={mediaDetails} />
          </TabPane>
          <TabPane tab="Jobs" key="4">
            <MediaModalJobDetails
              mediaDetails={mediaDetails}
              handleCancel={handleCancel}
            />
          </TabPane>
        </Tabs>
      )}
      <div className="d-flex align-items-center justify-content-end mt-5">
        {(mediaDetails?.status || "") !== "DELETED" && (
          <>
            <Button
              type="primary"
              className="btn btn-primary float-left"
              onClick={handleDownloadArchive}
              disabled={isDownloading}
            >
              Download
            </Button>
            <MediaModalTranscribe mediaDetails={mediaDetails} />
            <MediaModalBurn mediaDetails={mediaDetails} />
          </>
        )}
        <Button
          type="primary"
          className="btn btn-primary"
          onClick={() => onClose()}
        >
          OK
        </Button>
      </div>
    </Modal>
  );
};
