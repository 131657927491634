import { useCookieConsents } from "@enzsft/react-cookie-consents";
import React from "react";
import * as ROUTES from "../../routes";
import "./cookie-banner.scss";

export const CookieBanner: React.FC = () => {
  const cookieConsents = useCookieConsents();

  if (!cookieConsents || cookieConsents.get().length > 0) {
    return null;
  }

  return (
    <div id="cookies-consent">
      <p>
        This site uses cookies to store information on your computer. Some are
        essential to make our site work; others help us improve the user
        experience. By using the site, you consent to the placement of these
        cookies.
      </p>
      <div className="actions">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => cookieConsents.add("analytics")}
        >
          I'm alright with it
        </button>
        <p>
          Read our{" "}
          <a
            href={ROUTES.PRIVACY_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
};
