import { notification, Spin } from "antd";
import React, { useState } from "react";
import { EN } from "../../../assets/i18n/en";
import "./cognito-detail-editable.scss";
import Icon from "@mdi/react";
import { mdiCheck, mdiClose, mdiPencil } from "@mdi/js";

interface CognitoDetailEditableProps {
  name: string;
  value?: string;
  isTogglingEducation: boolean;
  setCognitoAttribute: (name: string, value: string) => void;
  onEditingOrSaving: (
    attributeName: string,
    isEditingOrSaving: boolean
  ) => void;
}

export const CognitoDetailEditable: React.FC<CognitoDetailEditableProps> = ({
  name,
  value,
  isTogglingEducation,
  setCognitoAttribute,
  onEditingOrSaving
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [editedValue, setEditedValue] = useState<string>();

  const onSave = async (event: React.MouseEvent<HTMLElement>) => {
    setIsEditing(false);
    setIsSaving(true);
    onEditingOrSaving(name, true);
    try {
      await setCognitoAttribute(name, editedValue || "");
    } catch (err) {
      notification.error({
        message: (err as any)?.message,
        description: (err as any)?.message || EN.error.defaultMessage,
        duration: 10
      });
    } finally {
      setIsSaving(false);
      onEditingOrSaving(name, false);
    }
  };

  const onEdit = (event: React.MouseEvent<HTMLElement>) => {
    setIsEditing(true);
    setEditedValue(value);
    onEditingOrSaving(name, true);
  };

  const onCancel = (event: React.MouseEvent<HTMLElement>) => {
    setIsEditing(false);
    onEditingOrSaving(name, false);
  };

  const onChangeValue = (event: React.FormEvent<HTMLInputElement>) => {
    setEditedValue(event.currentTarget.value.toString());
  };

  if (Boolean(isSaving) || Boolean(isTogglingEducation))
    return (
      <>
        <span>{name}:</span>
        <Spin className="ml-3" />;
      </>
    );

  return (
    <span className="user-modal-cognito-detail-editable">
      {Boolean(isEditing) ? (
        <>
          {" "}
          <span>{name}: </span>
          <input
            type="text"
            defaultValue={value}
            className="ml-2"
            onChange={(e) => onChangeValue(e)}
          />
          <button onClick={(e) => onCancel(e)}>
            {" "}
            <Icon path={mdiClose} size="1.4em" className="ml-2" />
          </button>
          <button onClick={(e) => onSave(e)}>
            {" "}
            <Icon path={mdiCheck} size="1.4em" className="ml-2" />
          </button>
        </>
      ) : (
        <>
          <span>{name}: </span>
          <span>{value}</span>
          <button onClick={(e) => onEdit(e)}>
            {" "}
            <Icon path={mdiPencil} size="1.4em" className="ml-2" />
          </button>
        </>
      )}
    </span>
  );
};
