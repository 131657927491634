import { Store, StoreConfig } from "@datorama/akita";

import { User } from "../../interfaces/user";
import { Order } from "@getsubly/common";
import { OrderFile } from "../../api/order.service";

export interface OrderListParamsState {
  filter?: string;
  search?: string;
  limit?: number;
  paginationToken?: string;
}

export interface OrderListState {
  loading: boolean;
  orderList: Order[];
}

export interface OrderDetailsState {
  loading: boolean;
  orderDetails?: Order;
  orderFiles: OrderFile[];
}

export interface OrderState {
  user?: User;
  loading: boolean;
  loaded: boolean;
  isReplacingFile: boolean;
  params: OrderListParamsState;
  list: OrderListState;
  details: OrderDetailsState;
}

@StoreConfig({ name: "orders" })
export class OrderStore extends Store<OrderState> {
  constructor() {
    super({
      loaded: false,
      loading: true,
      isReplacingFile: false
    });
  }

  updateList(list: Partial<OrderListState>) {
    this.update((s) => ({
      ...s,
      list: {
        ...s.list,
        ...list
      }
    }));
  }

  updateParams(params: OrderListParamsState) {
    this.update((s) => ({
      ...s,
      params: {
        ...s.params,
        ...params
      }
    }));
  }

  updateDetails(details: Partial<OrderDetailsState>) {
    this.update((s) => ({
      ...s,
      details: {
        ...s.details,
        ...details
      }
    }));
  }
}

export const orderStore = new OrderStore();
