// General Settings
export default {
  version_number: "0.2.25",
  copyright: "Subly © 2020 Take One Labs Trading as Subly. All right reserved.",
  support_link: "https://take1video.zendesk.com/hc/en-us",
  social_media: {
    linkedin: "https://www.linkedin.com/company/take-1-video-app/",
    instagram: "https://www.instagram.com/take1videoapp/",
    facebook: "https://www.facebook.com/take1videoapp",
    twitter: "https://twitter.com/take1videoapp"
  },
  themes: [
    {
      default: true,
      logo: "logo-60.png",
      favicon: "favicon.png",
      background_color: "#f7fbff",
      accent_color_1: "#2acfd2",
      accent_color_2: "#6e49e5",
      font: "Roboto, -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI','Helvetica Neue', Arial, sans-serif'"
    }
  ],
  transcription: {
    enabled: true,
    types: ["FLAC", "MP3", "MP4", "WAV"],
    maxDuration: 14400, // 4 hours
    maxAudio: 2 * 1000 * 1000, // 2gb
    languages: [
      {
        languageCode: "en-GB",
        language: "British English"
      },
      {
        languageCode: "en-US",
        language: "US English"
      },
      {
        languageCode: "en-AU",
        language: "Australian English"
      },
      {
        languageCode: "en-IE",
        language: "Irish English (beta)"
      },
      {
        languageCode: "en-AB",
        language: "Scottish English (beta)"
      },
      {
        languageCode: "en-WL",
        language: "Welsh English (beta)"
      },
      {
        languageCode: "en-IN",
        language: "Indian English (beta)"
      },
      {
        languageCode: "es-ES",
        language: "Spanish (beta)"
      },
      {
        languageCode: "es-US",
        language: "US Spanish (beta)"
      },
      {
        languageCode: "fr-FR",
        language: "French (beta)"
      },
      {
        languageCode: "fr-CA",
        language: "Canadian French (beta)"
      },
      {
        languageCode: "it-IT",
        language: "Italian (beta)"
      },
      {
        languageCode: "pt-PT",
        language: "Portuguese (beta)"
      },
      {
        languageCode: "pt-BR",
        language: "Brazilian Portuguese (beta)"
      },
      {
        languageCode: "de-DE",
        language: "German (beta)"
      },
      {
        languageCode: "de-CH",
        language: "Swiss German (beta)"
      },
      {
        languageCode: "nl-NL",
        language: "Dutch (beta)"
      },
      {
        languageCode: "tr-TR",
        language: "Turkish (beta)"
      },
      {
        languageCode: "ar-AE",
        language: "Gulf Arabic (beta)"
      },
      {
        languageCode: "ar-SA",
        language: "Modern Standard Arabic (beta)"
      },
      {
        languageCode: "zh-CN",
        language: "Chinese Mandarin - Mainland (beta)"
      },
      {
        languageCode: "fa-IR",
        language: "Farsi (beta)"
      },
      {
        languageCode: "he-IL",
        language: "Hebrew (beta)"
      },
      {
        languageCode: "hi-IN",
        language: "Indian Hindi (beta)"
      },
      {
        languageCode: "id-ID",
        language: "Indonesian (beta)"
      },
      {
        languageCode: "ja-JP",
        language: "Japanese (beta)"
      },
      {
        languageCode: "ko-KR",
        language: "Korean (beta)"
      },
      {
        languageCode: "ms-MY",
        language: "Malay (beta)"
      },
      {
        languageCode: "ru-RU",
        language: "Russian (beta)"
      },
      {
        languageCode: "ta-IN",
        language: "Tamil (beta)"
      },
      {
        languageCode: "te-IN",
        language: "Telugu (beta)"
      }
    ]
  },
  translation: {
    enabled: false,
    // total: 27
    languages: [
      {
        languageCode: "ar",
        language: "Arabic"
      },
      {
        languageCode: "zh",
        language: "Chinese (Simplified)"
      },
      {
        languageCode: "zh-TW",
        language: "Chinese (Traditional)"
      },
      {
        languageCode: "cs",
        language: "Czech"
      },
      {
        languageCode: "da",
        language: "Danish"
      },
      {
        languageCode: "nl",
        language: "Dutch"
      },
      {
        languageCode: "en",
        language: "English"
      },
      {
        languageCode: "fi",
        language: "Finnish"
      },
      {
        languageCode: "fr",
        language: "French"
      },
      {
        languageCode: "de",
        language: "German"
      },
      {
        languageCode: "he",
        language: "Hebrew"
      },
      {
        languageCode: "hi",
        language: "Hindi"
      },
      {
        languageCode: "id",
        language: "Indonesian"
      },
      {
        languageCode: "it",
        language: "Italian"
      },
      {
        languageCode: "ja",
        language: "Japanese"
      },
      {
        languageCode: "ko",
        language: "Korean"
      },
      {
        languageCode: "ms",
        language: "Malay"
      },
      {
        languageCode: "no",
        language: "Norwegian"
      },
      {
        languageCode: "fa",
        language: "Persian"
      },
      {
        languageCode: "pl",
        language: "Polish"
      },
      {
        languageCode: "pt",
        language: "Portuguese"
      },
      {
        languageCode: "ru",
        language: "Russian"
      },
      {
        languageCode: "es",
        language: "Spanish"
      },
      {
        languageCode: "sv",
        language: "Swedish"
      },
      {
        languageCode: "tr",
        language: "Turkish"
      }
    ],
    streamingLanguages: [
      {
        languageCode: "en-GB",
        language: "British English"
      },
      {
        languageCode: "en-US",
        language: "US English"
      },
      {
        languageCode: "fr-FR",
        language: "French"
      },
      {
        languageCode: "fr-CA",
        language: "Canadian French"
      },
      {
        languageCode: "es-US",
        language: "US Spanish"
      }
    ]
  },
  comprehend: {
    enabled: false,
    keyPhraseExtraction: false,
    sentimentAnalysis: false,
    entityRecognition: false,
    languageDetection: false,
    syntaxAnalysis: false
  },
  polly: {
    enabled: false,
    languages: [
      {
        language: "Arabic",
        languageCode: "arb",
        name: "Zeina",
        gender: "Female",
        standard_voice: true,
        neural_voice: false
      }
    ]
  },
  rekognition: {
    enabled: false
  },
  payments: {
    stripe: true,
    paypal: false
  },
  fonts: [
    "Arial",
    "Baloo Chettan",
    "Bebas",
    "Bellefair",
    "ChunkFive Roman",
    "Cormorant Garamond",
    "Cormorant Garamond Light",
    "Cormorant Garamond Medium",
    "Cormorant Garamond SemiBold",
    "Daniel Black",
    "Daniel",
    "Datalegreya Dot",
    "Datalegreya Gradient",
    "Datalegreya Thin",
    "Futura",
    "Futura Heavy",
    "Futura Light",
    "Homemade Apple",
    "Impact",
    "Impacted",
    "Lato Black",
    "Lato",
    "Lato Hairline",
    "Lato Heavy",
    "Lato Light",
    "Lato Medium",
    "Lato Semibold",
    "Lato Thin",
    "LM Roman 10",
    "LM Roman Caps 10",
    "LM Roman Demi 10",
    "LM Roman Dunhill 10",
    "LM Roman Slanted 10",
    "Open Sans",
    "Open Sans Extrabold",
    "Open Sans Light",
    "Open Sans Semibold",
    "Oranienbaum",
    "Pacifico",
    "Permanent Marker",
    "Playfair Display Black",
    "Playfair Display",
    "Playfair Display SC Black",
    "Playfair Display SC",
    "Quicksand",
    "Quicksand Light",
    "Quicksand Dash",
    "Raleway Black",
    "Raleway",
    "Raleway ExtraBold",
    "Raleway ExtraLight",
    "Raleway Light",
    "Raleway Medium",
    "Raleway SemiBold",
    "Raleway Thin",
    "Roboto Black",
    "Roboto",
    "Roboto Light",
    "Roboto Medium",
    "Roboto Thin",
    "Roboto Condensed",
    "Roboto Condensed Light",
    "Special Elite",
    "TrashHand",
    "Unicode Impact"
  ],
  app: {
    card_view: false,
    table_view: true,
    media_thumbnail: false
  },
  plans: {
    // Each plans requirements
    free: {
      queue_limit: 2
    },
    standard: {
      queue_limit: 5
    },
    plus: {
      queue_limit: 10
    },
    metered: {}
  },
  customers: [
    {
      background: "/img/mwulff.jpg",
      businessName: "Podcaster at Digital Love",
      comment:
        "Subly has been a game changer for creating videos. As a podcaster, I wanted to use video to promote my work. I was able to record a video, add text within seconds and upload to social media. It’s perfect for creators like me!",
      personName: "Monica Wulff",
      id: 1
    },
    {
      background: "/img/matt.jpg",
      businessName: "Founder of Trust the Process",
      comment:
        "Subly has saved us so much time at our agency. We needed a way to produce branded videos into multiple languages with text embedded for social media posts and adverts. This is the solution we have been waiting for!",
      personName: "Matthew Moseley",
      id: 2
    },
    {
      background: "/img/emma.jpg",
      businessName: "Bespoke Backdrop & Branding",
      comment:
        "Subly is what I’ve been waiting for! I’ve wanted to produce and add text to videos for my business but I couldn’t find an easy and fast way to do it. Finally there is a way and the videos look awesome!",
      personName: "Emma veiga-malta",
      id: 3
    }
  ]
};
