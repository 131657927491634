import { Button, notification } from "antd";
import React, { useState } from "react";
import axios from "axios";
import config from "../../../config";
import { getAccessToken } from "../../../api/auth.service";
import { EN } from "../../../assets/i18n/en";
import { MediaDetails } from "../../../api/media.service";
import { sortBy } from "lodash";
import { JobType, MediaStatus } from "../../../interfaces/media";

interface MediaModalTranscribeProps {
  mediaDetails: MediaDetails | undefined;
}
export const MediaModalTranscribe: React.FC<MediaModalTranscribeProps> = ({
  mediaDetails
}) => {
  const [isTranscribing, setIsTranscribing] = useState(false);

  const sortedJobs = sortBy(mediaDetails?.jobs, (x) => x.startDate).reverse();

  const successfulUploadJob = sortedJobs.find(
    (x) => x.type === JobType.Upload && x.status === MediaStatus.Uploaded
  );

  const onClick = async () => {
    try {
      setIsTranscribing(true);

      const data = {
        language: mediaDetails?.language,
        accountId: mediaDetails?.accountId
      };

      const result = await axios.post(
        `${config.apiUrl}/api/v1/admin/transcriptions/media/${mediaDetails?.mediaId}/transcribe`,
        data,
        {
          headers: { "x-access-token": await getAccessToken() }
        }
      );

      if (result.status !== 200) {
        notification.error({
          message: "Transcribe failed",
          description: result?.data?.message || EN.error.defaultMessage,
          duration: 10
        });
      }

      notification.info({
        message: "Transcribe started",
        duration: 10
      });
    } catch (err) {
      console.log(err);
      notification.error({
        message: "Transcribe failed",
        description: (err as any)?.message || EN.error.defaultMessage,
        duration: 10
      });
    } finally {
      setIsTranscribing(false);
    }
  };

  return !successfulUploadJob ? null : (
    <Button
      type="primary"
      className="btn btn-primary float-left"
      onClick={onClick}
      loading={isTranscribing}
      disabled={isTranscribing}
    >
      {isTranscribing ? "Transcribing" : `Transcribe`}
    </Button>
  );
};
