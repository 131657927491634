import React, { useState } from "react";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { fetchTopReferrers } from "../../../api/stats.service";
import { ReferrerUser } from "../../../interfaces/stats";
import { dashboardQuery } from "../../../state/dashboard/dashboard.query";
import { StatsCard } from "../../stats-card/stats-card";
import { ReferrersTable } from "../referrers-table/referrers-table";

interface ReferrersStatsCardProps {
  notifyOfSelectionStatus: (isEnabled: boolean) => void;
}

export const ReferrersStatsCard: React.FC<ReferrersStatsCardProps> = ({
  notifyOfSelectionStatus
}) => {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState<ReferrerUser[]>();

  const loadData = (isEnabled: boolean) => {
    if (!isEnabled) {
      setStats([]);
      return;
    }
    notifyOfSelectionStatus(false);

    const unsubscribe$ = new Subject();

    fetchTopReferrers(
      dashboardQuery.filterStartDate,
      dashboardQuery.filterEndDate,
      dashboardQuery.topReferrersTake
    );

    dashboardQuery
      .select("topReferrers")
      .pipe(takeUntil(unsubscribe$))
      .subscribe(({ loading, stats }) => {
        setLoading(loading);
        setStats(stats);
      });

    notifyOfSelectionStatus(true);

    return () => unsubscribe$.next();
  };

  return (
    <StatsCard title="Referrals" loadData={loadData}>
      <ReferrersTable stats={stats} loading={loading} />
    </StatsCard>
  );
};
