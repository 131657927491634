import React, { useState } from "react";
import settings from "../config/settings/settings";

export interface Language {
  languageCode: string;
  language: string;
}

interface SettingsContext {
  config: any;
  languages: Language[];
}

const SettingsContext = React.createContext<SettingsContext>({
  config: {},
  languages: []
});

const SettingsProvider: React.FC = ({ children }) => {
  const [config] = useState({ ...settings });
  const [languages] = useState<Language[]>(settings.transcription.languages);

  return (
    <SettingsContext.Provider
      value={{
        config,
        languages
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

const SettingConsumer = SettingsContext.Consumer;
export { SettingsProvider, SettingConsumer, SettingsContext };
