import { Tooltip } from "antd";
import React from "react";
import { withRouter } from "react-router";
import { Link, NavLink } from "react-router-dom";
import { AuthConsumer } from "../../../contexts/auth.context";
import Logo from "../../../assets/images/logo-dark.svg";
import * as ROUTES from "../../../routes";
import styles from "../styles.module.css";
import { PermissionGuard } from "../../../guards/permission-guard";
import { UserScope } from "../../../api/scope.service";
import { Icon } from "@mdi/react";
import { mdiGaugeFull } from "@mdi/js";

const MobileNav = ({ match }) => (
  <AuthConsumer>
    {({ user, logout }) => (
      <div className={styles.mobileHeader} style={{ maxWidth: "100vw" }}>
        <ul className={styles.mobileNavIcons}>
          <li className={styles.mobileNavMenu}>
            <div
              className="nav-logo"
              style={{
                display: "flex",
                height: "inherit",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Link to={ROUTES.LANDING}>
                <img src={Logo} style={{ width: "40px" }} alt="Subly" />
              </Link>
            </div>
          </li>
          <li className={styles.mobileNavMenu}>
            <Tooltip placement="right" title="Dashboard">
              <NavLink
                to={ROUTES.LANDING}
                activeClassName={styles.selected}
                isActive={() => {
                  const pattern = new RegExp(ROUTES.VIDEOS);
                  return (
                    match.url === ROUTES.LANDING || pattern.test(match.url)
                  );
                }}
              >
                <Icon path={mdiGaugeFull} size="28px" color="white" />
              </NavLink>
            </Tooltip>
          </li>
          <PermissionGuard level={UserScope.Admin}>
            <li className={styles.mobileNavMenu}>
              <Tooltip placement="right" title="Users">
                <NavLink
                  to={ROUTES.USERS_PATH}
                  activeClassName={styles.selected}
                  isActive={() => {
                    const pattern = new RegExp(ROUTES.USERS_PATH);
                    return (
                      match.url === ROUTES.USERS_PATH || pattern.test(match.url)
                    );
                  }}
                >
                  <i
                    className="mdi mdi-account-multiple"
                    style={{ fontSize: "28px" }}
                  />
                </NavLink>
              </Tooltip>
            </li>
          </PermissionGuard>
          <PermissionGuard level={UserScope.Admin}>
            <li className={styles.mobileNavMenu}>
              <Tooltip placement="right" title="Media">
                <NavLink
                  to={ROUTES.MEDIA_PATH}
                  activeClassName={styles.selected}
                  isActive={() => {
                    const pattern = new RegExp(ROUTES.MEDIA_PATH);
                    return (
                      match.url === ROUTES.MEDIA_PATH || pattern.test(match.url)
                    );
                  }}
                >
                  <i
                    className="mdi mdi-file-video"
                    style={{ fontSize: "28px" }}
                  />
                </NavLink>
              </Tooltip>
            </li>
          </PermissionGuard>
          <PermissionGuard level={UserScope.Admin}>
            <li className={styles.mobileNavMenu}>
              <Tooltip placement="right" title="Orders">
                <NavLink
                  to={ROUTES.ORDERS_PATH}
                  activeClassName={styles.selected}
                  isActive={() => {
                    const pattern = new RegExp(ROUTES.ORDERS_PATH);
                    return (
                      match.url === ROUTES.ORDERS_PATH ||
                      pattern.test(match.url)
                    );
                  }}
                >
                  <i className="mdi mdi-cart" style={{ fontSize: "28px" }} />
                </NavLink>
              </Tooltip>
            </li>
          </PermissionGuard>
          <PermissionGuard level={UserScope.Admin}>
            <li className={styles.mobileNavMenu}>
              <Tooltip placement="right" title="Jobs">
                <NavLink
                  to={ROUTES.JOBS_PATH}
                  activeClassName={styles.selected}
                  isActive={() => {
                    const pattern = new RegExp(ROUTES.JOBS_PATH);
                    return (
                      match.url === ROUTES.JOBS_PATH || pattern.test(match.url)
                    );
                  }}
                >
                  <i className="mdi mdi-memory" style={{ fontSize: "28px" }} />
                </NavLink>
              </Tooltip>
            </li>
          </PermissionGuard>
          <li className={styles.mobileNavMenu}>
            <Tooltip placement="top" title="Sign out">
              <span
                onClick={logout}
                style={{
                  display: "flex",
                  height: "inherit",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <i className="mdi mdi-power" style={{ fontSize: "28px" }} />
              </span>
            </Tooltip>
          </li>
        </ul>
      </div>
    )}
  </AuthConsumer>
);

export default withRouter(MobileNav);
