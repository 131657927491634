import { Query } from "@datorama/akita";
import {
  NewDealModalState,
  newDealModalStore,
  NewDealModalStore
} from "./new-deal-modal.store";

export class NewDealModalQuery extends Query<NewDealModalState> {
  constructor(protected store: NewDealModalStore) {
    super(store);
  }
}

export const newDealModalQuery = new NewDealModalQuery(newDealModalStore);
