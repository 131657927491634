import React from "react";
import {
  Order,
  languageLookup,
  transcriptionLanguages
} from "@getsubly/common";
import { parseSecondsToText } from "../../../utils/time";

interface OrderModalOrderDetailsProps {
  order: Order | undefined;
}

export const OrderModalOrderDetails: React.FC<OrderModalOrderDetailsProps> = ({
  order
}) => {
  const sourceLanguage = React.useMemo(() => {
    return order?.details.mediaLanguageCode
      ? languageLookup(transcriptionLanguages, order.details.mediaLanguageCode)
          ?.language
      : "";
  }, [order]);

  return order ? (
    <>
      <br />
      Order Reference: {order.reference}
      <br />
      Order Id: {order.id}
      <br />
      Account Id: {order.accountId}
      <br />
      Media Id: {order.mediaId}
      <br />
      Media duration: {parseSecondsToText(order.details.mediaDurationSeconds)}
      <br />
      Source language: {sourceLanguage}
      <br />
      Order status:&nbsp;
      {order.status === "pending" && (
        <span className="tw-px-1 tw-rounded tw-bg-primary-400 tw-text-white">
          Requested
        </span>
      )}
      {order.status === "in-progress" && (
        <span className="tw-px-1 tw-rounded tw-bg-warning-400 tw-text-white">
          In progress
        </span>
      )}
      {order.status === "completed" && (
        <span className="tw-px-1 tw-rounded tw-bg-success-400 tw-text-white">
          Completed
        </span>
      )}
      {order.status === "cancelled" && (
        <span className="tw-px-1 tw-rounded tw-bg-neutral-400 tw-text-white">
          Cancelled
        </span>
      )}
      {order.status === "rejected" && (
        <span className="tw-px-1 tw-rounded tw-bg-destructive-400 tw-text-white">
          Rejected
        </span>
      )}
      <br />
      Services:
      <br />
      <ul>
        {order.details.services.map((service, i) => {
          const suffix = service.languages
            ? `(${service.languages.join(", ")})`
            : "";
          return (
            <li key={i} className="tw-pl-4">
              - {service.name} {suffix}
            </li>
          );
        })}
      </ul>
      Created by: {order.createdBy.email} / {order.createdBy.id}
      <br />
      Created at: {order.createdAt}
      <br />
      Updated at: {order.updatedAt}
    </>
  ) : (
    <span>Details missing</span>
  );
};
