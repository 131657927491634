import { notification } from "antd";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { EN } from "../../../assets/i18n/en";
import cx from "classnames";
import Select from "react-select";
import { SelectOption } from "../../../utils/select";
import { Roles } from "../../../utils/roles";
import { Modal } from "../../modal/modal";
import styles from "./new-business-account-modal.module.scss";
import {
  AddAccountMember,
  AddMember,
  AddMemberParams
} from "../../../api/account.service";
import { emailPattern } from "../../../utils/regex-patterns";

interface NewBusinessAccountInviteModalProps {
  accountId: string;
  onClose: () => void;
}

export interface CreateMemberForm {
  email: string;
  role: SelectOption;
}

export const NewBusinessAccountMemberModal: React.FC<NewBusinessAccountInviteModalProps> =
  ({ onClose, accountId }) => {
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, errors, control, getValues } =
      useForm<CreateMemberForm>();

    const onSubmit = async ({ email, role }: CreateMemberForm) => {
      setLoading(true);

      try {
        const memberParams: AddMember = {
          email: email,
          role: role.value
        };
        const params: AddMemberParams = {
          accountId,
          member: memberParams
        };

        await AddAccountMember(params);
        notification.success({
          message: `You have invited a new member`,
          duration: 10
        });

        setLoading(false);
        onClose();
      } catch (e) {
        const description = e.message || EN.error.defaultMessage;

        notification.error({
          message: "Something went wrong",
          description,
          duration: 10
        });

        setLoading(false);
      }
    };

    const roles = Object.values(Roles);

    const state = getValues() as unknown as CreateMemberForm;

    return (
      <Modal onDismiss={onClose} className={styles["modal"]} hasCloseIcon>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-container container">
              <h2>Add member to account</h2>
              <div className="row">
                <div className="col-lg">
                  <label style={{ width: "100%" }}>
                    Email
                    <input
                      className={cx("form-control", {
                        "is-invalid": errors.email
                      })}
                      type="email"
                      id="email"
                      name="email"
                      ref={register({
                        required: true,
                        pattern: emailPattern
                      })}
                      defaultValue={state?.email}
                    />
                  </label>
                  <label htmlFor="role">Role</label>
                  <fieldset key={"role"} name={"role"}>
                    <Controller
                      as={
                        <Select
                          className={cx("country-select", {
                            "is-invalid": errors.role
                          })}
                          maxMenuHeight={150}
                          placeholder="Role"
                          options={roles.map((r) => ({
                            value: r,
                            label: r
                          }))}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                              ...theme.colors,
                              primary: "var(--purple)"
                            }
                          })}
                        />
                      }
                      control={control}
                      rules={{ required: true }}
                      name={`role`}
                      defaultValue={state?.role}
                    />
                  </fieldset>
                </div>
              </div>
              <div className="row m-t-20">
                <div className="col-lg new-business-account-form-actions">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block mt-3"
                    style={{ width: 120 }}
                  >
                    {loading ? "Saving" : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    );
  };
