import Icon from "@mdi/react";
import { Spin } from "antd";
import React from "react";
import { format, FormatOptions } from "../../../utils/number-formatter";
import "./number-stats-card.scss";

export enum NumberStatsCardSize {
  IsQuarter = "col-md-3",
  IsThird = "col-md-4",
  IsHalf = "col-md-6"
}

interface NumberStatsCardProps {
  title: string;
  loading: boolean;
  error?: boolean;
  result: number;
  icon: string;
  size?: NumberStatsCardSize;
  formatOptions?: FormatOptions;
}
export const NumberStatsCard: React.FC<NumberStatsCardProps> = ({
  loading,
  error,
  result,
  title,
  icon,
  size = NumberStatsCardSize.IsQuarter,
  formatOptions
}) => {
  return (
    <div className={size}>
      <div className="card stats-card">
        <div className="card-body">
          <div className="media justify-content-between">
            <div>
              <p>{title}</p>
              <h2 className="font-size-28 font-weight-light">
                {loading ? (
                  <Spin />
                ) : error ? (
                  "??"
                ) : (
                  format(result, formatOptions)
                )}
              </h2>
            </div>

            <Icon path={icon} className="text-success" />
          </div>
        </div>
      </div>
    </div>
  );
};
