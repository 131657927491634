import axios from "axios";
import config from "../config";
import { getAccessToken } from "./auth.service";
import { notification } from "antd";
import { EN } from "../assets/i18n/en";
import { AccountMember, Invitation } from "../interfaces/member";
import { accountMembersStore } from "../state/account/account-members/account-members.store";

const baseURL = `${config.apiUrl}/api/v1`;
const adminBaseURL = `${baseURL}/admin`;

export const fetchAccountMembers = async (accountId: string) => {
  try {
    const state = accountMembersStore.getValue();
    if (
      state.loading ||
      (state.members && state.invitations && state.accountId === accountId)
    ) {
      return;
    }
    accountMembersStore.update({
      members: [],
      invitations: [],
      loading: true,
      accountId
    });
    const { data } = await axios.get<{
      message: string;
      members: AccountMember[];
      invitations: Invitation[];
    }>(`${adminBaseURL}/accounts/${accountId}/members`, {
      headers: {
        "x-access-token": await getAccessToken()
      }
    });
    accountMembersStore.update({
      members: data.members,
      invitations: data.invitations,
      loading: false,
      accountId
    });
  } catch (error) {
    handleError(error);
    console.log(error);
  } finally {
    accountMembersStore.update({
      loading: false
    });
  }
};

export const DeleteAccountMember = async (
  accountId: string,
  usermemberId: string
) => {
  try {
    await axios.delete<{
      message: string;
    }>(`${adminBaseURL}/accounts/${accountId}/members/${usermemberId}`, {
      headers: {
        "x-access-token": await getAccessToken()
      }
    });
  } catch (error) {
    handleError(error);
  }
};

const handleError = (e: any) => {
  console.error(e);

  notification.error({
    message: "Something went wrong",
    description: e?.message || EN.error.defaultMessage,
    duration: 10
  });
};
