import { mdiGaugeFull, mdiApi } from "@mdi/js";
import { Icon } from "@mdi/react";
import { Tooltip } from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import { AuthConsumer } from "../../../contexts/auth.context";
import * as ROUTES from "../../../routes";
import styles from "./style.module.css";
import { PermissionGuard } from "../../../guards/permission-guard";
import { UserScope } from "../../../api/scope.service";

class UserNavigationMenu extends Component {
  state = {
    balance: 0,
    userDetails: {}
  };

  async componentDidMount() {}

  handleLogout = () => null;

  render() {
    return (
      <AuthConsumer>
        {({ logout }) => (
          <>
            <li className={styles.sidebarIcon}>
              <Tooltip placement="right" title="Dashboard">
                <NavLink
                  to={ROUTES.LANDING}
                  activeClassName={styles.selected}
                  isActive={() => {
                    const pattern = new RegExp(ROUTES.VIDEOS);
                    return (
                      this.props.match.url === ROUTES.LANDING ||
                      pattern.test(this.props.match.url)
                    );
                  }}
                >
                  <Icon
                    path={mdiGaugeFull}
                    size="28px"
                    color="white"
                    className="tw-inline"
                  />
                </NavLink>
              </Tooltip>
            </li>
            <PermissionGuard level={UserScope.Admin}>
              <li className={styles.sidebarIcon}>
                <Tooltip placement="right" title="Users">
                  <NavLink
                    to={ROUTES.USERS_PATH}
                    activeClassName={styles.selected}
                    isActive={() => {
                      const pattern = new RegExp(ROUTES.USERS_PATH);
                      return (
                        this.props.match.url === ROUTES.USERS_PATH ||
                        pattern.test(this.props.match.url)
                      );
                    }}
                  >
                    <i
                      className="mdi mdi-account-multiple"
                      style={{ fontSize: "28px" }}
                    />
                  </NavLink>
                </Tooltip>
              </li>
            </PermissionGuard>

            <PermissionGuard level={UserScope.Admin}>
              <li className={styles.sidebarIcon}>
                <Tooltip placement="right" title="Accounts">
                  <NavLink
                    to={ROUTES.ACCOUNTS_PATH}
                    activeClassName={styles.selected}
                    isActive={() => {
                      const pattern = new RegExp(ROUTES.ACCOUNTS_PATH);
                      return (
                        this.props.match.url === ROUTES.ACCOUNTS_PATH ||
                        pattern.test(this.props.match.url)
                      );
                    }}
                  >
                    <i
                      className="mdi mdi-account"
                      style={{ fontSize: "28px" }}
                    />
                  </NavLink>
                </Tooltip>
              </li>
            </PermissionGuard>
            <PermissionGuard level={UserScope.Admin}>
              <li className={styles.sidebarIcon}>
                <Tooltip placement="right" title="Media">
                  <NavLink
                    to={ROUTES.MEDIA_PATH}
                    activeClassName={styles.selected}
                    isActive={() => {
                      const pattern = new RegExp(ROUTES.MEDIA_PATH);
                      return (
                        this.props.match.url === ROUTES.MEDIA_PATH ||
                        pattern.test(this.props.match.url)
                      );
                    }}
                  >
                    <i
                      className="mdi mdi-file-video"
                      style={{ fontSize: "28px" }}
                    />
                  </NavLink>
                </Tooltip>
              </li>
            </PermissionGuard>
            <PermissionGuard level={UserScope.Admin}>
              <li className={styles.sidebarIcon}>
                <Tooltip placement="right" title="Orders">
                  <NavLink
                    to={ROUTES.ORDERS_PATH}
                    activeClassName={styles.selected}
                    isActive={() => {
                      const pattern = new RegExp(ROUTES.ORDERS_PATH);
                      return (
                        this.props.match.url === ROUTES.ORDERS_PATH ||
                        pattern.test(this.props.match.url)
                      );
                    }}
                  >
                    <i className="mdi mdi-cart" style={{ fontSize: "28px" }} />
                  </NavLink>
                </Tooltip>
              </li>
            </PermissionGuard>
            <PermissionGuard level={UserScope.Admin}>
              <li className={styles.sidebarIcon}>
                <Tooltip placement="right" title="Jobs">
                  <NavLink
                    to={ROUTES.JOBS_PATH}
                    activeClassName={styles.selected}
                    isActive={() => {
                      const pattern = new RegExp(ROUTES.JOBS_PATH);
                      return (
                        this.props.match.url === ROUTES.JOBS_PATH ||
                        pattern.test(this.props.match.url)
                      );
                    }}
                  >
                    <i
                      className="mdi mdi-memory"
                      style={{ fontSize: "28px" }}
                    />
                  </NavLink>
                </Tooltip>
              </li>
            </PermissionGuard>

            <PermissionGuard level={UserScope.SalesRep}>
              <li className={styles.sidebarIcon}>
                <Tooltip placement="right" title="Business">
                  <NavLink
                    to={ROUTES.BUSINESS_ACCOUNTS_PATH}
                    activeClassName={styles.selected}
                    isActive={() => {
                      const pattern = new RegExp(ROUTES.BUSINESS_ACCOUNTS_PATH);
                      return (
                        this.props.match.url ===
                          ROUTES.BUSINESS_ACCOUNTS_PATH ||
                        pattern.test(this.props.match.url)
                      );
                    }}
                  >
                    <i
                      className="mdi mdi-domain"
                      style={{ fontSize: "28px" }}
                    />
                  </NavLink>
                </Tooltip>
              </li>
              <li className={styles.sidebarIcon}>
                <Tooltip placement="right" title="Deals">
                  <NavLink
                    to={ROUTES.DEALS_PATH}
                    activeClassName={styles.selected}
                    isActive={() => {
                      const pattern = new RegExp(ROUTES.DEALS_PATH);
                      return (
                        this.props.match.url === ROUTES.DEALS_PATH ||
                        pattern.test(this.props.match.url)
                      );
                    }}
                  >
                    <i
                      className="mdi mdi-domain"
                      style={{ fontSize: "28px" }}
                    />
                  </NavLink>
                </Tooltip>
              </li>
            </PermissionGuard>
            <PermissionGuard level={UserScope.Admin}>
              <li className={styles.sidebarIcon}>
                <Tooltip placement="right" title="API">
                  <NavLink
                    to={ROUTES.API_ACCOUNTS_PATH}
                    activeClassName={styles.selected}
                    isActive={() => {
                      const pattern = new RegExp(ROUTES.API_ACCOUNTS_PATH);

                      return (
                        this.props.match.url === ROUTES.API_ACCOUNTS_PATH ||
                        pattern.test(this.props.match.url)
                      );
                    }}
                  >
                    <Icon
                      path={mdiApi}
                      size="28px"
                      color="white"
                      className="tw-inline"
                    />
                  </NavLink>
                </Tooltip>
              </li>
            </PermissionGuard>
          </>
        )}
      </AuthConsumer>
    );
  }
}

export default withRouter(UserNavigationMenu);
