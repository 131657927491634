import React, { useState, useEffect } from "react";
import { Button, Spin, Tabs } from "antd";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { accountQuery } from "../../../state/account/account.query";
import { dealListQuery } from "../../../state/deal/deal-list/deal-list.query";
import {
  AccountDetails,
  fetchAccountDetails
} from "../../../api/account.service";

import { fetchAccountMembers } from "../../../api/member.service";
import { AccountModalAccountDetails } from "./business-account-modal-account-details";
import { AccountDealList } from "./account-deal-list";
import { AccountMemberList } from "./account-members-list";
import { switchAccountType } from "../../../api/account.service";
import { fetchDealList } from "../../../api/deal.service";
import { IDeal } from "../../../interfaces/deal";

import { Modal } from "../../modal/modal";
import { DealModal } from "../../deals/deal-modal/deal-modal";
import { ModalContext, ModalType } from "../../../contexts/modal.context";
import { NewDealModal } from "../../deals/new-deal-modal/new-deal-modal";
import styles from "./business-account-modal.module.scss";
import { DestroyAccountModal } from "../../destroy-account-modal/destroy-account-modal";
import { AccountType } from "../../../interfaces/account";
import { accountMembersQuery } from "../../../state/account/account-members/account-members.query";
import { AccountMember, Invitation } from "../../../interfaces/member";
import { AccountInviteList } from "./account-invite-list";
import { SetAccountCreditModal } from "../../set-account-credit-modal/set-account-credit-modal";
import { SetAccountTeamCapacityModal } from "../../set-account-team-cap-modal/set-account-team-cap-modal";

interface AccountModalProps {
  onClose: () => void;
  accountId: string;
}
export const AccountModal: React.FC<AccountModalProps> = ({
  accountId,
  onClose
}) => {
  const { TabPane } = Tabs;
  const title = `Account details (${accountId})`;

  const [loading, setLoading] = useState(false);
  const [dealsLoading, setDealsLoading] = useState(false);
  const [membersLoading, setMembersLoading] = useState(false);
  const [accountDetails, setAccountDetails] = useState<AccountDetails>();
  const [accountDeals, setAccountDeals] = useState<IDeal[]>();
  const [accountMembers, setAccountMembers] = useState<AccountMember[]>();
  const [accountInvitations, setAccountInvitations] = useState<Invitation[]>();
  const { showModal, hideModal } = React.useContext(ModalContext);

  useEffect(() => {
    if (!accountId) {
      return;
    }

    fetchAccountDetails(accountId);
    fetchDealList(accountId);
    fetchAccountMembers(accountId);

    const unsubcribe$ = new Subject();
    accountQuery
      .select()
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ details }) => {
        setLoading(details.loading);
        setAccountDetails(details.accountDetails);
      });

    dealListQuery
      .select()
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ deals, loading }) => {
        setDealsLoading(loading);
        setAccountDeals(deals);
      });

    accountMembersQuery
      .select()
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ members, invitations, loading }) => {
        setMembersLoading(loading);
        setAccountMembers(members);
        setAccountInvitations(invitations);
      });

    return () => unsubcribe$.next();
  }, [accountId]);

  const onSelect = (params: { accountId: string; dealId: string }) =>
    showModal(
      ModalType.Deal,
      <DealModal
        params={params}
        onClose={(reload?: boolean) => {
          hideModal(ModalType.Deal);
          if (reload) {
            fetchDealList(accountId);
          }
        }}
      />
    );

  const extraButtons = () => {
    if (!accountDetails) {
      return null;
    }

    return (
      <>
        <Button
          type="primary"
          className="btn btn-primary"
          onClick={() =>
            showModal(
              ModalType.SetAccountCredit,
              <SetAccountCreditModal
                accountId={accountId}
                defaultValue={accountDetails}
                closeModal={(reload?: boolean) => {
                  hideModal(ModalType.SetAccountCredit);
                  if (reload) {
                    fetchAccountDetails(accountId);
                  }
                }}
              />
            )
          }
        >
          Set Credit
        </Button>
        <Button
          type="primary"
          className="btn btn-primary"
          onClick={() =>
            showModal(
              ModalType.SetAccountTeamCapacity,
              <SetAccountTeamCapacityModal
                accountId={accountId}
                defaultValue={accountDetails.teamCapacity}
                closeModal={(reload?: boolean) => {
                  hideModal(ModalType.SetAccountTeamCapacity);
                  if (reload) {
                    fetchAccountDetails(accountId);
                  }
                }}
              />
            )
          }
        >
          Set Team Cap
        </Button>
      </>
    );
  };

  return (
    <Modal onDismiss={onClose} className={styles["modal"]} hasCloseIcon>
      <h5 className="mb-3">{title}</h5>
      {loading ? (
        <Spin />
      ) : (
        <Tabs defaultActiveKey="1">
          <TabPane tab="Account" key="1">
            <AccountModalAccountDetails accountDetails={accountDetails} />
          </TabPane>
          <TabPane tab="Deals" key="2">
            <AccountDealList
              accountId={accountId}
              deals={accountDeals}
              loading={dealsLoading}
              onSelect={onSelect}
              //reloadList={() => fetchDealList(accountId)}
            />
          </TabPane>
          <TabPane tab="Members" key="3">
            <AccountMemberList
              accountId={accountId}
              members={accountMembers}
              loading={membersLoading}
            />
          </TabPane>
          <TabPane tab="Invitations" key="4">
            <AccountInviteList
              accountId={accountId}
              invitations={accountInvitations}
              loading={membersLoading}
            />
          </TabPane>
        </Tabs>
      )}

      <div className="d-flex align-items-center justify-content-end mt-5">
        {accountDetails && (
          <Button
            type="primary"
            className="btn btn-danger"
            onClick={() =>
              showModal(
                ModalType.DeleteAccount,
                <DestroyAccountModal
                  accountId={accountDetails.accountId}
                  defaultValue={accountDetails}
                  closeModal={(reload?: boolean) => {
                    hideModal(ModalType.DeleteAccount);
                    if (reload) {
                      fetchAccountDetails(accountDetails.accountId);
                    }
                  }}
                />
              )
            }
          >
            Delete Account
          </Button>
        )}
        <Button
          type="primary"
          className="btn btn-primary"
          onClick={() => {
            if (accountId) {
              switchAccountType(accountId, AccountType.Personal);
              onClose();
            }
          }}
        >
          Switch to Personal
        </Button>
        {extraButtons()}
        <Button
          type="primary"
          className="btn btn-primary"
          onClick={() => {
            showModal(
              ModalType.NewDeal,
              <NewDealModal
                accountId={accountId}
                onSave={() => {
                  hideModal(ModalType.NewDeal);
                  fetchDealList(accountId);
                }}
                onClose={() => hideModal(ModalType.NewDeal)}
              />
            );
          }}
        >
          New Deal
        </Button>
        <Button
          type="primary"
          className="btn btn-primary"
          onClick={() => onClose()}
        >
          OK
        </Button>
      </div>
    </Modal>
  );
};
