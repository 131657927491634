import React, { useState } from "react";
import { Input, Button, Select, Table } from "antd";
import { AccountListItem } from "../../../api/account.service";

const { Option } = Select;

interface ApiAccountsTableProps {
  loading: boolean;
  accountList?: AccountListItem[];
  onSelect: (value: string) => void;
  onSearch: (filter: string, search: string) => void;
  onResetSearch: () => void;
}
export const ApiAccountsTable: React.FC<ApiAccountsTableProps> = ({
  loading,
  accountList,
  onSelect,
  onSearch,
  onResetSearch
}) => {
  const [searchField, setSearchField] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");

  const filtering = () => {
    return (
      <div className="row">
        <div className="col col-2">
          <Select
            style={{ width: "100%" }}
            onChange={(value: string) => {
              console.log("value:", value);
              setSearchField(value);
            }}
            value={searchField}
          >
            <Option value={"account"}>Account Id</Option>
            <Option value={"name"}>Name</Option>
            <Option value={"ownersEmail"}>Owner's Email</Option>
          </Select>
        </div>
        <div className="col col-6">
          <Input
            onChange={(e) => {
              setSearchText(e?.target?.value);
            }}
            value={searchText}
          />
        </div>
        <div className="col col-4">
          <Button
            onClick={() => {
              if (searchText !== "" && searchField !== "") {
                onSearch(searchField, searchText.trim());
              }
            }}
            style={{ marginRight: "8px" }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              setSearchField("");
              setSearchText("");
              onResetSearch();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      {filtering()}

      <hr className="rounded tw-my-2" />
      <Table
        size="small"
        bordered
        loading={loading}
        dataSource={accountList}
        pagination={false}
        rowKey="accountId"
        onRow={(record: AccountListItem) => ({
          onClick: () => onSelect(record.accountId)
        })}
        scroll={{ x: 1000 }}
        columns={[
          {
            title: "Business Name",
            dataIndex: "name",
            key: "name"
          },
          {
            title: "ID",
            dataIndex: "accountId",
            key: "accountId"
          },
          {
            title: "Contact name / account holder",
            dataIndex: "owner",
            key: "ownerName",
            render: (value: AccountListItem) => {
              return <>{value?.name || ""}</>;
            }
          },
          {
            title: "Email",
            dataIndex: "owner",
            key: "ownerEmail",
            render: (value) => {
              return <>{value?.email || ""}</>;
            }
          }
        ]}
      />
    </>
  );
};
