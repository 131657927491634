import dotenv from "dotenv";
import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/react";
import App from "./App";
import config from "./config";
import * as serviceWorker from "./serviceWorker";
import { enableAkitaProdMode } from "@datorama/akita";
import { isProduction } from "./utils/is-production";
import { akitaDevtools } from "@datorama/akita";
import "./index.scss";

dotenv.config();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [], // new Integrations.Tracing()
  tracesSampleRate: 1.0
});

// Google Tag manager
const tagManagerArgs = {
  gtmId: config.gtmId
};

TagManager.initialize(tagManagerArgs);

if (isProduction) {
  enableAkitaProdMode();
} else {
  akitaDevtools();
}

ReactDOM.render(<App />, document.getElementById("root"));
serviceWorker.unregister();
