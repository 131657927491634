import React, { useState, useEffect } from "react";
import { UserScope, userHasPermission } from "../api/scope.service";

interface PermissionGuardProps {
  level: UserScope;
}
export const PermissionGuard: React.FC<PermissionGuardProps> = ({
  level,
  children
}) => {
  const [permission, setHasPermission] = useState(false);

  useEffect(() => {
    const checkHasPermission = async () => {
      const p = await userHasPermission(level);
      setHasPermission(p);
    };

    checkHasPermission();
  }, [level]);

  if (!permission) {
    return null;
  }

  return <>{children}</>;
};
