import { Button, Input, Select } from "antd";
import React, { useEffect, useState } from "react";

interface TableFilterProps {
  initialValue?: { filter?: string; search?: string };
  options?: { filter: string; display: string }[];
  onSearch: ({ filter, search }: { filter: string; search: string }) => void;
  onResetSearch: () => void;
}
export const TableFilter: React.FC<TableFilterProps> = ({
  initialValue,
  options,
  onSearch,
  onResetSearch
}) => {
  const [searchField, setSearchField] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    setSearchField(initialValue?.filter || "");
    setSearchText(initialValue?.search || "");
  }, [initialValue]);

  return (
    <div className="row">
      <div className="col col-2">
        <Select
          style={{ width: "100%" }}
          onChange={(value: string) => {
            setSearchField(value);
          }}
          value={searchField}
        >
          {options?.map((o) => (
            <Select.Option value={o.filter}>{o.display}</Select.Option>
          ))}
        </Select>
      </div>
      <div className="col col-6">
        <Input
          onChange={(e) => {
            setSearchText(e?.target?.value);
          }}
          value={searchText}
        ></Input>
      </div>
      <div className="col col-4">
        <Button
          onClick={() => {
            if (searchText !== "" && searchField !== "") {
              onSearch({ filter: searchField, search: searchText.trim() });
            }
          }}
          style={{ marginRight: "8px" }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            setSearchField("");
            setSearchText("");
            onResetSearch();
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );
};
