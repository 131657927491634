import React from "react";
import { NewTable } from "../../new-table";
import classNames from "classnames";
import subDays from "date-fns/subDays";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import { format } from "date-fns";
import {
  Order,
  languageLookup,
  transcriptionLanguages
} from "@getsubly/common";
import { parseSecondsToText } from "../../../utils/time";
import { Duration } from "luxon";

interface OrdersTableProps {
  ordersList?: Order[];
  onSelect?: (value: string) => void;
}
export const OrdersTable: React.FC<OrdersTableProps> = ({
  ordersList,
  onSelect
}) => {
  return (
    <NewTable
      columns={[
        { label: "Reference", id: "reference", width: "min-content" },
        { label: "Status", id: "status", width: "min-content" },
        { label: "Services", id: "services", width: "1fr" },
        { label: "User", id: "user", width: "min-content" },
        { label: "Media language", id: "media_language", width: "min-content" },
        { label: "Media duration", id: "media_duration", width: "min-content" },
        { label: "Updated", id: "updated", width: "min-content" },
        { label: "Created", id: "created", width: "min-content" }
      ]}
      items={ordersList || []}
      className="tw-flex tw-flex-col tw-overflow-auto tw-whitespace-nowrap tw-max-w-full tw-scroll-auto tw-min-w-0"
    >
      <NewTable.Header>
        {(cols) =>
          cols.map((col, i) => {
            const isFirst = i === 0;
            const isLast = cols.length - 1 === i;

            return (
              <NewTable.HeaderCell
                key={col.id}
                className={classNames(" !tw-pr-2 tw-font-medium", {
                  "!tw-pl-3": isFirst,
                  "!tw-pl-2": !isFirst,
                  "!tw-pr-3": isLast,
                  "!tw-pr-2": !isLast
                })}
              >
                {col.label}
              </NewTable.HeaderCell>
            );
          })
        }
      </NewTable.Header>
      {(ordersList || []).map((item, i) => (
        <Row key={i} item={item} onRowClick={() => onSelect?.(item.id)} />
      ))}
    </NewTable>
  );
};

interface RowInnerProps {
  item: Order;
  onRowClick: (item: Order) => void;
}
const Row: React.FC<RowInnerProps> = ({ item, onRowClick }) => {
  const [isHovered, setHovered] = React.useState(false);

  const sharedClasses = React.useMemo(() => {
    let classes = "tw-min-h-12 tw-cursor-pointer tw-pr-8";
    if (isHovered) classes += " tw-bg-neutral-50 tw-cursor-pointer";
    return classes;
  }, [isHovered]);

  const onClickCell = () => {
    onRowClick(item);
  };

  const sourceLanguage = React.useMemo(() => {
    return item.details?.mediaLanguageCode
      ? languageLookup(transcriptionLanguages, item.details?.mediaLanguageCode)
          ?.language
      : "";
  }, [item]);

  return (
    <>
      <NewTable.Cell
        className={classNames(sharedClasses, "tw-overflow-hidden")}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        {item.reference}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        {item.status === "pending" && (
          <span className="tw-px-1 tw-rounded tw-bg-primary-400 tw-text-white">
            Requested
          </span>
        )}
        {item.status === "in-progress" && (
          <span className="tw-px-1 tw-rounded tw-bg-warning-400 tw-text-white">
            In progress
          </span>
        )}
        {item.status === "completed" && (
          <span className="tw-px-1 tw-rounded tw-bg-success-400 tw-text-white">
            Completed
          </span>
        )}
        {item.status === "cancelled" && (
          <span className="tw-px-1 tw-rounded tw-bg-neutral-400 tw-text-white">
            Cancelled
          </span>
        )}
        {item.status === "rejected" && (
          <span className="tw-px-1 tw-rounded tw-bg-destructive-400 tw-text-white">
            Rejected
          </span>
        )}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        {item.details.services.length + " services"}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        {item.createdBy.email}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        {sourceLanguage}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
        title={parseSecondsToText(item.details.mediaDurationSeconds)}
      >
        {Duration.fromObject({ seconds: item.details.mediaDurationSeconds })
          .shiftTo("hours", "minutes", "seconds")
          .toFormat("hh:mm:ss")}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
        title={item.updatedAt}
      >
        {formatDistanceStrict(
          subDays(new Date(item.updatedAt), 0),
          new Date(),
          { addSuffix: true }
        )}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        {format(new Date(item.createdAt), "yyyy-MM-dd HH:mm:ss")}
      </NewTable.Cell>
    </>
  );
};
