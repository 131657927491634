import { mdiClockOutline, mdiFire, mdiProgressUpload, mdiVideo } from "@mdi/js";
import React, { useState } from "react";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MediaUsageStats } from "../../../interfaces/stats";
import { dashboardQuery } from "../../../state/dashboard/dashboard.query";
import {
  NumberStatsCard,
  NumberStatsCardSize
} from "../../stats-card/number-stats-card/number-stats-card";
import { StatsCard } from "../../stats-card/stats-card";
import { fetchMediaStats } from "../../../api/stats.service";

interface MediaStatsCardsProps {
  notifyOfMediaStatsStatus: (isEnabled: boolean) => void;
}

export const MediaStatsCards: React.FC<MediaStatsCardsProps> = ({
  notifyOfMediaStatsStatus
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | undefined>(false);
  const [stats, setStats] = useState<MediaUsageStats>();

  const loadData = (isEnabled: boolean) => {
    if (!isEnabled) {
      setStats({
        totalBurnt: 0,
        totalSrtDownloads: 0,
        totalTxtDownloads: 0,
        totalVideoDownloads: 0,
        totalVideos: 0,
        uploadDuration: 0,
        uploadSize: 0,
        burntDuration: 0,
        burntSize: 0
      });
      notifyOfMediaStatsStatus(false);
      return;
    }

    const unsubcribe$ = new Subject();

    fetchMediaStats(
      dashboardQuery.filterStartDate,
      dashboardQuery.filterEndDate
    );

    dashboardQuery
      .select("mediaStats")
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ loading, stats, error }) => {
        setLoading(loading);
        setError(error);
        setStats(stats);
      });

    notifyOfMediaStatsStatus(true);

    return () => unsubcribe$.next();
  };

  return (
    <StatsCard title="Media Stats" loadData={loadData}>
      <div className="row">
        <NumberStatsCard
          title="Total uploads"
          loading={loading}
          error={error}
          result={stats?.totalVideos ?? 0}
          icon={mdiVideo}
          size={NumberStatsCardSize.IsHalf}
        />

        <NumberStatsCard
          title="Total burnt videos"
          loading={loading}
          error={error}
          result={stats?.totalBurnt ?? 0}
          icon={mdiFire}
          size={NumberStatsCardSize.IsHalf}
        />
      </div>
      <div className="row mb-0">
        <NumberStatsCard
          title="Upload duration (h)"
          loading={loading}
          error={error}
          result={stats?.uploadDuration ?? 0}
          icon={mdiProgressUpload}
          size={NumberStatsCardSize.IsHalf}
          formatOptions={{ format: "0,0.00", factor: 60 * 60 }}
        />

        <NumberStatsCard
          title="Burnt duration (h)"
          loading={loading}
          error={error}
          result={stats?.burntDuration ?? 0}
          icon={mdiClockOutline}
          size={NumberStatsCardSize.IsHalf}
          formatOptions={{ format: "0,0.00", factor: 60 * 60 }}
        />
      </div>
    </StatsCard>
  );
};
