import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { fetchAuthGraph } from "../../../api/stats.service";
import { AuthGraphData } from "../../../interfaces/stats";
import { dashboardQuery } from "../../../state/dashboard/dashboard.query";
import { StatsCard } from "../../stats-card/stats-card";

interface UserChartCardsProps {
  notifyOfUserChartStatus: (isEnabled: boolean) => void;
}

export const UserChart: React.FC<UserChartCardsProps> = ({
  notifyOfUserChartStatus
}) => {
  const [stats, setStats] = useState<AuthGraphData>();

  const loadData = (isEnabled: boolean) => {
    if (!isEnabled) {
      setStats({
        labels: [],
        signUpData: []
      });
      notifyOfUserChartStatus(false);
      return;
    }

    const unsubcribe$ = new Subject();

    fetchAuthGraph(
      dashboardQuery.filterStartDate,
      dashboardQuery.filterEndDate
    );

    dashboardQuery
      .select("authGraphStats")
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ stats, loading }) => {
        if (loading) {
          setStats(undefined);
        } else {
          setStats(stats);
        }
      });

    notifyOfUserChartStatus(true);

    return () => unsubcribe$.next();
  };

  return (
    <StatsCard title="User Sign Ups" loadData={loadData}>
      <div className="row mb-0">
        <Line
          data={{
            labels: stats?.labels ?? [],
            datasets: [
              {
                label: "Sign ups",
                lineTension: 0.1,
                data: stats?.signUpData ?? [],
                borderColor: "#39efa5",
                fill: false
              }
            ]
          }}
          options={{
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true
                  }
                }
              ]
            }
          }}
        />
      </div>
    </StatsCard>
  );
};
