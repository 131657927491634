import { Button } from "antd";
import React from "react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AccountListItem, fetchAccountList } from "../../api/account.service";
import { ModalContext, ModalType } from "../../contexts/modal.context";
import { accountQuery } from "../../state/account/account.query";
import {
  AccountListParamsState,
  accountStore,
  AccountTypeEnum
} from "../../state/account/account.store";
import { getSingleQueryStringParameterValue } from "../../utils/querystring";
import { AccountsRouteParams } from "../accounts/accounts";
import AdminLayout from "../layouts/adminLayout";
import { NewBusinessAccountModal } from "./new-business-account-modal/new-business-account-modal";
import { NewBusinessUserModal } from "./new-busines-user-modal/new-business-user-modal";
import { AccountModal } from "./business-account-modal/business-account-modal";
import { BusinessAccountTable } from "./business-accounts-table/business-accounts-table";

const BusinessAccounts: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [accountsList, setAccountsList] = useState<AccountListItem[]>();
  const [params, setParams] = useState<AccountListParamsState>();
  const { showModal, hideModal } = React.useContext(ModalContext);

  let { id } = useParams<AccountsRouteParams>();

  const searchField = getSingleQueryStringParameterValue(
    window.location.search,
    "filter"
  );

  const history = useHistory();

  const onSelect = (accountId: string) =>
    showModal(
      ModalType.BusinessAccount,
      <AccountModal
        accountId={accountId}
        onClose={() => hideModal(ModalType.BusinessAccount)}
      />
    );

  const onResetSearch = () => {
    history.push("/business");
  };

  const onSearch = (filter: string, search: string) => {
    let url = `/business/${search}`;

    if (filter !== "accounts") {
      url = url + `?filter=${filter}`;
    }

    history.push(url);
  };

  useEffect(() => {
    let params = {
      filter: "account",
      search: "",
      accountType: AccountTypeEnum.BUSINESS
    };

    if (id != null) {
      params.search = id;

      Boolean(searchField)
        ? (params.filter = searchField)
        : (params.filter = "account");
    }

    setParams(params);

    accountStore.updateParams(params);

    fetchAccountList();

    const unsubcribe$ = new Subject();

    accountQuery
      .select()
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ list }) => {
        setLoading(list.loading);
        setAccountsList(list.accountList);
        if (params.filter === "account" && list.accountList.length === 1) {
          // onSelect(params.search);
        }
      });

    return () => unsubcribe$.next();

    //rerender on route param changes only - everything here goes through the router
  }, [searchField, id]);

  const NewBusiness = () => {
    showModal(
      ModalType.NewBusinessAccount,
      <NewBusinessAccountModal
        onClose={() => hideModal(ModalType.NewBusinessAccount)}
      />
    );
  };

  const NewBusinessUser = () => {
    showModal(
      ModalType.NewBusinessUser,
      <NewBusinessUserModal
        onClose={() => hideModal(ModalType.NewBusinessUser)}
      />
    );
  };

  const buttons = (
    <>
      <Button className="btn btn-primary" onClick={NewBusiness}>
        New business
      </Button>
      <Button className="btn btn-primary" onClick={NewBusinessUser}>
        New user
      </Button>
    </>
  );

  return (
    <>
      <AdminLayout
        parent="Lists"
        title="Business Account List"
        buttons={buttons}
      >
        <BusinessAccountTable
          loading={loading}
          params={params}
          accountList={accountsList}
          onSelect={onSelect}
          onSearch={onSearch}
          onResetSearch={onResetSearch}
        />
      </AdminLayout>
    </>
  );
};

export default BusinessAccounts;
