import { Store, StoreConfig } from "@datorama/akita";

export interface NewDealModalState {
  accountId?: string;
  params: { description: string; teamCapacity: number; creditSeconds: number };
  init: any;
  loading: boolean;
  creating: boolean;
}

@StoreConfig({ name: "new-deal-modal" })
export class NewDealModalStore extends Store<NewDealModalState> {
  constructor() {
    super({
      init: undefined,
      loading: false,
      creating: false
    });
  }

  updateState(data: Partial<NewDealModalState>): void {
    this.update((s) => ({
      ...s,
      data
    }));
  }
}

export const newDealModalStore = new NewDealModalStore();
