import moment from "moment";

export const formatDate = (date: Date, format = "DD/MM/YYYY"): string => {
  if (!date) return "";
  return moment(date).format(format);
};

export const formatDateTime = (
  date: Date,
  format = "DD/MM/YYYY HH:mm"
): string => {
  return formatDate(date, format);
};

export const formatDaysFromNow = (date: Date) => {
  if (!date) return "";
  return moment(date).fromNow();
};
