import { mdiCloudDownload } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, DatePicker } from "antd";
import { RangePickerValue } from "antd/lib/date-picker/interface";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  fetchActiveUsersGraphData,
  fetchActiveUsersStatsData,
  fetchAuthGraph,
  fetchAuthStats,
  fetchMediaStats,
  fetchUserMediaStats,
  fetchTopReferrers,
  fetchUserStatsCSV
} from "../../api/stats.service";
import { dashboardQuery } from "../../state/dashboard/dashboard.query";
import { dashboardStore } from "../../state/dashboard/dashboard.store";
import AdminLayout from "../layouts/adminLayout";
import { StatsCard } from "../stats-card/stats-card";
import { ActiveUsersChart } from "./active-users-chart/active-users-chart";
import { ActiveUsersStatsCards } from "./active-users-stats-cards/active-users-stats-cards";
import { AuthStatsCards } from "./auth-stats-cards/auth-stats-cards";
import { DownloadStatsCards } from "./download-stats-cards/download-stats-cards";
import { MediaStatsCards } from "./media-stats-cards/media-stats-cards";
import { TotalStatsCards } from "./total-stats-cards/total-stats-cards";
import { ReferrersStatsCard } from "./referrers-stats-card/referrers-stats-card";
import { UserChart } from "./user-chart/user-chart";
import { UserMediaStatsCards } from "./user-media-stats-cards/user-media-stats-cards";
import { PermissionGuard } from "../../guards/permission-guard";
import { UserScope } from "../../api/scope.service";

const Dashboard: React.FC = () => {
  const [filtersDates, setFiltersDates] = useState<[Date, Date]>(
    dashboardQuery.filtersDates
  );

  const [areAuthStatsEnabled, setAreAuthStatsEnabled] = useState(false);
  const [areMediaStatsEnabled, setAreMediaStatsEnabled] = useState(false);
  const [areFreeUserMediaStatsEnabled, setAreFreeUserMediaStatsEnabled] =
    useState(false);
  const [arePaidUserMediaStatsEnabled, setArePaidUserMediaStatsEnabled] =
    useState(false);
  const [areActiveUsersStatsEnabled, setAreActiveUsersStatsEnabled] =
    useState(false);
  const [areDownloadStatsEnabled, setAreDownloadStatsEnabled] = useState(false);
  const [isUserChartEnabled, setIsUserChartEnabled] = useState(false);
  const [isActiveUsersChartEnabled, setIsActiveUsersChartEnabled] =
    useState(false);
  const [isReferrersTableEnabled, setIsReferrersTableEnabled] = useState(false);

  const notifyOfAuthStatsStatus = (isEnabled: boolean) => {
    setAreAuthStatsEnabled(isEnabled);
  };

  const notifyOfMediaStatsStatus = (isEnabled: boolean) => {
    setAreMediaStatsEnabled(isEnabled);
  };

  const notifyOfFreeUserMediaStatsStatus = (isEnabled: boolean) => {
    setAreFreeUserMediaStatsEnabled(isEnabled);
  };

  const notifyOfPaidUserMediaStatsStatus = (isEnabled: boolean) => {
    setArePaidUserMediaStatsEnabled(isEnabled);
  };

  const notifyOfActiveUsersStatsStatus = (isEnabled: boolean) => {
    setAreActiveUsersStatsEnabled(isEnabled);
  };

  const notifyOfDownloadStatsStatus = (isEnabled: boolean) => {
    setAreDownloadStatsEnabled(isEnabled);
  };

  const notifyOfUserChartStatus = (isEnabled: boolean) => {
    setIsUserChartEnabled(isEnabled);
  };

  const notifyOfActiveUsersChartStatus = (isEnabled: boolean) => {
    setIsActiveUsersChartEnabled(isEnabled);
  };

  const notifyOfReferrersTableStatus = (isEnabled: boolean) => {
    setIsReferrersTableEnabled(isEnabled);
  };

  const onRangePickerChange = (dates: RangePickerValue) => {
    const startDate = dates[0] && dates[0].utc().startOf("D").toDate();
    const endDate = dates[1] && dates[1].utc().endOf("D").toDate();

    if (!startDate || !endDate) {
      return;
    }

    setFiltersDates([startDate, endDate]);

    dashboardStore.update({
      filters: {
        startDate: startDate,
        endDate: endDate
      }
    });

    if (areAuthStatsEnabled) {
      fetchAuthStats(startDate, endDate);
    }
    if (areMediaStatsEnabled || areDownloadStatsEnabled) {
      fetchMediaStats(startDate, endDate);
    }

    if (areFreeUserMediaStatsEnabled) {
      fetchUserMediaStats(startDate, endDate, false);
    }

    if (arePaidUserMediaStatsEnabled) {
      fetchUserMediaStats(startDate, endDate, true);
    }

    if (areActiveUsersStatsEnabled) {
      fetchActiveUsersStatsData(startDate, endDate);
    }

    if (isUserChartEnabled) {
      fetchAuthGraph(startDate, endDate);
    }

    if (isActiveUsersChartEnabled) {
      fetchActiveUsersGraphData(startDate, endDate);
    }

    if (isReferrersTableEnabled) {
      fetchTopReferrers(startDate, endDate, dashboardQuery.topReferrersTake);
    }
  };

  return (
    <AdminLayout
      parent="Dashboard"
      title="Admin Stats"
      buttons={
        <PermissionGuard level={UserScope.Admin}>
          <DownloadCSVButton />
        </PermissionGuard>
      }
    >
      <TotalStatsCards />

      <StatsCard
        title="Filtered Stats"
        subtitle={
          <DatePicker.RangePicker
            value={[
              moment(filtersDates[0]).utc().startOf("D"),
              moment(filtersDates[1]).utc().endOf("D")
            ]}
            onChange={onRangePickerChange}
            allowClear={false}
            format="DD/MM/YYYY"
            separator="-"
          />
        }
      >
        <div className="row">
          <div className="col-sm-6">
            <AuthStatsCards notifyOfAuthStatsStatus={notifyOfAuthStatsStatus} />
          </div>
          <div className="col-sm-6">
            <MediaStatsCards
              notifyOfMediaStatsStatus={notifyOfMediaStatsStatus}
            />
          </div>
        </div>
        <PermissionGuard level={UserScope.Admin}>
          <UserMediaStatsCards
            notifyOfUserMediaStatsStatus={notifyOfFreeUserMediaStatsStatus}
            isPaid={false}
          />
        </PermissionGuard>
        <PermissionGuard level={UserScope.Admin}>
          <UserMediaStatsCards
            notifyOfUserMediaStatsStatus={notifyOfPaidUserMediaStatsStatus}
            isPaid={true}
          />
        </PermissionGuard>
        <PermissionGuard level={UserScope.Admin}>
          <ActiveUsersStatsCards
            notifyOfActiveUsersStatsStatus={notifyOfActiveUsersStatsStatus}
          />
        </PermissionGuard>
        <PermissionGuard level={UserScope.Admin}>
          <DownloadStatsCards
            notifyOfDownloadStatsStatus={notifyOfDownloadStatsStatus}
          />
        </PermissionGuard>
        <PermissionGuard level={UserScope.Admin}>
          <UserChart notifyOfUserChartStatus={notifyOfUserChartStatus} />
        </PermissionGuard>
        <PermissionGuard level={UserScope.Admin}>
          <ActiveUsersChart
            notifyOfActiveUsersChartStatus={notifyOfActiveUsersChartStatus}
          />
        </PermissionGuard>
        <ReferrersStatsCard
          notifyOfSelectionStatus={notifyOfReferrersTableStatus}
        />
      </StatsCard>

      {/* TODO: This is very expensive call. Check if we really want this table.
      <StatsCard title="Top 50 users">
        <TopUsersTable />
      </StatsCard>
      */}
    </AdminLayout>
  );
};

export default Dashboard;

export const DownloadCSVButton: React.FC = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const unsubcribe$ = new Subject();

    dashboardQuery
      .select("preparingDownload")
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ loading }) => {
        setLoading(loading);
      });
    return () => unsubcribe$.next();
  }, []);

  const handleClick = () => {
    fetchUserStatsCSV();
  };

  return (
    <Button
      className="btn btn-primary"
      style={{
        display: "flex",
        alignItems: "center"
      }}
      loading={loading}
      onClick={handleClick}
    >
      {!loading && (
        <Icon path={mdiCloudDownload} size="1.4em" className="mr-2" />
      )}
      Download CSV
    </Button>
  );
};
