import React, { useState } from "react";
import { Button, Checkbox, DatePicker } from "antd";
import { DateTime } from "luxon";
import { finaliseDeal } from "../../../api/deal.service";
import { Modal } from "../../modal/modal";
import styles from "./finalise-deal-modal.module.scss";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

interface FinaliseDealModalProps {
  params: { accountId: string; dealId: string };
  onClose: (reload?: boolean) => void;
}
export const FinaliseDealModal: React.FC<FinaliseDealModalProps> = ({
  params,
  onClose
}) => {
  const title = `Finalise Deal`;
  // const [startedAt, setStartedAt] = useState<DateTime>(
  //   DateTime.fromJSDate(new Date())
  // );
  const [date, setDate] = useState<moment.Moment | null>(null);
  const [isTrial, setIsTrial] = useState<boolean>(false);
  const [endingAt, setEndingAt] = useState<DateTime>(
    DateTime.fromJSDate(new Date())
  );

  const onEndDateChange = (m: moment.Moment | null) => {
    setDate(m);
    if (m) {
      setEndingAt(DateTime.fromJSDate(m.toDate()));
    }
  };

  const onIsTrialChange = (e: CheckboxChangeEvent) => {
    setIsTrial(!isTrial);
  };

  const onFinaliseDeal = async () => {
    await finaliseDeal({
      ...params,
      // startDate,
      endingAt,
      isTrial
    });
    onClose(true);
  };

  return (
    <Modal onDismiss={onClose} className={styles["modal"]} hasCloseIcon>
      <h5 className="mb-3">{title}</h5>
      {/* <label style={{ width: "100%" }}>
        Start Date:
        <input
          type="date"
          onChange={(e) =>
            setStartedAt(DateTime.fromJSDate(new Date(e.target.value)))
          }
        />
      </label> */}
      <label style={{ width: "100%" }}>
        End Date:
        <DatePicker onChange={onEndDateChange} value={date} />
      </label>
      <label style={{ width: "100%" }}>
        Is Trial:
        <Checkbox onChange={onIsTrialChange} checked={isTrial} />
      </label>

      <div className="d-flex align-items-center justify-content-end mt-5">
        <Button
          type="primary"
          className="btn btn-primary"
          onClick={onFinaliseDeal}
        >
          Finalise
        </Button>
        <Button
          type="primary"
          className="btn btn-primary"
          onClick={() => onClose()}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};
