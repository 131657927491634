import { Store, StoreConfig } from "@datorama/akita";
import { JobDetails, JobListItem } from "../../api/job.service";

export interface JobListState {
  loading: boolean;
  jobList: JobListItem[];
}

export interface JobDetailsState {
  loading: boolean;
  jobDetails?: JobDetails;
}

export interface JobsState {
  list: JobListState;
  details: JobDetailsState;
}

@StoreConfig({ name: "jobs" })
export class JobsStore extends Store<JobsState> {
  constructor() {
    super({
      list: { loading: false, jobList: [] },
      details: {
        loading: false
      }
    });
  }

  updateList(list: Partial<JobListState>) {
    this.update((s) => ({
      ...s,
      list: {
        ...s.list,
        ...list
      }
    }));
  }

  updateDetails(details: Partial<JobDetailsState>) {
    this.update((s) => ({
      ...s,
      details: {
        ...s.details,
        ...details
      }
    }));
  }
}

export const jobsStore = new JobsStore();
