import { Store, StoreConfig } from "@datorama/akita";
import { GroupItem, UserDetails, UserListItem } from "../../api/user.service";

export interface UserListParamsState {
  filter?: string;
  search?: string;
  limit?: number;
  paginationToken?: string;
}

export interface UserListState {
  loading: boolean;
  userList: UserListItem[];
}

export interface UserGroupsState {
  loading: boolean;
  groups: GroupItem[];
}

export interface UserDetailsState {
  loading: boolean;
  userDetails?: UserDetails;
}

export interface UsersState {
  params: UserListParamsState;
  list: UserListState;
  group: UserGroupsState;
  details: UserDetailsState;
}

@StoreConfig({ name: "users" })
export class UsersStore extends Store<UsersState> {
  constructor() {
    super({
      params: {
        filter: undefined,
        search: undefined,
        limit: 60,
        paginationToken: undefined
      },
      list: { loading: false, userList: [] },
      group: { loading: false, groups: [] },
      details: {
        loading: false
      }
    });
  }

  updateList(list: Partial<UserListState>) {
    this.update((s) => ({
      ...s,
      list: {
        ...s.list,
        ...list
      }
    }));
  }

  updateParams(params: UserListParamsState) {
    this.update((s) => ({
      ...s,
      params: {
        ...s.params,
        ...params
      }
    }));
  }

  updatePagination(token?: string) {
    this.update((s) => ({
      ...s,
      params: {
        ...s.params,
        paginationToken: token
      }
    }));
  }

  updateUserGroups(groups: Partial<UserGroupsState>) {
    this.update((s) => ({
      ...s,
      group: {
        ...s.group,
        ...groups
      }
    }));
  }

  updateDetails(details: Partial<UserDetailsState>) {
    this.update((s) => ({
      ...s,
      details: {
        ...s.details,
        ...details
      }
    }));
  }

  addGroupToDetails(group: string) {
    this.update((s) => {
      let details: UserDetailsState = s.details;

      if (
        details &&
        details.userDetails &&
        details.userDetails.cognito.groups
      ) {
        details.userDetails.cognito.groups.push(group);
      }

      return {
        ...s,
        details
      };
    });
  }

  removeGroupFromDetails(group: string) {
    this.update((s) => {
      let details: UserDetailsState = s.details;

      if (
        details &&
        details.userDetails &&
        details.userDetails.cognito.groups
      ) {
        details.userDetails.cognito.groups.filter((g: string) => g !== group);
      }

      return {
        ...s,
        details
      };
    });
  }
}

export const usersStore = new UsersStore();
