import React from "react";
import { chunk } from "lodash";

export type NewTableColumn = {
  label: string;
  id: string | number;
  width: string | number;
};

export type NewTableItem = any;

export interface NewTableContext {
  columns: NewTableColumn[];
  items: NewTableItem;
  registerCell: (id: string) => void;
  unregisterCell: (id: string) => void;
  hoveredCell: string | null;
  setHoveredCell: (id: string | null) => void;
  hoveredRowCells: NewTableItem;
}
export const NewTableContext =
  React.createContext<NewTableContext | null>(null);
interface NewTableProviderProps {
  children: React.ReactNode;
  columns: NewTableColumn[];
  items: NewTableItem;
}
export const NewTableProvider: React.FC<NewTableProviderProps> = ({
  columns,
  items,
  children
}) => {
  const [registeredCells, setRegisteredCells] = React.useState<string[]>([]);
  const registerCell = (id: string) =>
    setRegisteredCells((prev) => [...prev, id]);
  const unregisterCell = (id: string) =>
    setRegisteredCells((prev) => prev.filter((cellId) => cellId !== id));
  const [hoveredCell, setHoveredCell] = React.useState<string | null>(null);
  const hoveredRowCells = React.useMemo(() => {
    return hoveredCell
      ? chunk(registeredCells, columns.length).find((chunk) =>
          chunk.includes(hoveredCell)
        )
      : [];
  }, [hoveredCell, registeredCells, columns.length]);

  return (
    <NewTableContext.Provider
      value={{
        columns,
        items,
        registerCell,
        unregisterCell,
        hoveredCell,
        setHoveredCell,
        hoveredRowCells
      }}
    >
      {children}
    </NewTableContext.Provider>
  );
};
export const useNewTableContext = () => {
  const context = React.useContext(NewTableContext);

  if (context === null) {
    throw new Error("useNewTableContext must be used within a Table2Provider");
  }

  return context;
};
