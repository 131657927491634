import { DateTime } from "luxon";

export enum CreditType {
  Free = "FREE",
  Paid = "PAID",
  Extra = "EXTRA"
}

export enum AccountType {
  Personal = "PERSONAL",
  Business = "BUSINESS",
  Api = "API"
}

export interface IAccountType {
  id: string;
  type: AccountType;
}

export interface IAccountBilling {
  stripeId?: string;
  foundingMember?: boolean;
  planName?: string;
  createdAt?: string;
  renewAt?: string;
  cancelAt?: string;
  interval?: "year" | "month" | string;
  monthlyTopupCredit?: number;
  nextTopupDate?: string;
}

export interface IAccountSettings {
  beta?: boolean;
  primaryColors?: string[];
  outlineColors?: string[];
  hasSeenMediaEditorTour?: boolean;
}

export interface IUpdateAccountDetails {
  accountName: string;
  accountType: AccountType;
}

export interface IAccount {
  id: string;
  accountName: string;
  accountType: IAccountType;
  typeId: string;
  creditFreeSeconds: number;
  creditPaidSeconds: number;
  creditPaygSeconds: number;
  creditExtraSeconds: number;
  totalFreeSecondsUsed: number;
  totalPaidSecondsUsed: number;
  billing?: IAccountBilling;
  settings?: IAccountSettings;
  transactions?: ITransaction[];
  createdAt: DateTime;
  updatedAt: DateTime;
}

export type IAccountCredit = Pick<
  IAccount,
  | "creditFreeSeconds"
  | "creditPaidSeconds"
  | "creditPaygSeconds"
  | "creditExtraSeconds"
>;

export interface ITransaction {
  id: string;
  accountId: string;
  invoiceId?: string;
  creditType: CreditType;
  creditFreeSeconds: number;
  creditPaidSeconds: number;
  creditPaygSeconds: number;
  creditExtraSeconds: number;
  adjustment: number;
  reason: string;
  metadata?: ITransactionMetadata;
  createdAt: DateTime;
}

export interface ITransactionMetadata {
  credit_seconds?: string;
  topupYear?: number;
  topupMonth?: number;
  subscriptionId?: string;
  fileId?: string;
  waiver?: number;
  [prop: string]: any;
}
