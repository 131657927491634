import { notification } from "antd";
import axios from "axios";
import { EN } from "../assets/i18n/en";
import config from "../config";
import {
  AuthGraphData,
  AuthUsageStats,
  MediaUsageStats,
  UsersStats,
  UserUsageStats,
  ActiveUsersGraphData,
  ActiveUsersStats,
  UserMediaStats,
  ReferrerUser,
  IApiAccountStats
} from "../interfaces/stats";
import {
  DashboardState,
  dashboardStore
} from "../state/dashboard/dashboard.store";
import { getAccessToken } from "./auth.service";

const baseURL = `${config.apiUrl}/api/v1/admin`;

interface UsersStatsResponse {
  usersStats: UsersStats;
}
export const fetchUsersStats = async () => {
  try {
    dashboardStore.setStatsLoading("usersStats", true);

    const { data } = await axios.get<UsersStatsResponse>(
      `${baseURL}/stats/users`,
      {
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );

    dashboardStore.updateStats("usersStats", data.usersStats);
  } catch (error) {
    handleError(error);
  } finally {
    dashboardStore.setStatsLoading("usersStats", false);
  }
};

interface AuthUsageStatsResponse {
  start: Date;
  end: Date;
  authUsage: AuthUsageStats;
}
export const fetchAuthStats = async (startDate: Date, endDate: Date) => {
  try {
    dashboardStore.setStatsLoading("authStats", true);

    const { data } = await axios.get<AuthUsageStatsResponse>(
      `${baseURL}/stats/auth?${createDateParams(startDate, endDate)}`,
      {
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );

    dashboardStore.updateStats("authStats", data.authUsage);
  } catch (error) {
    handleError(error);
  } finally {
    dashboardStore.setStatsLoading("authStats", false);
  }
};

interface AuthGraphDataResponse {
  start: Date;
  end: Date;
  authGraphData: AuthGraphData;
}
export const fetchAuthGraph = async (startDate: Date, endDate: Date) => {
  try {
    dashboardStore.setStatsLoading("authStats", true);

    const { data } = await axios.get<AuthGraphDataResponse>(
      `${baseURL}/stats/auth-graph?${createDateParams(startDate, endDate)}`,
      {
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );

    dashboardStore.updateStats("authGraphStats", data.authGraphData);
  } catch (error) {
    handleError(error);
  } finally {
    dashboardStore.setStatsLoading("authStats", false);
  }
};

interface ActiveUsersStatsResponse {
  start: Date;
  end: Date;
  activeUserStats: ActiveUsersStats;
}

export const fetchActiveUsersStatsData = async (
  startDate: Date,
  endDate: Date
) => {
  const dataKey = "activeUsersStats";

  try {
    dashboardStore.setStatsLoading(dataKey, true);
    const { data } = await axios.get<ActiveUsersStatsResponse>(
      `${baseURL}/stats/active-users?${createDateParams(startDate, endDate)}`,
      {
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );

    dashboardStore.updateStats(dataKey, data.activeUserStats);
  } catch (error) {
    handleError(error, dataKey);
  } finally {
    dashboardStore.setStatsLoading(dataKey, false);
  }
};

interface ActiveUsersGraphDataResponse {
  start: Date;
  end: Date;
  activeUsersGraph: ActiveUsersGraphData;
}
export const fetchActiveUsersGraphData = async (
  startDate: Date,
  endDate: Date,
  period: number = 30
) => {
  try {
    dashboardStore.setStatsLoading("activeUsersGraphStats", true);

    const { data } = await axios.get<ActiveUsersGraphDataResponse>(
      `${baseURL}/stats/active-users-graph?${createDateParams(
        startDate,
        endDate
      )}&period=${period}`,
      {
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );

    dashboardStore.updateStats("activeUsersGraphStats", data.activeUsersGraph);
  } catch (error) {
    handleError(error);
  } finally {
    dashboardStore.setStatsLoading("activeUsersGraphStats", false);
  }
};

interface UserListStatsResponse {
  take: Date;
  skip: Date;
  users: UserUsageStats[];
}
export const fetchUserListStats = async (take = 50) => {
  try {
    dashboardStore.setStatsLoading("userListStats", true);

    const { data } = await axios.get<UserListStatsResponse>(
      `${baseURL}/stats/users-list?take=${take}`,
      {
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );

    dashboardStore.updateStats("userListStats", data.users);
  } catch (error) {
    handleError(error);
  } finally {
    dashboardStore.setStatsLoading("userListStats", false);
  }
};

interface MediaUsageStatsResponse {
  start: Date;
  end: Date;
  mediaUsage: MediaUsageStats;
}
export const fetchMediaStats = async (startDate: Date, endDate: Date) => {
  const dataKey = "mediaStats";

  try {
    dashboardStore.setStatsLoading(dataKey, true);

    const { data } = await axios.get<MediaUsageStatsResponse>(
      `${baseURL}/stats/media?${createDateParams(startDate, endDate)}`,
      {
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );

    dashboardStore.updateStats(dataKey, data.mediaUsage);
  } catch (error) {
    handleError(error, dataKey);
  } finally {
    dashboardStore.setStatsLoading(dataKey, false);
  }
};

interface UserMediaStatsResponse {
  start: Date;
  end: Date;
  isPaid: boolean;
  userMediaStats: UserMediaStats;
}

export const fetchUserMediaStats = async (
  startDate: Date,
  endDate: Date,
  isPaid: boolean
) => {
  const dataKey = isPaid ? "paidUserMediaStats" : "freeUserMediaStats";

  try {
    dashboardStore.setStatsLoading(dataKey, true);

    const { data } = await axios.get<UserMediaStatsResponse>(
      `${baseURL}/stats/user-media?${createDateParams(
        startDate,
        endDate
      )}&isPaid=${isPaid}`,
      {
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );

    dashboardStore.updateStats(dataKey, data.userMediaStats);
  } catch (error) {
    handleError(error, dataKey);
  } finally {
    dashboardStore.setStatsLoading(dataKey, false);
  }
};

interface TopReferrersResponse {
  users: ReferrerUser[];
}

export const fetchTopReferrers = async (
  startDate: Date,
  endDate: Date,
  take = 10
) => {
  try {
    dashboardStore.setStatsLoading("topReferrers", true);

    const { data } = await axios.get<TopReferrersResponse>("top-referrers", {
      baseURL,
      headers: {
        "x-access-token": await getAccessToken()
      },
      params: {
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        take
      }
    });

    dashboardStore.updateStats("topReferrers", data.users);
  } catch (e) {
    handleError(e);
  } finally {
    dashboardStore.setStatsLoading("topReferrers", false);
  }
};

export const fetchUserStatsCSV = async () => {
  try {
    dashboardStore.setStatsLoading("preparingDownload", true);

    const { data } = await axios.get(`${baseURL}/download/users-list`, {
      headers: {
        "x-access-token": await getAccessToken()
      }
    });

    if (!data) {
      return;
    }

    const filename = "user_stats.csv";
    const blob = new Blob([data]);

    saveAs(blob, filename);
  } catch (error) {
    handleError(error);
  } finally {
    dashboardStore.setStatsLoading("preparingDownload", false);
  }
};

interface IApiAccountStatsResponse {
  start: Date;
  end: Date;
  apiAccountStats: IApiAccountStats;
}

export const fetchApiAccountStats = async ({
  accountId,
  startDate,
  endDate
}: {
  accountId: string;
  startDate: Date;
  endDate: Date;
}): Promise<IApiAccountStats | undefined> => {
  try {
    const { data } = await axios.get<IApiAccountStatsResponse>(
      `/api/${accountId}/stats`,
      {
        baseURL,
        headers: {
          "x-access-token": await getAccessToken()
        },
        params: {
          start: startDate.toISOString(),
          end: endDate.toISOString()
        }
      }
    );

    return data.apiAccountStats;
  } catch (e) {
    handleError(e);
  }
};

// Private functions

const handleError = (e: any, dataKey?: keyof DashboardState) => {
  console.error(e);

  notification.error({
    message: "Something went wrong",
    description: e?.message || EN.error.defaultMessage,
    duration: 10
  });

  if (dataKey) {
    dashboardStore.setStatsError(dataKey);
  }
};

const createDateParams = (start: Date, end: Date): string => {
  return `start=${start.toISOString()}&end=${end.toISOString()}`;
};
