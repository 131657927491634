import { Button, notification } from "antd";
import React from "react";
import { isEducation, UserDetailsCognito } from "../../../api/user.service";
import { EN } from "../../../assets/i18n/en";
import "./toggle-education.scss";

interface ToggleEducationProps {
  details: UserDetailsCognito;
  isToggling: boolean;
  isEditingOrSavingCustomSettings: boolean;
  setUserEducation: (value: boolean, username?: string) => void;
  onToggling: (isToggling: boolean) => void;
}

export const ToggleEducation: React.FC<ToggleEducationProps> = ({
  details,
  isToggling,
  isEditingOrSavingCustomSettings,
  setUserEducation,
  onToggling
}) => {
  const isEducationUser = isEducation(details);

  const onSave = async () => {
    onToggling(true);

    try {
      await setUserEducation(!isEducationUser, details.username);
    } catch (err) {
      notification.error({
        message: "Failed to update user education",
        description: (err as any)?.message || EN.error.defaultMessage,
        duration: 10
      });
    } finally {
      onToggling(false);
    }
  };

  return isToggling || isEditingOrSavingCustomSettings ? null : (
    <Button
      onClick={() => onSave()}
      className="ant-btn btn btn-primary btn-toggle-education btn-sm ml-4"
    >
      {isEducationUser ? "Remove" : "Add"} Education
    </Button>
  );
};
