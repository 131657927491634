import React, { useEffect, useState } from "react";
import { Input, Button, Select, Table } from "antd";
import { AccountListItem, IAccountBilling } from "../../../api/account.service";
import { AccountListParamsState } from "../../../state/account/account.store";
import { calcMinutesLeft } from "../../../utils/calc";
import { formatDate } from "../../../utils/date-formatter";

const { Option } = Select;

interface BusinessAccountTableProps {
  loading: boolean;
  accountList?: AccountListItem[];
  params?: AccountListParamsState;
  onSelect: (value: string) => void;
  onSearch: (filter: string, search: string) => void;
  onResetSearch: () => void;
}
export const BusinessAccountTable: React.FC<BusinessAccountTableProps> = ({
  loading,
  accountList,
  params,
  onSelect,
  onSearch,
  onResetSearch
}) => {
  const [searchField, setSearchField] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    setSearchField(params?.filter || "");
    setSearchText(params?.search || "");
  }, [params]);

  const filtering = () => {
    return (
      <div className="row">
        <div className="col col-2">
          <Select
            style={{ width: "100%" }}
            onChange={(value: string) => {
              console.log("value:", value);
              setSearchField(value);
            }}
            value={searchField}
          >
            <Option value={"account"}>Account Id</Option>
            <Option value={"name"}>Name</Option>
            <Option value={"ownersEmail"}>Owner's Email</Option>
          </Select>
        </div>
        <div className="col col-6">
          <Input
            onChange={(e) => {
              setSearchText(e?.target?.value);
            }}
            value={searchText}
          />
        </div>
        <div className="col col-4">
          <Button
            onClick={() => {
              if (searchText !== "" && searchField !== "") {
                onSearch(searchField, searchText.trim());
              }
            }}
            style={{ marginRight: "8px" }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              setSearchField("");
              setSearchText("");
              onResetSearch();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    );
  };

  const getPercentageMonthlyUsage = (record: any) => {
    return record.billing?.monthlyTopupCredit
      ? Math.round(
          (((record.creditFreeSeconds || 0) +
            (record.creditExtraSeconds || 0) +
            (record.creditPaidSeconds || 0) +
            (record.creditPaygSeconds || 0)) *
            100) /
            record.billing?.monthlyTopupCredit
        )
      : 0;
  };

  return (
    <>
      {filtering()}

      <hr className="rounded tw-my-2" />
      <Table
        size="small"
        bordered
        loading={loading}
        dataSource={accountList}
        pagination={false}
        rowKey="accountId"
        onRow={(record: AccountListItem) => ({
          onClick: () => onSelect(record.accountId)
        })}
        scroll={{ x: 1300 }}
        columns={[
          {
            title: "Business Name",
            dataIndex: "name",
            key: "name"
          },
          {
            title: "ID",
            dataIndex: "accountId",
            key: "accountId"
          },
          {
            title: "Contact name / account holder",
            dataIndex: "owner",
            key: "ownerName",
            render: (value: AccountListItem) => {
              return <>{value?.name || ""}</>;
            }
          },
          {
            title: "Email",
            dataIndex: "owner",
            key: "ownerEmail",
            render: (value) => {
              return <>{value?.email || ""}</>;
            }
          },
          {
            title: "Number of users",
            dataIndex: "numberOfUsers",
            key: "numberOfUsers",
            render: (value) => {
              return <>{value || ""}</>;
            }
          },
          {
            title: "Plan",
            dataIndex: "billing",
            key: "plan",
            filters: [
              {
                text: "Trial",
                value: "Trial"
              }
            ],
            onFilter: (value: string, record) =>
              Boolean(record.billing?.trialPlanName) || false,
            render: (value: IAccountBilling) => {
              const name = value?.trialPlanName
                ? `Trial ${value?.trialPlanName}`
                : value?.planName;
              return <>{name}</>;
            }
          },
          {
            title: "Contract value",
            dataIndex: "billing",
            key: "price",
            render: (value) => {
              const name = value?.value
                ? value?.currency
                  ? `${value?.currency} ${value?.value / 100}`
                  : value?.value
                : "";
              return <>{name}</>;
            }
          },
          {
            title: "Start date",
            dataIndex: "billing",
            key: "startDate",
            render: (value) => {
              const name = value?.trialCreatedAt
                ? formatDate(value.trialCreatedAt)
                : value?.createdAt
                ? formatDate(value.createdAt)
                : "";
              return <>{name}</>;
            }
          },
          {
            title: "End date",
            dataIndex: "billing",
            key: "endDate",
            render: (value) => {
              const name = value?.trialEndAt
                ? formatDate(value.trialEndAt)
                : value?.cancelAt
                ? formatDate(value.cancelAt)
                : "";
              return <>{name}</>;
            }
          },
          {
            title: "Minutes given",
            dataIndex: "billing",
            key: "minutesGiven",
            render: (value) => {
              return <>{Math.floor(value?.monthlyTopupCredit / 60) || ""}</>;
            },
            sorter: (a, b) =>
              (a.billing?.monthlyTopupCredit || 0) -
              (b.billing?.monthlyTopupCredit || 0)
          },
          {
            title: "Minutes remaining",
            key: "minutesRemaining",
            render: (value) => {
              const minutesLeft = calcMinutesLeft(value);
              return <>{minutesLeft || ""}</>;
            },
            sorter: (a, b) => calcMinutesLeft(a) - calcMinutesLeft(b)
          },
          {
            title: "Credit remaining (%)",
            dataIndex: "billing",
            key: "secondsRemainingPercent",
            render: (value, record) => {
              const result = getPercentageMonthlyUsage(record);
              return <>{result || ""}</>;
            },
            sorter: (a, b) =>
              getPercentageMonthlyUsage(a) - getPercentageMonthlyUsage(b)
          }
        ]}
      />
    </>
  );
};
