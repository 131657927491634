import React from "react";
import { Table } from "antd";
import { ReferrerUser } from "../../../interfaces/stats";

interface ReferrersTableProps {
  loading: boolean;
  stats?: ReferrerUser[];
}

export const ReferrersTable: React.FC<ReferrersTableProps> = ({
  loading,
  stats = []
}) => (
  <Table
    size="small"
    bordered
    loading={loading}
    dataSource={stats}
    rowKey="referrer"
    scroll={{ x: true }}
    columns={[
      {
        title: "Referrer",
        dataIndex: "referrer"
      },
      {
        title: "Referrals",
        dataIndex: "standardUserCount"
      },
      {
        title: "Business Referrals",
        dataIndex: "businessUserCount"
      },
      {
        title: "Confirmed",
        dataIndex: "confirmedUserCount"
      }
    ]}
  />
);
