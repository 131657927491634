import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";

// TODO: What is this? Quick fix for TS not to complain
interface LocationProps {
  from: {
    pathname: string;
  };
}

export default class Page4041 extends Component<
  RouteComponentProps<{}, {}, LocationProps>
> {
  state = {
    redirectToReferrer: false
  };

  handleRedirect = () =>
    this.setState(() => ({
      redirectToReferrer: true
    }));

  render() {
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer === true) {
      this.props.history.push(from.pathname);
    }
    return (
      <div className="app">
        <div className="layout">
          <div className="container">
            <div className="row full-height align-items-center">
              <div className="col-12">
                <div className="text-center p-t-50">
                  <h1 className="font-size-170 text-secondary font-weight-light text-opacity ls-2">
                    404
                  </h1>
                  <h2 className="font-weight-light font-size-30">
                    Whoops! Looks like you got lost
                  </h2>
                  <p className="lead">
                    We couldnt find what you were looking for.
                  </p>
                  <button
                    className="btn btn-primary btn-lg m-t-30"
                    onClick={this.handleRedirect}
                  >
                    Go Back
                  </button>
                </div>
              </div>
              <div className="col-12 align-self-end ">
                <div className="text-center p-b-20 font-size-13">
                  <span>
                    Copyright © 2020{" "}
                    <a
                      className="text-dark"
                      href="https://getsubly.com"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Subly
                    </a>
                    . All rights reserved.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
