import React, { useEffect, useState } from "react";
import { takeUntil } from "rxjs/internal/operators/takeUntil";
import { Subject } from "rxjs/internal/Subject";
import AdminLayout from "../layouts/adminLayout";
import { fetchOrderList } from "../../api/order.service";
import { orderQuery } from "../../state/order/order.query";
import { OrdersTable } from "./orders-table/orders-table";
import { ModalContext, ModalType } from "../../contexts/modal.context";
import { OrderModal } from "./order-modal/order-modal";
import { Order } from "@getsubly/common";

export interface OrdersRouteParams {
  id: string;
}

const Orders: React.FC = () => {
  const [orderList, setOrderList] = useState<Order[]>();
  const [bumpFetch, setBumpFetch] = useState<number>(0);
  const { showModal, hideModal } = React.useContext(ModalContext);

  const handleUpdated = () => {
    setBumpFetch(bumpFetch + 1);
  };

  const onSelect = (orderId: string) => {
    return showModal(
      ModalType.Order,
      <OrderModal
        orderId={orderId}
        onClose={() => hideModal(ModalType.Order)}
        onUpdated={handleUpdated}
      />
    );
  };

  useEffect(() => {
    fetchOrderList();

    const unsubcribe$ = new Subject();

    orderQuery
      .select()
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ list }) => setOrderList(list.orderList));

    return () => unsubcribe$.next();

    //rerender on route param changes only - everything here goes through the router
  }, [bumpFetch]);

  return (
    <AdminLayout parent="Lists" title="Orders">
      <OrdersTable ordersList={orderList} onSelect={onSelect} />
    </AdminLayout>
  );
};

export default Orders;
