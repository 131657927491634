import { Query } from "@datorama/akita";
import {
  DashboardState,
  DashboardStore,
  dashboardStore
} from "./dashboard.store";
import moment from "moment";

export class DashboardQuery extends Query<DashboardState> {
  get userListTake(): number {
    return this.getValue().userListStats.take;
  }

  get topReferrersTake(): number {
    return this.getValue().userListStats.take;
  }

  get filterStartDate(): Date {
    return moment(this.getValue().filters.startDate)
      .utc()
      .startOf("D")
      .toDate();
  }

  get filterEndDate(): Date {
    return moment(this.getValue().filters.endDate).utc().endOf("D").toDate();
  }

  get filtersDates(): [Date, Date] {
    return [this.filterStartDate, this.filterEndDate];
  }

  get activeDays(): number {
    return this.getValue().activeUsersGraphStats.activeDays;
  }

  constructor(protected store: DashboardStore) {
    super(store);
  }
}

export const dashboardQuery = new DashboardQuery(dashboardStore);
