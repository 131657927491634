import React from "react";
import { Collapse } from "antd";
import { AccountDetails } from "../../../api/account.service";
import { accountQuery } from "../../../state/account/account.query";
import { formatDate } from "../../../utils/date-formatter";

interface AccountModalAccountDetailsProps {
  accountDetails: AccountDetails | undefined;
}

export const AccountModalAccountDetails: React.FC<AccountModalAccountDetailsProps> =
  ({ accountDetails }) => {
    const accountList = accountQuery.list.accountList;
    const currentAccount = accountList.find(
      (a) => a.accountId === accountDetails?.accountId
    );
    const ownerName = currentAccount?.owner?.name || "";
    const ownerEmail = currentAccount?.owner?.email || "";
    const endDate = accountDetails?.billing?.cancelAt
      ? formatDate(new Date(accountDetails?.billing?.cancelAt))
      : "";
    const minutesLeft = Math.floor(
      ((accountDetails?.creditFreeSeconds || 0) +
        (accountDetails?.creditExtraSeconds || 0) +
        (accountDetails?.creditPaidSeconds || 0) +
        (accountDetails?.creditPaygSeconds || 0)) /
        60
    );
    const minutesGiven = Math.floor(
      (accountDetails?.billing?.monthlyTopupCredit || 0) / 60
    );
    const { Panel } = Collapse;
    return accountDetails ? (
      <>
        Business name: {accountDetails.name} <br />
        Account ID: {accountDetails.accountId} <br />
        {ownerName && (
          <>
            Main contact: {ownerName} <br />
          </>
        )}
        {ownerEmail && (
          <>
            Main email address: {ownerEmail} <br />
          </>
        )}
        {(Boolean(minutesGiven) || minutesGiven === 0) && (
          <>
            Minutes given: {minutesGiven} <br />
          </>
        )}
        {(Boolean(minutesLeft) || minutesLeft === 0) && (
          <>
            Minutes remaining: {minutesLeft}
            <br />
          </>
        )}
        {endDate && (
          <>
            End date of contract: {endDate} <br />
          </>
        )}
        <br />
        Plan:{" "}
        {accountDetails.billing?.planName ||
          accountDetails.billing?.trialPlanName}
        <br />
        Credit - free seconds: {accountDetails.creditFreeSeconds}
        <br />
        Credit - paid seconds: {accountDetails.creditPaidSeconds}
        <br />
        Credit - payg seconds: {accountDetails.creditPaygSeconds}
        <br />
        Credit - extra seconds: {accountDetails.creditExtraSeconds}
        <br />
        Team capacity: {accountDetails.teamCapacity}
        <br />
        Is pay as you go: {String(Boolean(accountDetails.isPayg))}
        <br />
        Created at: {accountDetails.createdAt}
        <br />
        Updated at: {accountDetails.updatedAt}
        <br />
        <Collapse>
          <Panel header="Billing" key="1">
            <p>{JSON.stringify(accountDetails.billing)}</p>
          </Panel>
          <Panel header="Settings" key="2">
            <p>{JSON.stringify(accountDetails.settings)}</p>
          </Panel>
        </Collapse>
      </>
    ) : (
      <span>"Details missing"</span>
    );
  };
