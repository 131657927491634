import { Button, Table } from "antd";
import React from "react";
import { MediaDetails, MediaSharedUser } from "../../../api/media.service";
import { TrashCanIcon } from "../../icons/icons";

interface MediaModalShareDetailsProps {
  mediaDetails: MediaDetails | undefined;
  openNewMediaShareModal: () => void;
  removeMediaShare: (id: string) => void;
}

export const MediaModalShareDetails: React.FC<MediaModalShareDetailsProps> = ({
  mediaDetails,
  openNewMediaShareModal,
  removeMediaShare
}) => {
  const sharedUsers = mediaDetails?.sharedUsers || [];

  const columns = [
    {
      title: "Id",
      dataIndex: "shareId",
      key: "shareId"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Permission",
      dataIndex: "permission",
      key: "permission"
    },
    {
      title: "Delete?",
      render: (row: MediaSharedUser) => {
        return <TrashCanIcon />;
      },
      onCellClick: (row: MediaSharedUser) => {
        removeMediaShare(row.shareId);
      }
    }
  ];

  return mediaDetails ? (
    <>
      <Table
        dataSource={sharedUsers.map((f) => {
          return { key: f.shareId, ...f };
        })}
        columns={columns}
      />
      <br />
      <div>
        <Button className="btn btn-primary" onClick={openNewMediaShareModal}>
          New Media Share
        </Button>
      </div>
    </>
  ) : (
    <span>"Details missing"</span>
  );
};
