import React from "react";
import { NewTable } from "../../new-table";
import classNames from "classnames";
import { parseObjectToHtmlText } from "../../../utils/text";
import { FileType, OrderFile } from "../../../api/order.service";
import Icon from "@mdi/react";
import { mdiDownload, mdiFileReplace } from "@mdi/js";
import { replaceMediaFile } from "../../../api/media.service";
import { Order } from "@getsubly/common";
import { toast } from "react-toastify";

interface OrderModalOrderDetailsProps {
  order?: Order;
  files: OrderFile[];
}

export const OrderModalFiles: React.FC<OrderModalOrderDetailsProps> = ({
  order,
  files
}) => {
  const sortFileOrder = (files: OrderFile[]) => {
    const order = [
      FileType.Transcript,
      FileType.Subtitle,
      FileType.Transcription,
      FileType.Video,
      FileType.Audio,
      FileType.Thumbnail,
      FileType.Waveform
    ];
    return files.sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type));
  };

  const processReplace = async (orderFile: OrderFile, file?: File | null) => {
    if (!file || !order) return;

    const contents = await file.text();
    console.log(file);
    console.log(contents);
    await replaceMediaFile(order.mediaId, orderFile.id, contents);
  };

  const handleReplace = (item: OrderFile) => {
    console.log(item);
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "*.srt";

    fileInput.addEventListener("change", (event) => {
      processReplace(item, fileInput.files?.item(0));
      toast.success("File has been replaced");
    });

    fileInput.click();
  };

  return (
    <NewTable
      columns={[
        { label: "", id: "actions", width: "min-content" },
        { label: "Id", id: "id", width: "min-content" },
        { label: "Type", id: "type", width: "min-content" },
        { label: "Filename", id: "filename", width: "1fr" },
        { label: "Metadata", id: "metadata", width: "min-content" },
        { label: "Uploaded at", id: "uploaded_at", width: "min-content" }
      ]}
      items={sortFileOrder(files) || []}
      className="tw-flex tw-flex-col tw-overflow-auto tw-whitespace-nowrap tw-max-w-full tw-scroll-auto tw-min-w-0"
    >
      <NewTable.Header>
        {(cols) =>
          cols.map((col, i) => {
            const isFirst = i === 0;
            const isLast = cols.length - 1 === i;

            return (
              <NewTable.HeaderCell
                key={col.id}
                className={classNames(" !tw-pr-2 tw-font-medium", {
                  "!tw-pl-3": isFirst,
                  "!tw-pl-2": !isFirst,
                  "!tw-pr-3": isLast,
                  "!tw-pr-2": !isLast
                })}
              >
                {col.label}
              </NewTable.HeaderCell>
            );
          })
        }
      </NewTable.Header>
      {(sortFileOrder(files) || []).map((item, i) => (
        <Row key={i} item={item} onReplaceClick={handleReplace} />
      ))}
    </NewTable>
  );
};

interface RowInnerProps {
  item: OrderFile;
  onReplaceClick: (item: OrderFile) => void;
}
const Row: React.FC<RowInnerProps> = ({ item, onReplaceClick }) => {
  const [isHovered, setHovered] = React.useState(false);

  const sharedClasses = React.useMemo(() => {
    let classes = "tw-min-h-12 tw-pr-8";
    if (isHovered) classes += " tw-bg-neutral-50";
    return classes;
  }, [isHovered]);

  const onReplace = () => {
    onReplaceClick(item);
  };

  return (
    <>
      <NewTable.Cell
        className={classNames(sharedClasses, "tw-overflow-hidden")}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
      >
        <a href={item.url}>
          <Icon path={mdiDownload} size="1.4em" className="ml-2" />
        </a>

        <button onClick={onReplace}>
          <Icon path={mdiFileReplace} size="1.4em" className="ml-2" />
        </button>
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses, "tw-overflow-hidden")}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
      >
        {item.id}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
      >
        {item.type}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses, "tw-whitespace-normal")}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
      >
        {item.filename}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: parseObjectToHtmlText(item.metadata)
          }}
        />
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
      >
        {item.uploadedDate}
      </NewTable.Cell>
    </>
  );
};
