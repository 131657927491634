import { Query } from "@datorama/akita";
import {
  OrderDetailsState,
  OrderListParamsState,
  OrderListState,
  OrderState,
  OrderStore,
  orderStore
} from "./order.store";

export class OrderQuery extends Query<OrderState> {
  get list(): OrderListState {
    return this.getValue().list;
  }

  get details(): OrderDetailsState {
    return this.getValue().details;
  }

  get orderListParams(): OrderListParamsState {
    return this.getValue().params;
  }

  constructor(protected store: OrderStore) {
    super(store);
  }
}

export const orderQuery = new OrderQuery(orderStore);
