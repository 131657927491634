import { Store, StoreConfig } from "@datorama/akita";
import { IProduct } from "../../../interfaces/product";

export interface ProductListState {
  products: IProduct[];
  loading: boolean;
}

@StoreConfig({ name: "product-list" })
export class ProductListStore extends Store<ProductListState> {
  constructor() {
    super({});
  }

  updateState(data: Partial<ProductListState>): void {
    this.update((s) => ({
      ...s,
      data
    }));
  }
}

export const productListStore = new ProductListStore();
