import axios from "axios";
import config from "../config";
import { getAccessToken } from "./auth.service";
import { usersStore } from "../state/users/users.store";
import { notification } from "antd";
import { EN } from "../assets/i18n/en";
import { usersQuery } from "../state/users/users.query";
import cognitoAttributeNames from "../utils/cognitoAttributeNames";
import { AccountType } from "../interfaces/account";

const baseURL = `${config.apiUrl}/api/v1/admin`;

// Users
interface UserListResponse {
  users: UserListItem[];
  paginationToken?: string;
}
export interface UserListItem {
  // username: string;
  id: string;
  name: string;
  givenName: string;
  familyName: string;
  email: string;
  verification_status: boolean;
  phoneNumber: string;
  phoneNumberVerified: boolean;
  sub: string;
  createdAt: Date;
  updatedAt: Date;
  status: string;
  group: string;
}

interface ICreateUserParams {
  givenName: string;
  familyName: string;
  email: string;
}

export const createBusinessUser = async ({
  givenName,
  familyName,
  email
}: ICreateUserParams): Promise<void> => {
  await axios.post(
    `${baseURL}/user`,
    {
      givenName,
      familyName,
      email,
      accountType: AccountType.Business
    },
    {
      headers: {
        "x-access-token": await getAccessToken()
      }
    }
  );
};

export const fetchUserList = async () => {
  try {
    usersStore.updateList({
      loading: true,
      userList: []
    });

    const params = usersQuery.userListParams;

    const filter =
      params.filter && params.search
        ? `${params.filter}^="${params.search}"`
        : undefined;

    const { data } = await axios.get<UserListResponse>(
      `${baseURL}/users?${filter ? `filter=${filter}&` : ""}limit=${
        params.limit ?? 60
      }${
        params.paginationToken
          ? `&paginationToken=${btoa(params.paginationToken)}`
          : ""
      }`,
      {
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );

    usersStore.updateList({
      loading: false,
      userList: data.users
    });

    usersStore.updatePagination(data.paginationToken);
  } catch (error) {
    handleError(error);
  } finally {
    usersStore.updateList({
      loading: false
    });
  }
};

export const fetchMoreUserList = async () => {
  try {
    const params = usersQuery.userListParams;

    const filter =
      params.filter && params.search
        ? `${params.filter}=${params.search}`
        : undefined;

    const { data } = await axios.get<UserListResponse>(
      `${baseURL}/users?${filter ? `filter=${filter}&` : ""}limit=${
        params.limit ?? 60
      }${
        params.paginationToken
          ? `&paginationToken=${btoa(params.paginationToken)}`
          : ""
      }`,
      {
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );

    usersStore.updateList({
      userList: [...usersQuery.list.userList, ...data.users]
    });

    usersStore.updatePagination(data.paginationToken);
  } catch (error) {
    handleError(error);
  } finally {
    usersStore.updateList({
      loading: false
    });
  }
};

interface UserGroupsResponse {
  message: string;
  groups: GroupItem[];
}
export interface GroupItem {
  name: string;
  description: string;
}

export const fetchUserGroups = async () => {
  try {
    usersStore.updateUserGroups({
      loading: true,
      groups: []
    });
    const { data } = await axios.get<UserGroupsResponse>(
      `${baseURL}/users-groups`,
      {
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );
    usersStore.updateUserGroups({
      loading: false,
      groups: data.groups
    });
  } catch (error) {
    handleError(error);
  } finally {
    usersStore.updateUserGroups({
      loading: false
    });
  }
};

interface UserDetailsResponse {
  message: string;
  user: UserDetails;
}

export interface UserDetails {
  username: string;
  cognito: UserDetailsCognito;
  database: UserDetailsDatabase;
  hubspot: UserDetailsHubspot;
}

export interface UserDetailsCognito {
  username: string;
  status?: string;
  enabled?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  attributes: UserAttributes[];
  groups: string[];
}

export interface UserAttributes {
  name: string;
  value?: string;
}

export interface UserDetailsDatabase {
  username: string;
  groups: string[];
  status: string;
  givenName: string;
  familyName: string;
  email: string;
  emailVerified: boolean;
  phoneNumber: string;
  phoneNumberVerified: boolean;
  sub: string;
  referrer: string;
  partner: string;
  createdAt: Date;
  updatedAt: Date;
  setttings: any;
  excludeReporting: boolean;
  accounts: UserDetailsDatabaseAccount[];
  mediaList: UserDetailsDatabaseMedia[];
}

export interface UserDetailsDatabaseAccount {
  id: string;
  account: string;
  role: string;
}

export interface UserDetailsDatabaseMedia {
  id: string;
  name: string;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  files: UserDetailsDatabaseFile[];
  jobs: UserDetailsDatabaseJob[];
}

export interface UserDetailsDatabaseFile {
  id: string;
  type: string;
  filename: string;
  extension: string;
  s3Uri: string;
  sizeBytes: number;
  durationSeconds?: number;
  metadata: any;
  uploadedAt: Date;
}

export interface UserDetailsDatabaseJob {
  id: string;
  type: string;
  status: string;
  startDate: Date;
  endDate?: Date;
  message: string;
}

export interface UserDetailsHubspot {
  vid: string;
  url: string;
  properties: UserAttributes[];
}

export const fetchUserDetails = async (username: string) => {
  try {
    usersStore.updateDetails({
      loading: true
    });
    const { data } = await axios.get<UserDetailsResponse>(
      `${baseURL}/users/${username}`,
      {
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );
    usersStore.updateDetails({
      loading: false,
      userDetails: data.user
    });
  } catch (error) {
    handleError(error);
  } finally {
    usersStore.updateDetails({
      loading: false
    });
  }
};

export const addGroupToUser = async (username: string, group: string) => {
  try {
    usersStore.updateDetails({
      loading: true
    });
    await axios.put(`${baseURL}/users/${username}/add-groups`, {
      headers: {
        "x-access-token": await getAccessToken()
      },
      body: {
        group
      }
    });
    usersStore.addGroupToDetails(group);
  } catch (error) {
    handleError(error);
  } finally {
    usersStore.updateDetails({
      loading: false
    });
  }
};

export const removeGroupToUser = async (username: string, group: string) => {
  try {
    usersStore.updateDetails({
      loading: true
    });
    await axios.put(`${baseURL}/users/${username}/remove-groups`, {
      headers: {
        "x-access-token": await getAccessToken()
      },
      body: {
        group
      }
    });
    usersStore.removeGroupFromDetails(group);
  } catch (error) {
    handleError(error);
  } finally {
    usersStore.updateDetails({
      loading: false
    });
  }
};

export const isEducation = (details: UserDetailsCognito) => {
  if (!details) {
    return false;
  }

  const settingsAttribute = details.attributes.find(
    (x) => x.name === cognitoAttributeNames.customSettings
  );

  const settings = JSON.parse(settingsAttribute?.value || "");

  const result = settings?.onboarding?.industry === "Education";

  return result;
};

export const updateCognitoAttribute = async (
  username: string,
  attributeName: string,
  attributeValue: string
) => {
  const data = {
    attribute: attributeName,
    value: attributeValue
  };

  const config = {
    headers: {
      "x-access-token": await getAccessToken()
    }
  };
  const result = await axios.put(
    `${baseURL}/users/${username}/update`,
    data,
    config
  );

  if (result.status !== 200) {
    throw new Error(
      `Failed to save Cognito setting: status ${result.status}; message: ${result.statusText}`
    );
  }
};

export const ConvertGoogleUser = async (username?: string, email?: string) => {
  try {
    const { data } = await axios.post<{
      message: string;
    }>(
      `${baseURL}/users/${username}/convert`,
      { email: email },
      {
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );
    console.log(data);
    notification.info({
      message: `User converted to email ${email}`,
      duration: 10
    });
  } catch (error) {
    handleError(error);
  }
};

// Private functions

const handleError = (e: any) => {
  console.error(e);

  notification.error({
    message: "Something went wrong",
    description: e?.message || EN.error.defaultMessage,
    duration: 10
  });
};
