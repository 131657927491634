import React, { useState } from "react";
import { Table } from "antd";
import { AccountListParamsState } from "../../../state/account/account.store";
import { formatDate } from "../../../utils/date-formatter";
import { DealStatus, IDeal } from "../../../interfaces/deal";
import { TableFilter } from "../../table/table-filter";

interface DealTableProps {
  loading: boolean;
  dealList?: IDeal[];
  params?: AccountListParamsState;
  onSelect: (params: { accountId: string; dealId: string }) => void;
  onSearch: (filter: string, search: string) => void;
  onResetSearch: () => void;
}
export const DealTable: React.FC<DealTableProps> = ({
  loading,
  dealList,
  params,
  onSelect,
  onSearch,
  onResetSearch
}) => {
  const [hideDeleted, setHideDeleted] = useState(true);
  const [hideVoid, setHideVoid] = useState(true);

  return (
    <>
      <TableFilter
        initialValue={{ filter: params?.filter, search: params?.search }}
        options={[
          { filter: "deal", display: "Deal Id" },
          { filter: "account", display: "Account Id" }
        ]}
        onSearch={({ filter, search }) => onSearch(filter, search)}
        onResetSearch={() => onResetSearch()}
      />
      <label style={{ width: "100%" }}>
        Hide deleted:
        <input
          type="checkbox"
          defaultChecked={hideDeleted}
          onChange={(e) => setHideDeleted(!hideDeleted)}
        />
      </label>
      <label style={{ width: "100%" }}>
        Hide void:
        <input
          type="checkbox"
          defaultChecked={hideVoid}
          onChange={(e) => setHideVoid(!hideVoid)}
        />
      </label>

      <hr className="rounded"></hr>
      <Table
        size="small"
        bordered
        loading={loading}
        dataSource={(dealList || []).filter((d) => {
          if (hideDeleted && d.status === DealStatus.Deleted) {
            return false;
          }
          if (hideVoid && d.status === DealStatus.Void) {
            return false;
          }
          return true;
        })}
        pagination={false}
        rowKey="id"
        onRowClick={(record) =>
          onSelect({ accountId: record.accountId, dealId: record.id })
        }
        columns={[
          {
            title: "Deal Id",
            dataIndex: "id",
            key: "id"
          },
          {
            title: "Account Id",
            dataIndex: "accountId",
            key: "accountId"
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status"
          },
          {
            title: "Paid?",
            dataIndex: "isPaid",
            key: "isPaid",
            render: (value) => {
              return <>{value || false ? "YES" : "NO"}</>;
            }
          },
          {
            title: "Trial?",
            dataIndex: "isTrial",
            key: "isTrial",
            render: (value: boolean) => {
              return <>{value || false ? "YES" : "NO"}</>;
            }
          },
          {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (value) => {
              return <>{formatDate(value)}</>;
            }
          },
          {
            title: "Started At",
            dataIndex: "startedAt",
            key: "startedAt",
            render: (value) => {
              return <>{formatDate(value)}</>;
            }
          },
          {
            title: "Ending At",
            dataIndex: "endingAt",
            key: "endingAt",
            render: (value) => {
              return <>{formatDate(value)}</>;
            }
          }
        ]}
      />
    </>
  );
};
