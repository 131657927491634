import React from "react";
import cx from "classnames";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import "./stats-card.scss";

interface StatsCardProps {
  title?: string;
  subtitle?: string | JSX.Element;
  loadData?: Function;
}
export const StatsCard: React.FC<StatsCardProps> = ({
  title,
  subtitle,
  loadData,
  children
}) => {
  return (
    <div className="card">
      <StatsCardHeader title={title} subtitle={subtitle} loadData={loadData} />
      <div className={cx("card-body", { "pt-0": title && !subtitle })}>
        {children}
      </div>
    </div>
  );
};

const StatsCardHeader: React.FC<StatsCardProps> = ({
  title,
  subtitle,
  loadData
}) => {
  const hasHeader = !!title || !!subtitle;

  if (!hasHeader) {
    return null;
  }

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    loadData && loadData(e.target.checked);
  };

  return (
    <div className="card-header pb-0">
      <h5 className="card-title">{title}</h5>
      {!!loadData && <Checkbox onChange={onCheckboxChange}></Checkbox>}
      {subtitle && (
        <h6 className="card-subtitle mt-1 text-muted">{subtitle}</h6>
      )}
    </div>
  );
};
