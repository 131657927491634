import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { takeUntil } from "rxjs/internal/operators/takeUntil";
import { Subject } from "rxjs/internal/Subject";
import AdminLayout from "../layouts/adminLayout";
import { getSingleQueryStringParameterValue } from "../../utils/querystring";
import {
  AccountListParamsState,
  accountStore
} from "../../state/account/account.store";
import { AccountListItem, fetchAccountList } from "../../api/account.service";
import { accountQuery } from "../../state/account/account.query";
import { AccountTable } from "./accounts-table/accounts-table";
import { AccountModal } from "./account-modal/account-modal";
import { ModalContext, ModalType } from "../../contexts/modal.context";

export interface AccountsRouteParams {
  id: string;
}

const Accounts: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [accountsList, setAccountsList] = useState<AccountListItem[]>();
  const [params, setParams] = useState<AccountListParamsState>();
  const { showModal, hideModal } = React.useContext(ModalContext);

  let { id } = useParams<AccountsRouteParams>();

  const searchField = getSingleQueryStringParameterValue(
    window.location.search,
    "filter"
  );

  const history = useHistory();

  const onSelect = (accountId: string) =>
    showModal(
      ModalType.Account,
      <AccountModal
        accountId={accountId}
        onClose={() => hideModal(ModalType.Account)}
      />
    );

  const onResetSearch = () => {
    history.push("/accounts");
  };

  const onSearch = (filter: string, search: string) => {
    let url = `/accounts/${search}`;

    if (filter !== "accounts") {
      url = url + `?filter=${filter}`;
    }

    history.push(url);
  };

  useEffect(() => {
    let params = {
      filter: "",
      search: "",
      accountType: undefined
    };

    if (id != null) {
      params.search = id;

      Boolean(searchField)
        ? (params.filter = searchField)
        : (params.filter = "account");
    }

    setParams(params);

    accountStore.updateParams(params);

    fetchAccountList();

    const unsubcribe$ = new Subject();

    accountQuery
      .select()
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ list }) => {
        setLoading(list.loading);
        setAccountsList(list.accountList);
        if (params.filter === "account" && list.accountList.length === 1) {
          // onSelect(params.search);
        }
      });

    return () => unsubcribe$.next();

    //rerender on route param changes only - everything here goes through the router
  }, [searchField, id]);

  return (
    <AdminLayout parent="Lists" title="Account List">
      <AccountTable
        loading={loading}
        params={params}
        accountList={accountsList}
        onSelect={onSelect}
        onSearch={onSearch}
        onResetSearch={onResetSearch}
      />
    </AdminLayout>
  );
};

export default Accounts;
