import axios from "axios";
import config from "../config";
import { getAccessToken } from "./auth.service";

const baseURL = `${config.apiUrl}/api/v1/admin/auth`;

// Auth
export interface SignInParams {
  email: string;
  password: string;
  remember?: boolean;
}
export const signIn = async (params: SignInParams) =>
  await axios.post(`${baseURL}/signin`, params);

export interface SignUpParams {
  email: string;
  password: string;
  name: string;
  firstName?: string;
  lastName?: string;
  referralCode?: string;
  partner?: string;
}
export const signUp = async (params: SignUpParams) =>
  await axios.post(`${baseURL}/signup`, params);

interface ProviderSignInParams {
  code: string;
  redirect_uri: string;
}
export const providerSignIn = async ({
  code,
  redirect_uri
}: ProviderSignInParams) =>
  await axios.post(`${baseURL}/token`, {
    code,
    redirect_uri
  });

interface VerifyUserParams {
  code: string;
  id?: string;
}
export const verifyUser = async ({ code, id }: VerifyUserParams) =>
  await axios.post(`${baseURL}/verify/${id}`, {
    verification_code: code
  });

export interface VerifyPartnerUserParams {
  id: string;
  code: string;
  password: string;
}
export const verifyPartnerUser = async ({
  id,
  code,
  password
}: VerifyPartnerUserParams) =>
  await axios.post(`${baseURL}/verify/partner/${id}`, {
    verification_code: code,
    password
  });

interface EditUserProfileParams {
  id: string;
  [key: string]: any;
}
export const editUserProfile = async ({
  id,
  ...rest
}: EditUserProfileParams) => {
  return await axios.put(`${baseURL}/update`, rest, {
    headers: { "x-access-token": await getAccessToken() }
  });
};

export const editUserProfilePicture = async (file: any) => {
  const bodyFormData = new FormData();
  bodyFormData.append("file", file);
  return await axios.put(`${baseURL}/update-picture`, bodyFormData, {
    headers: {
      "Content-Type": "form-data",
      "x-access-token": await getAccessToken()
    }
  });
};

export const removeUserProfilePicture = async () => {
  return await axios.put(
    `${baseURL}/remove-picture`,
    {},
    {
      headers: {
        "x-access-token": await getAccessToken()
      }
    }
  );
};

export interface ResetPasswordParams {
  id: string;
  code: string;
  password: string;
  passwordConfirm: string;
}
export const resetPassword = async (params: ResetPasswordParams) =>
  await axios.post(`${baseURL}/reset`, params);

export interface ChangePasswordParams {
  email: string;
  password: string;
}

export interface ForgotPasswordParams {
  email: string;
}
export const forgotPassword = async (params: ForgotPasswordParams) =>
  axios.post(`${baseURL}/forget-password`, params);

export const changePassword = async ({
  email,
  password
}: ChangePasswordParams) =>
  await axios.post(`${baseURL}/change-password`, {
    email,
    password
  });

export const signOut = async () => await axios.get(`${baseURL}/signout`);

export const verificationResend = async (id: string) =>
  await axios.get(`${baseURL}/verify/resend/${id}`);
