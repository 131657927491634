import queryString from "query-string";

export const getSingleQueryStringParameterValue = (
  querystring: string,
  parameterName: string
): string => {
  const queryStringParams = queryString.parse(querystring);

  if (!queryStringParams) {
    return "";
  }

  const namedQueryStringParams = queryStringParams[parameterName];

  if (!namedQueryStringParams || Array.isArray(namedQueryStringParams)) {
    return "";
  }

  return namedQueryStringParams;
};
