import React, { useEffect } from "react";
import { Button, Spin } from "antd";
import { NewDealForm } from "./new-deal-form";
import { Subject } from "rxjs";
import { newDealModalQuery } from "../../../state/deal/new-deal-modal/new-deal-modal.query";
import { takeUntil } from "rxjs/operators";
import { fetchDealInit } from "../../../api/deal.service";
import { Modal } from "../../modal/modal";
import styles from "./new-deal-modal.module.scss";

interface NewDealModalProps {
  accountId?: string;
  onSave: (dealId: string) => void;
  onClose: () => void;
}
export const NewDealModal: React.FC<NewDealModalProps> = ({
  accountId,
  onSave,
  onClose
}) => {
  const title = `New Deal`;
  const [loading, setLoading] = React.useState<boolean>();
  const [init, setInit] = React.useState<any>();

  useEffect(() => {
    const unsubcribe$ = new Subject();
    newDealModalQuery
      .select()
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ loading, init }) => {
        setLoading(loading);
        setInit(init);
      });
    return () => unsubcribe$.next();
  }, []);

  useEffect(() => {
    if (!accountId) {
      return;
    }

    fetchDealInit(accountId);
  }, [accountId]);

  if (!accountId) {
    return <></>;
  }

  if (!loading && !init) {
    return (
      <Modal onDismiss={onClose} className={styles["modal"]} hasCloseIcon>
        <h5 className="mb-3">{title}</h5>
        <Spin spinning={true} />

        <div className="d-flex align-items-center justify-content-end mt-5">
          <Button
            type="primary"
            className="btn btn-primary"
            onClick={() => onClose()}
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  }

  return (
    <Modal onDismiss={onClose} className={styles["modal"]} hasCloseIcon>
      <h5 className="mb-3">{title}</h5>
      <NewDealForm
        accountId={accountId}
        init={init}
        onSave={(dealId: string) => onSave(dealId)}
      />
      <div className="d-flex align-items-center justify-content-end mt-5">
        <Button
          type="primary"
          className="btn btn-primary"
          onClick={() => onClose()}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};
