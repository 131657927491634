import { Store, StoreConfig } from "@datorama/akita";
import {
  AuthGraphData,
  AuthUsageStats,
  MediaUsageStats,
  UsersStats,
  UserUsageStats,
  ActiveUsersGraphData,
  ActiveUsersStats,
  UserMediaStats,
  ReferrerUser
} from "../../interfaces/stats";

interface IStatsData<T> {
  loading: boolean;
  error?: boolean;
  stats?: T;
}

export interface DashboardState {
  filters: {
    startDate: Date;
    endDate: Date;
  };

  preparingDownload: {
    loading: boolean;
  };

  usersStats: IStatsData<UsersStats>;

  authStats: IStatsData<AuthUsageStats>;

  mediaStats: IStatsData<MediaUsageStats>;

  freeUserMediaStats: IStatsData<UserMediaStats>;
  paidUserMediaStats: IStatsData<UserMediaStats>;

  authGraphStats: IStatsData<AuthGraphData>;

  activeUsersStats: IStatsData<ActiveUsersStats>;

  activeUsersGraphStats: {
    loading: boolean;
    activeDays: number;
    stats?: ActiveUsersGraphData;
  };

  userListStats: {
    loading: boolean;
    take: number;
    stats: UserUsageStats[];
  };

  topReferrers: {
    loading: boolean;
    take: number;
    offset?: number;
    stats: ReferrerUser[];
  };
}

function createDashboardState(): DashboardState {
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 7);

  return {
    filters: {
      startDate,
      endDate: new Date()
    },
    preparingDownload: {
      loading: false
    },
    usersStats: {
      loading: false
    },
    authStats: {
      loading: false
    },
    mediaStats: {
      loading: false
    },
    freeUserMediaStats: {
      loading: false
    },
    paidUserMediaStats: {
      loading: false
    },
    authGraphStats: {
      loading: false
    },
    activeUsersStats: {
      loading: false
    },
    activeUsersGraphStats: {
      activeDays: 30,
      loading: false
    },
    userListStats: {
      loading: false,
      take: 50,
      stats: []
    },
    topReferrers: {
      loading: false,
      take: 10,
      stats: []
    }
  };
}

@StoreConfig({ name: "dashboard" })
export class DashboardStore extends Store<DashboardState> {
  constructor() {
    super(createDashboardState());
  }

  setStatsLoading<K extends keyof DashboardState>(key: K, loading: boolean) {
    this.update((s) => ({
      [key]: {
        ...s[key],
        loading
      }
    }));
  }

  setStatsError<K extends keyof DashboardState>(key: K) {
    this.update((s) => ({
      [key]: {
        ...s[key],
        error: true
      }
    }));
  }

  updateStats<K extends keyof DashboardState>(key: K, stats: any) {
    this.update((s) => ({
      [key]: {
        ...s[key],
        error: false,
        stats
      }
    }));
  }

  setActiveDays(days: number) {
    this.update((s) => ({
      activeUsersGraphStats: {
        ...s.activeUsersGraphStats,
        activeDays: days
      }
    }));
  }
}

export const dashboardStore = new DashboardStore();
