import { Duration } from "luxon";
import { pluralize } from "./string";

export const parseSecondsToText = (duration?: number): string => {
  if (!duration) {
    return "";
  }

  if (duration < 60) {
    const label = pluralize(duration, "second");
    return `${duration.toFixed(0)} ${label}`;
  }

  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;

  const minutesLabel = pluralize(minutes, "minute");

  if (!seconds) {
    return `${minutes} ${minutesLabel}`;
  }

  const secondsLabel = pluralize(seconds, "second");

  return `${minutes} ${minutesLabel} and ${seconds.toFixed(0)} ${secondsLabel}`;
};

export const durationToString = ({
  hours,
  minutes,
  seconds
}: Duration): string => {
  const hr = `${hours ? `${hours} ${pluralize(hours, "hr")} ` : ``}`;
  const min = `${minutes ? `${minutes} ${pluralize(minutes, "min")} ` : ``}`;

  if (!hr) {
    const sec = `${
      seconds ? `${Math.floor(seconds)} ${pluralize(seconds, "sec")} ` : ``
    }`;

    return `${min}${sec}`;
  }

  return `${hr}${min}`;
};
