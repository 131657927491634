import { notification } from "antd";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { EN } from "../../../assets/i18n/en";
import cx from "classnames";
import Select from "react-select";
import { Modal } from "../../modal/modal";
import { emailPattern } from "../../../utils/regex-patterns";
import {
  addMediaShare,
  MediaSharePermission
} from "../../../api/media.service";
import styles from "./new-media-share-modal.module.scss";

interface NewMediaShareModalProps {
  mediaId: string;
  onClose: () => void;
}

export interface MediaShareForm {
  email: string;
  permission: { value: MediaSharePermission; label: string };
}

export const NewMediaShareModal: React.FC<NewMediaShareModalProps> = ({
  onClose,
  mediaId
}) => {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors, control, getValues } =
    useForm<MediaShareForm>();

  const onSubmit = async ({
    email,
    permission: permissionField
  }: MediaShareForm) => {
    setLoading(true);

    try {
      await addMediaShare(mediaId, {
        email,
        permission: permissionField.value
      });
      notification.success({
        message: `Succesfully added media share`,
        duration: 10
      });

      setLoading(false);
      onClose();
    } catch (e) {
      const description = (e as any)?.message || EN.error.defaultMessage;

      notification.error({
        message: "Something went wrong",
        description,
        duration: 10
      });

      setLoading(false);
    }
  };

  const permission = Object.values(MediaSharePermission);

  const state = getValues() as unknown as MediaShareForm;

  return (
    <Modal onDismiss={onClose} className={styles["modal"]} hasCloseIcon>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-container container">
            <h2>Add member to account</h2>
            <div className="row">
              <div className="col-lg">
                <label style={{ width: "100%" }}>
                  Email
                  <input
                    className={cx("form-control", {
                      "is-invalid": errors.email
                    })}
                    type="email"
                    id="email"
                    name="email"
                    ref={register({
                      required: true,
                      pattern: emailPattern
                    })}
                    defaultValue={state?.email}
                  />
                </label>
                <label htmlFor="permission">Permission</label>
                <fieldset key={"permission"} name={"permission"}>
                  <Controller
                    as={
                      <Select
                        className={cx("country-select", {
                          "is-invalid": errors.permission
                        })}
                        maxMenuHeight={150}
                        placeholder="Permission"
                        options={permission.map((p) => ({
                          value: p,
                          label: p
                        }))}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary: "var(--purple)"
                          }
                        })}
                      />
                    }
                    control={control}
                    rules={{ required: true }}
                    name={`permission`}
                    defaultValue={state?.permission}
                  />
                </fieldset>
              </div>
            </div>
            <div className="row m-t-20">
              <div className="col-lg new-business-account-form-actions">
                <button
                  type="submit"
                  className="btn btn-primary btn-block mt-3"
                  style={{ width: 120 }}
                >
                  {loading ? "Saving" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
