import queryString from "query-string";
import { notification } from "antd";
import axios from "axios";
import { omit } from "lodash";
import { EN } from "../assets/i18n/en";
import config from "../config";
import { FinaliseDealParams, IDeal } from "../interfaces/deal";
import {
  DealListParamsState,
  dealListStore
} from "../state/deal/deal-list/deal-list.store";
import { dealModalStore } from "../state/deal/deal-modal/deal-modal.store";
import { newDealModalStore } from "../state/deal/new-deal-modal/new-deal-modal.store";
import { getAccessToken } from "./auth.service";

const baseURL = `${config.apiUrl}/api/v1/admin`;

export const fetchDealList = async (accountId?: string) => {
  try {
    const { params } = dealListStore.getValue();

    dealListStore.update({
      loading: true,
      deals: []
    });

    const query = buildQueryString(params);

    const { data } = await axios.get<{
      message: string;
      deals: IDeal[];
    }>(
      accountId
        ? `/accounts/${accountId}/deals`
        : `/deals${query ? `?${query}` : ""}`,
      {
        baseURL,
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );

    dealListStore.update({
      deals: data.deals
    });
  } catch (error) {
    handleError(error);
  } finally {
    dealListStore.update({
      loading: false
    });
  }
};

export const fetchDealInit = async (accountId: string) => {
  try {
    const { loading } = newDealModalStore.getValue();

    if (loading) return;

    newDealModalStore.update({
      loading: true,
      init: undefined
    });

    const { data } = await axios.get<{
      account: string;
      coupons: any[];
    }>(`${baseURL}/accounts/${accountId}/deals/init`, {
      headers: {
        "x-access-token": await getAccessToken()
      }
    });

    newDealModalStore.update({
      init: data
    });
  } catch (error) {
    handleError(error);
  } finally {
    newDealModalStore.update({
      loading: false
    });
  }
};

export const fetchDealDetails = async ({
  accountId,
  dealId
}: {
  accountId: string;
  dealId: string;
}) => {
  try {
    dealModalStore.update({
      loading: true,
      deal: undefined
    });

    const { data } = await axios.get<{
      message: string;
      deal: IDeal;
    }>(`${baseURL}/accounts/${accountId}/deals/${dealId}`, {
      headers: {
        "x-access-token": await getAccessToken()
      }
    });

    dealModalStore.update({
      deal: data.deal
    });
  } catch (error) {
    handleError(error);
  } finally {
    dealModalStore.update({
      loading: false
    });
  }
};

export const createDeal = async (
  accountId: string,
  body: any
): Promise<IDeal | undefined> => {
  try {
    if (!accountId) {
      return;
    }

    newDealModalStore.update({
      creating: true
    });

    const { data } = await axios.post<{
      message: string;
      deal: IDeal;
    }>(`${baseURL}/accounts/${accountId}/deals`, body, {
      headers: {
        "x-access-token": await getAccessToken()
      }
    });

    newDealModalStore.update({
      params: undefined
    });

    console.log("data:", data);

    return data.deal;
  } catch (error) {
    handleError(error);
  } finally {
    newDealModalStore.update({
      creating: false
    });
  }
};

export const updateDeal = async (
  { accountId, dealId }: { accountId: string; dealId: string },
  params: any
) => {
  try {
    await axios.put<{
      message: string;
      deal: IDeal;
    }>(`${baseURL}/accounts/${accountId}/deals/${dealId}`, params, {
      headers: {
        "x-access-token": await getAccessToken()
      }
    });
  } catch (error) {
    handleError(error);
  }
};

export const finaliseDeal = async (params: FinaliseDealParams) => {
  try {
    await axios.put<{
      message: string;
      deal: IDeal;
    }>(
      `${baseURL}/accounts/${params.accountId}/deals/${params.dealId}/finalise`,
      omit(params, ["accountId", "dealId"]),
      {
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );
  } catch (error) {
    handleError(error);
  }
};

export const markAsPaidDeal = async ({
  accountId,
  dealId
}: {
  accountId: string;
  dealId: string;
}) => {
  try {
    await axios.put<{
      message: string;
      deal: IDeal;
    }>(
      `${baseURL}/accounts/${accountId}/deals/${dealId}/paid`,
      {},
      {
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );
  } catch (error) {
    handleError(error);
  }
};

export const voidDeal = async ({
  accountId,
  dealId
}: {
  accountId: string;
  dealId: string;
}) => {
  try {
    await axios.put<{
      message: string;
      deal: IDeal;
    }>(
      `${baseURL}/accounts/${accountId}/deals/${dealId}/void`,
      {},
      {
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );
  } catch (error) {
    handleError(error);
  }
};

export const deleteDeal = async ({
  accountId,
  dealId
}: {
  accountId: string;
  dealId: string;
}) => {
  try {
    await axios.delete<{
      message: string;
      deal: IDeal;
    }>(`${baseURL}/accounts/${accountId}/deals/${dealId}`, {
      headers: {
        "x-access-token": await getAccessToken()
      }
    });
  } catch (error) {
    handleError(error);
  }
};

const handleError = (e: any) => {
  console.error(e);

  notification.error({
    message: "Something went wrong",
    description: e?.message || EN.error.defaultMessage,
    duration: 10
  });
};

const buildQueryString = (params: DealListParamsState = {}) => {
  let queryStringBuilder = {};

  if (params.filter && params.search) {
    queryStringBuilder = {
      ...queryStringBuilder,
      filter: params.filter,
      search: params.search
    };
  }
  const result = queryString.stringify(queryStringBuilder);

  return result;
};
