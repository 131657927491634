import jwt from "jsonwebtoken";
import { getAccessToken } from "./auth.service";

export enum UserScope {
  Admin = "Admin",
  Partner = "partner:linkedin",
  User = "User",
  SalesRep = "SalesRep"
}
function getUserScope(token: string): UserScope {
  const decode = jwt.decode(token);

  if (!decode || typeof decode === "string" || !decode["cognito:groups"]) {
    return UserScope.User;
  }

  const groups = decode["cognito:groups"];
  if (!groups || !groups.length) {
    return UserScope.User;
  }

  if (groups.includes(UserScope.Admin)) {
    return UserScope.Admin;
  }

  if (groups.includes(UserScope.SalesRep)) {
    return UserScope.SalesRep;
  }

  if (groups.includes(UserScope.Partner)) {
    return UserScope.Partner;
  }

  return UserScope.User;
}

const SALES_REP_LEVELS = [UserScope.SalesRep];
const PARTNER_LEVELS = [UserScope.Partner, UserScope.User];
const USER_LEVELS = [UserScope.User];

export async function userHasPermission(level: UserScope): Promise<boolean> {
  const token = await getAccessToken();
  const userScope = getUserScope(token);

  if (userScope === UserScope.Admin) {
    return true;
  }

  if (userScope === UserScope.SalesRep) {
    return SALES_REP_LEVELS.includes(level);
  }

  if (userScope === UserScope.Partner) {
    return PARTNER_LEVELS.includes(level);
  }

  return USER_LEVELS.includes(level);
}
