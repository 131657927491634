import React from "react";
import "./aside.scss";

interface AsideProps {
  background: string;
  businessName: string;
  comment: string;
  personName: string;
}
export const Aside: React.FC<AsideProps> = ({
  background,
  businessName,
  comment,
  personName
}) => (
  <div
    id="aside"
    style={{ backgroundImage: `url('${background}')` }}
    className="d-none d-sm-block"
  >
    <div className="shadow"></div>
    <div className="comment">
      <h3>{comment}</h3>
      <p>{personName}</p>
      <strong>{businessName}</strong>
    </div>
  </div>
);
