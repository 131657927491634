import { Tooltip } from "antd";
import cx from "classnames";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { AuthConsumer } from "../../../contexts/auth.context";
import * as ROUTES from "../../../routes";
import { UserNavigationMenu } from "./";
import styles from "./style.module.css";
import Logo from "../../../assets/images/logo-dark.svg";

class Sidebar extends Component {
  render() {
    const { account, toggleDrawer, isPublicHeader } = this.props;

    return (
      <AuthConsumer>
        {({ user, logout }) => (
          <div className={cx("header navbar", styles.headerLeft)}>
            <div className={cx("header-container", styles.headerContainer)}>
              <div className={cx("nav-logo", styles.navLogo)}>
                <Link to={ROUTES.LANDING} className={styles.navLogoA}>
                  <img
                    src={Logo}
                    style={{ width: "64px", padding: "20px 0" }}
                    alt="Subly"
                    className="tw-inline"
                  />
                </Link>
              </div>
              <ul
                className="nav-right"
                style={{
                  float: "none",
                  display: "block",
                  textAlign: "center",
                  flex: "1"
                }}
              >
                <UserNavigationMenu
                  user={user}
                  account={account}
                  toggleDrawer={toggleDrawer}
                  isPublicHeader={isPublicHeader}
                />
                <li className={styles.sidebarIcon}></li>
              </ul>
              <ul
                className="nav-right"
                style={{
                  float: "none",
                  display: "block",
                  textAlign: "center"
                }}
              >
                <li style={{ width: "100%" }} className={styles.sidebarIcon}>
                  <Tooltip placement="right" title="Sign out">
                    <span onClick={logout}>
                      <i
                        className="mdi mdi-power"
                        style={{ fontSize: "28px" }}
                      />
                    </span>
                  </Tooltip>
                </li>
              </ul>
            </div>
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default withRouter(Sidebar);
