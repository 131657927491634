import { Store, StoreConfig } from "@datorama/akita";
import { MediaDetails, MediaListItem } from "../../api/media.service";

export interface MediaListParamsState {
  filter?: string;
  search?: string;
  limit?: number;
  paginationToken?: string;
}

export interface MediaListState {
  loading: boolean;
  mediaList: MediaListItem[];
}

export interface MediaDetailsState {
  loading: boolean;
  mediaDetails?: MediaDetails;
}

export interface MediaState {
  params: MediaListParamsState;
  list: MediaListState;
  details: MediaDetailsState;
}

@StoreConfig({ name: "media" })
export class MediaStore extends Store<MediaState> {
  constructor() {
    super({
      params: {
        filter: undefined,
        search: undefined
      },
      list: { loading: false, mediaList: [] },
      details: {
        loading: false
      }
    });
  }

  updateList(list: Partial<MediaListState>) {
    this.update((s) => ({
      ...s,
      list: {
        ...s.list,
        ...list
      }
    }));
  }

  updateParams(params: MediaListParamsState) {
    this.update((s) => ({
      ...s,
      params: {
        ...s.params,
        ...params
      }
    }));
  }

  updateDetails(details: Partial<MediaDetailsState>) {
    this.update((s) => ({
      ...s,
      details: {
        ...s.details,
        ...details
      }
    }));
  }
}

export const mediaStore = new MediaStore();
