import { Button, notification } from "antd";
import React, { useState } from "react";
import { getAccessToken } from "../../../api/auth.service";
import { EN } from "../../../assets/i18n/en";
import config from "../../../config";
import axios from "axios";
import { TransactionDetails } from "../../../api/account.service";

interface AccountModalTransactionRefundProps {
  transaction: TransactionDetails;
}

export const AccountModalTransactionRefund: React.FC<AccountModalTransactionRefundProps> =
  ({ transaction }) => {
    const [isRefunding, setIsRefunding] = useState(false);
    const [hasRefunded, setHasRefunded] = useState(false);

    const isAlreadyRefunded = Boolean(transaction.metadata?.refunded);

    //metadata.fileId is currently our best way of checking for transcribe transactions
    const isRefundable =
      !isAlreadyRefunded && Boolean(transaction.metadata?.fileId);

    const onClick = async () => {
      setIsRefunding(true);

      try {
        const result = await axios.post(
          `${config.apiUrl}/api/v1/admin/transactions/${transaction.id}/refund`,
          null,
          {
            headers: { "x-access-token": await getAccessToken() }
          }
        );

        if (result.status !== 200) {
          notification.error({
            message: "Refund failed",
            description: result?.data || EN.error.defaultMessage,
            duration: 10
          });
        }

        setHasRefunded(true);

        notification.info({
          message: "Refund successful",
          duration: 10
        });
      } catch (err) {
        console.log(err);
        notification.error({
          message: "Refund failed",
          description: (err as any)?.message || EN.error.defaultMessage,
          duration: 10
        });
      } finally {
        setIsRefunding(false);
      }
    };

    if (isAlreadyRefunded || hasRefunded) {
      return <>Refunded</>;
    }

    return Boolean(isRefundable) ? (
      <>
        <Button
          type="primary"
          className="btn btn-primary"
          onClick={onClick}
          loading={isRefunding}
          disabled={isRefunding}
        >
          Refund
        </Button>
      </>
    ) : null;
  };
