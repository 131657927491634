import { Query } from "@datorama/akita";
import {
  DealModalState,
  dealModalStore,
  DealModalStore
} from "./deal-modal.store";

export class DealModalQuery extends Query<DealModalState> {
  constructor(protected store: DealModalStore) {
    super(store);
  }
}

export const dealModalQuery = new DealModalQuery(dealModalStore);
