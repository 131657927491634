import { Store, StoreConfig } from "@datorama/akita";
import { AccountMember, Invitation } from "../../../interfaces/member";

export interface AccountMembersState {
  members: AccountMember[];
  invitations: Invitation[];
  loading: boolean;
  accountId: string;
}

@StoreConfig({ name: "account-members" })
export class AccountMembersStore extends Store<AccountMembersState> {
  constructor() {
    super({});
  }
  updateLoading(loading: boolean) {
    this.update((s) => ({
      ...s,
      loading
    }));
  }
}

export const accountMembersStore = new AccountMembersStore();
