import cx from "classnames";
import React, { Component } from "react";
import { AuthConsumer } from "../../contexts/auth.context";
import { SideDrawer } from "../sideDrawer";
import MobileNav from "./navigation";
import { Sidebar } from "./sidebar";
import styles from "./styles.module.css";

export const WithLayout = (WrappedComponent) =>
  class extends Component {
    constructor(props) {
      super(props);

      this.state = {
        sideNavFolded: false,
        sideNavFoldedMobile: false,
        defaultTheme: true,
        user: this.props.user,
        account: null,
        useDrawer: false,
        drawerType: "Video"
      };
    }

    handleSideNavFold = () => {
      const { sideNavFolded } = this.state;
      this.setState({
        sideNavFolded: !sideNavFolded
      });
    };
    handleSideNavFoldMobile = () => {
      const { sideNavFoldedMobile } = this.state;
      this.setState({
        sideNavFoldedMobile: !sideNavFoldedMobile
      });
    };

    toggleDrawer = (e, type = "Video") =>
      this.setState({ useDrawer: !this.state.useDrawer, drawerType: type });

    render() {
      const { user, useDrawer, drawerType } = this.state;

      return (
        <div className={"app"}>
          <MobileNav
            account={null}
            toggleDrawer={this.toggleDrawer}
            isPublicHeader={false}
          />
          <div className={cx("layout", styles.sidebarLayput)}>
            <Sidebar
              handleSideNavFold={this.handleSideNavFold}
              handleSideNavFoldMobile={this.handleSideNavFoldMobile}
              toggleDrawer={this.toggleDrawer}
              isPublicHeader={false}
              user={user}
            />
            <SideDrawer
              toggleDrawer={this.toggleDrawer}
              hasSideDrawer={useDrawer}
              type={drawerType}
            ></SideDrawer>
            <div className="page-container">
              <AuthConsumer>
                {(user) => (
                  <WrappedComponent
                    {...this.props}
                    user={user}
                    toggleDrawer={this.toggleDrawer}
                  />
                )}
              </AuthConsumer>
            </div>
          </div>
        </div>
      );
    }
  };

export default WithLayout;
