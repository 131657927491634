import { Table } from "antd";
import React from "react";
import { TransactionDetails } from "../../../api/account.service";
import { AccountModalTransactionRefund } from "./account-modal-transaction-refund";
import { SortOrder } from "antd/lib/table/interface";

interface AccountModalTransactionDetailsProps {
  transactions: TransactionDetails[];
}

export const AccountModalTransactionDetails: React.FC<AccountModalTransactionDetailsProps> =
  ({ transactions }) => {
    const columns = [
      {
        title: "Id",
        dataIndex: "id",
        key: "id"
      },
      {
        title: "Invoice Id",
        dataIndex: "invoiceId",
        key: "invoiceId"
      },
      {
        title: "Credit type",
        dataIndex: "creditType",
        key: "creditType"
      },
      {
        title: "Credit - Free Seconds",
        dataIndex: "creditFreeSeconds",
        key: "creditFreeSeconds"
      },
      {
        title: "Credit - Paid Seconds",
        dataIndex: "creditPaidSeconds",
        key: "creditPaidSeconds"
      },
      {
        title: "Credit - PAYG Seconds",
        dataIndex: "creditPaygSeconds",
        key: "creditPaygSeconds"
      },
      {
        title: "Credit - Extra Seconds",
        dataIndex: "creditExtraSeconds",
        key: "creditExtraSeconds"
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount"
      },
      {
        title: "Operation",
        dataIndex: "operation",
        key: "operation"
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type"
      },
      {
        title: "Reason",
        dataIndex: "reason",
        key: "reason"
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description"
      },
      {
        title: "Metadata",
        dataIndex: "metadata",
        key: "metadata"
      },
      {
        title: "Created at",
        dataIndex: "createdAt",
        key: "createdAt",
        defaultSortOrder: "descend" as SortOrder,
        sorter: (a: any, b: any) => (a.createdAt > b.createdAt ? 1 : -1)
      },
      {
        dataIndex: "id",
        width: "150px",
        fixed: "right" as "right",
        render: (value: string, row: TransactionDetails) => {
          return <AccountModalTransactionRefund transaction={row} />;
        }
      }
    ];

    return (
      <>
        <Table
          rowKey="id"
          dataSource={transactions.map((f) => {
            return { key: f.id, ...f };
          })}
          columns={columns}
          scroll={{ x: 1300 }}
        />
      </>
    );
  };
