import { notification } from "antd";
import axios from "axios";
import { DateTime } from "luxon";
import queryString from "query-string";
import config from "../config";
import { AccountType as IAccountType } from "../interfaces/account";
import { accountQuery } from "../state/account/account.query";
import {
  AccountListParamsState,
  accountStore
} from "../state/account/account.store";
import { getAccessToken } from "./auth.service";

const baseURL = `${config.apiUrl}/api/v1`;
const adminBaseURL = `${baseURL}/admin`;

export interface AccountListItemOwner {
  name: string;
  email: string;
}

export interface AccountListItem {
  accountId: string;
  name: string;
  balance: {
    free: number;
    paid: number;
    extra: number;
    payg: number;
    post: number;
  };
  createdAt: DateTime;
  updatedAt: DateTime;
  storageBytesUsed: number;
  billing: IAccountBilling;
  settings: string;
  isPayg?: boolean;
  accountType: AccountType;
  owner?: AccountListItemOwner;
  numberOfUsers?: number;
}

export interface AccountType {
  id: string;
  type: string;
}

export interface Invitation {
  email: string;
  role: string;
}

export interface AddMember {
  email: string;
  role: string;
}
export interface CreateBusinessAccountParams {
  accountName: string;
  firstName: string;
  surname: string;
  email: string;
  address: string;
  city?: string;
  state?: string;
  postalCode: string;
  salesRep: string;
  country: string;
}

export const createBusinessAccount = async (
  params: CreateBusinessAccountParams
) => {
  await axios.post(`${adminBaseURL}/accounts/business`, params, {
    headers: {
      "x-access-token": await getAccessToken()
    }
  });
};

export const fetchAccountList = async () => {
  try {
    const params = accountQuery.accountListParams;
    if (!(params.filter && params.search) && !params.accountType) {
      accountStore.updateList({
        loading: false,
        accountList: []
      });
      return;
    }

    accountStore.updateList({
      loading: true,
      accountList: []
    });

    const query = buildQueryString(params);

    const { data } = await axios.get<{
      message: string;
      accountList: AccountListItem[];
    }>(`${adminBaseURL}/accounts?${query}`, {
      headers: {
        "x-access-token": await getAccessToken()
      }
    });

    accountStore.updateList({
      loading: false,
      accountList: data.accountList
    });
  } catch (error) {
    handleError(error);
  } finally {
    accountStore.updateList({
      loading: false
    });
  }
};

export interface AccountDetails {
  accountId: string;
  name: string;
  accountType: AccountType;
  creditFreeSeconds: number;
  creditPaidSeconds: number;
  creditPaygSeconds: number;
  creditExtraSeconds: number;
  totalFreeSecondsUsed: number;
  totalPaidSecondsUsed: number;
  billing: IAccountBilling;
  settings: IAccountSettings;
  ownerId?: string;
  salesRep?: string;
  teamCapacity: number;
  transactions: TransactionDetails[];
  assets: Asset[];
  isPayg?: boolean;
  createdAt: DateTime;
  updatedAt: DateTime;
}

export interface IAccountBilling {
  stripeId?: string;
  foundingMember?: boolean;
  planName?: string;
  createdAt?: string;
  renewAt?: string;
  cancelAt?: string;
  interval?: "year" | "month" | string;
  trialPlanName?: string;
  trialCreatedAt?: string;
  trialEndAt?: string;
  trialReminderSentAt?: string;
  trialCancelAtEnd?: boolean;
  monthlyTopupCredit?: number;
  nextTopupDate?: string;
  dealId?: string;
  isUpfrontCredit?: boolean;
}

export interface IAccountSettings {
  hubspotId?: number;
  hasAlreadyTrialled?: boolean;
  beta?: boolean;
  primaryColors?: string[];
  outlineColors?: string[];
  hasSeenMediaEditorTour?: boolean;
}

export interface TransactionDetails {
  id: string;
  accountId: string;
  invoiceId?: string;
  creditType: string;
  creditFreeSeconds: number;
  creditPaidSeconds: number;
  creditExtraSeconds: number;
  adjustment: number;
  reason: string;
  metadata?: any;
  createdAt: DateTime;
}

export interface Asset {
  id: string;
  url: string;
  filename: string;
  type: string;
  metadata: any;
}
export const fetchAccountDetails = async (accountId: string) => {
  try {
    accountStore.updateDetails({
      loading: true,
      accountDetails: undefined
    });
    const { data } = await axios.get<{
      message: string;
      accountDetails: AccountDetails;
    }>(`${adminBaseURL}/accounts/${accountId}`, {
      headers: {
        "x-access-token": await getAccessToken()
      }
    });
    console.log("data:", data);
    accountStore.updateDetails({
      loading: false,
      accountDetails: data.accountDetails
    });
  } catch (error) {
    handleError(error);
    accountStore.updateDetails({
      loading: false
    });
  }
};

export const destroyAccount = async (accountId: string) => {
  try {
    const { data } = await axios.delete<{
      message: string;
      accountDetails: AccountDetails;
    }>(`${adminBaseURL}/accounts/${accountId}/destroy`, {
      headers: {
        "x-access-token": await getAccessToken()
      }
    });
    console.log("data:", data);
    accountStore.updateDetails({
      loading: false,
      accountDetails: data.accountDetails
    });
  } catch (error) {
    handleError(error);
    accountStore.updateDetails({
      loading: false
    });
  }
};

export const switchAccountType = async (
  accountId: string,
  type: IAccountType
) => {
  await axios.put<{
    message: string;
  }>(
    `${adminBaseURL}/accounts/${accountId}/switch-type`,
    { type },
    {
      headers: {
        "x-access-token": await getAccessToken()
      }
    }
  );
};

export interface InviteParams {
  accountName: string;
  invitations: Invitation[];
}

export const InviteAccountMember = async (params: InviteParams) => {
  await axios.post<{
    message: string;
  }>(
    `${adminBaseURL}/accounts/${params.accountName}/invite-member`,
    { invites: params.invitations },
    {
      headers: {
        "x-access-token": await getAccessToken()
      }
    }
  );
};

export interface AddMemberParams {
  accountId: string;
  member: AddMember;
}

export const AddAccountMember = async (params: AddMemberParams) => {
  const { accountId, member } = params;
  await axios.put<{
    message: string;
  }>(
    `${adminBaseURL}/accounts/${accountId}/add-member`,
    { member },
    {
      headers: {
        "x-access-token": await getAccessToken()
      }
    }
  );
};

export const DeleteInvitation = async (
  accountId: string,
  invitationId: string
) => {
  try {
    await axios.delete<{
      message: string;
    }>(`${adminBaseURL}/accounts/${accountId}/invitation/${invitationId}`, {
      headers: {
        "x-access-token": await getAccessToken()
      }
    });
  } catch (error) {
    handleError(error);
  }
};

// Private functions

const handleError = (e: any) => {
  console.error(e);

  notification.error({
    message: "Something went wrong",
    description: e.message ?? e,
    duration: 10
  });
};

const buildQueryString = (params: AccountListParamsState) => {
  let queryStringBuilder = {};

  if (params.filter && params.search) {
    queryStringBuilder = {
      ...queryStringBuilder,
      filter: params.filter,
      search: params.search
    };
  }

  if (params.accountType) {
    queryStringBuilder = {
      ...queryStringBuilder,
      accountType: params.accountType
    };
  }
  return queryString.stringify(queryStringBuilder);
};
