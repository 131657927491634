import { get, isEmpty, sortBy } from "lodash";

export const reverseSort = (collection: any, element: any) =>
  sortBy(collection, (i) => i[element]).reverse();

export const hasData = (collection: any, element: any) =>
  !isEmpty(get(collection, element) && get(collection[element], "items"));

export const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * Math.floor(max));
};

export const truncate = (text: string, n: number) =>
  text.length > n ? text.substr(0, n - 1) + "..." : text;
