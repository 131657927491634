import React, { useState } from "react";
import { UserDetailsCognito } from "../../../api/user.service";
import { Tag } from "antd";
import { CognitoDetailEditable } from "./cognito-detail-editable";
import { ToggleEducation } from "../users-modal/toggle-education";
import cognitoAttributeNames from "../../../utils/cognitoAttributeNames";

interface UserCognitoDetailsProps {
  details: UserDetailsCognito;
  setCognitoAttribute: (name: string, value: string) => void;
  setUserEducation: (value: boolean, username?: string) => void;
}

export const UserCognitoDetails: React.FC<UserCognitoDetailsProps> = ({
  details,
  setCognitoAttribute,
  setUserEducation
}) => {
  const [isTogglingEducation, setIsTogglingEducation] = useState(false);

  const onTogglingEducation = (isToggling: boolean) => {
    setIsTogglingEducation(isToggling);
  };

  const [isEditingOrSavingCustomSettings, setIsEditingOrSavingCustomSettings] =
    useState(false);

  const onEditingOrSaving = (
    attributeName: string,
    isEditingOrSaving: boolean
  ) => {
    if (attributeName === cognitoAttributeNames.customSettings) {
      setIsEditingOrSavingCustomSettings(isEditingOrSaving);
    }
  };

  //CognitoDetailEditable and ToggleEducation are sibligns that affect each other
  //so we've raised some of their state / events to this level
  const attributeFields = details.attributes.map((a, i) => {
    return (
      <p key={i}>
        <CognitoDetailEditable
          name={a.name}
          value={a.value}
          //we only pass in isTogglingEducation if we're dealing with the customSettings attribute
          isTogglingEducation={
            a.name === cognitoAttributeNames.customSettings
              ? isTogglingEducation
              : false
          }
          setCognitoAttribute={setCognitoAttribute}
          onEditingOrSaving={onEditingOrSaving}
        />
        {a.name === cognitoAttributeNames.customSettings && (
          <ToggleEducation
            details={details}
            setUserEducation={setUserEducation}
            onToggling={onTogglingEducation}
            isToggling={isTogglingEducation}
            isEditingOrSavingCustomSettings={
              a.name === cognitoAttributeNames.customSettings
                ? isEditingOrSavingCustomSettings
                : false
            }
          />
        )}
      </p>
    );
  });

  return (
    <>
      <p>username: {details.username}</p>
      <p>
        groups:{" "}
        {details?.groups?.map((g, i) => {
          return (
            <Tag key={i} color="blue">
              {g}
            </Tag>
          );
        })}
      </p>
      <p>status: {details.status}</p>
      <p>enabled: {details.enabled}</p>
      <p>createdAt: {details.createdAt}</p>
      <p>updatedAt: {details.updatedAt}</p>
      <p>Attributes</p>
      {attributeFields}
    </>
  );
};
