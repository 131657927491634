import {
  mdiFileDocumentOutline,
  mdiFileVideoOutline,
  mdiMessageTextOutline
} from "@mdi/js";
import React, { useState } from "react";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MediaUsageStats } from "../../../interfaces/stats";
import { dashboardQuery } from "../../../state/dashboard/dashboard.query";
import {
  NumberStatsCard,
  NumberStatsCardSize
} from "../../stats-card/number-stats-card/number-stats-card";
import { StatsCard } from "../../stats-card/stats-card";

interface DownloadStatsCardsProps {
  notifyOfDownloadStatsStatus: (isEnabled: boolean) => void;
}

export const DownloadStatsCards: React.FC<DownloadStatsCardsProps> = ({
  notifyOfDownloadStatsStatus
}) => {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState<MediaUsageStats>();

  const loadData = (isEnabled: boolean) => {
    if (!isEnabled) {
      setStats({
        burntSize: 0,
        burntDuration: 0,
        totalBurnt: 0,
        totalSrtDownloads: 0,
        totalTxtDownloads: 0,
        totalVideoDownloads: 0,
        totalVideos: 0,
        uploadDuration: 0,
        uploadSize: 0
      });

      notifyOfDownloadStatsStatus(false);

      return;
    }

    const unsubcribe$ = new Subject();

    dashboardQuery
      .select("mediaStats")
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ loading, stats }) => {
        setLoading(loading);
        setStats(stats);
      });

    notifyOfDownloadStatsStatus(true);

    return () => unsubcribe$.next();
  };

  return (
    <StatsCard title="Download Stats" loadData={loadData}>
      <div className="row mb-0">
        <NumberStatsCard
          title="Total Media"
          loading={loading}
          result={stats?.totalVideoDownloads ?? 0}
          icon={mdiFileVideoOutline}
          size={NumberStatsCardSize.IsThird}
        />
        <NumberStatsCard
          title="Total SRT"
          loading={loading}
          result={stats?.totalSrtDownloads ?? 0}
          icon={mdiMessageTextOutline}
          size={NumberStatsCardSize.IsThird}
        />
        <NumberStatsCard
          title="Total TXT"
          loading={loading}
          result={stats?.totalTxtDownloads ?? 0}
          icon={mdiFileDocumentOutline}
          size={NumberStatsCardSize.IsThird}
        />
      </div>
    </StatsCard>
  );
};
