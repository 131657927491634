import React from "react";

export const EN = {
  error: {
    defaultMessage: (
      <>
        There was an unknown error, please try again or contact{" "}
        <a href="mailto:support@getsubly.com">support@getsubly.com</a>
      </>
    )
  }
};
