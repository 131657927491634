import { mdiClockOutline, mdiAccountGroup, mdiVideo } from "@mdi/js";
import React, { useState } from "react";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UserMediaStats } from "../../../interfaces/stats";
import { dashboardQuery } from "../../../state/dashboard/dashboard.query";
import {
  NumberStatsCard,
  NumberStatsCardSize
} from "../../stats-card/number-stats-card/number-stats-card";
import { StatsCard } from "../../stats-card/stats-card";
import { fetchUserMediaStats } from "../../../api/stats.service";

interface UserMediaStatsCardsProps {
  notifyOfUserMediaStatsStatus: (isEnabled: boolean) => void;
  isPaid: boolean;
}

export const UserMediaStatsCards: React.FC<UserMediaStatsCardsProps> = ({
  notifyOfUserMediaStatsStatus,
  isPaid
}) => {
  const statsKey = isPaid ? "paidUserMediaStats" : "freeUserMediaStats";
  const title = `User Media Stats: ${isPaid ? "Paid" : "Free"}`;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | undefined>(false);
  const [stats, setStats] = useState<UserMediaStats>();

  const loadData = (isEnabled: boolean) => {
    if (!isEnabled) {
      setStats({
        activeUsers: 0,
        secondsPerUser: 0,
        videosPerUser: 0
      });
      notifyOfUserMediaStatsStatus(false);

      return;
    }

    const unsubcribe$ = new Subject();

    fetchUserMediaStats(
      dashboardQuery.filterStartDate,
      dashboardQuery.filterEndDate,
      isPaid
    );

    dashboardQuery
      .select(statsKey)
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ loading, stats, error }) => {
        setLoading(loading);
        setError(error);
        setStats(stats);
      });

    notifyOfUserMediaStatsStatus(true);

    return () => unsubcribe$.next();
  };

  return (
    <StatsCard title={title} loadData={loadData}>
      <div className="row">
        <NumberStatsCard
          title="Avg Minutes per user"
          loading={loading}
          error={error}
          result={stats?.secondsPerUser ?? 0}
          icon={mdiClockOutline}
          size={NumberStatsCardSize.IsThird}
          formatOptions={{ format: "0,0.00", factor: 60 }}
        />

        <NumberStatsCard
          title="Avg videos per user"
          loading={loading}
          error={error}
          result={stats?.videosPerUser ?? 0}
          icon={mdiVideo}
          size={NumberStatsCardSize.IsThird}
          formatOptions={{ format: "0,0.00" }}
        />

        <NumberStatsCard
          title="Users with video"
          loading={loading}
          error={error}
          result={stats?.activeUsers ?? 0}
          icon={mdiAccountGroup}
          size={NumberStatsCardSize.IsThird}
        />
      </div>
    </StatsCard>
  );
};
