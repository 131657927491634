import { Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../contexts/auth.context";
import { getSingleQueryStringParameterValue } from "../../../utils/querystring";
import { AuthWrapper, AuthWrapperType } from "../auth-wrapper/auth-wrapper";
import SignInForm from "./sign-in-form";

interface SignInProps extends FormComponentProps {
  email: string;
  name: string;
  password: string;
}
export const SignIn: React.FC<SignInProps> = ({ form }) => {
  const [useAlert, setUseAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const history = useHistory();
  const { login } = useContext(AuthContext);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    form.validateFields(async (error, values) => {
      if (error) {
        setUseAlert(true);
        setAlertMessage("Please fix these errors");
        return;
      }

      setLoading(true);
      setUseAlert(false);
      setAlertMessage("");

      const { success, loading, useAlert, alertMessage } = await login(values);

      setLoading(loading);
      setUseAlert(useAlert);
      setAlertMessage(alertMessage);

      if (success === true) {
        const redirect = getSingleQueryStringParameterValue(
          window.location.search,
          "redirect"
        );

        Boolean(redirect) ? history.push(redirect) : history.push("/");
      }
    });
  };
  return (
    <AuthWrapper
      type={AuthWrapperType.SignIn}
      useAlert={useAlert}
      alertMessage={alertMessage}
    >
      <SignInForm form={form} loading={loading} onSubmit={onSubmit} />
    </AuthWrapper>
  );
};

const WithForm = Form.create()(SignIn);
export default WithForm;
