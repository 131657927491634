import { AssConfig } from "../utils/webvtt2ass/subtitle.text";

export interface Media {
  createdAt: string;
  mediaId: string;
  status: MediaStatus;
  name: string;
  files: MediaFile[];
  current: string;
  thumbnail: string;
  jobs: Job[];
  assConfig: AssConfig;
  lastBurnHash?: string;
  subtitlesHash?: string;
  original_file_name: string;
  languageCode: string;
}

export interface BasicMedia extends Media {
  id: string;
  name: string;
  video_url: string;
  poster_url: string;
  status: MediaStatus;
  needsBurning: boolean;
  isBurning?: boolean;
  burningTasks?: BurningTask[];
}

export interface Job {
  id: string;
  type: JobType;
  message: string;
  startDate: string;
  endDate: string;
  inputs: string[];
  outputs: string[];
  status: MediaStatus;
}

export interface MediaFile {
  extension: string;
  size_bytes: number;
  uploadedDate: string;
  type: string;
  url: string;
  filename: string;
  original_filename: string;
  width: number;
  id: string;
  key: string;
  duration_seconds?: number;
  burnt?: boolean;
  height?: number;
}

export enum MediaStatus {
  Uploading = "UPLOADING",
  Uploaded = "UPLOADED",
  Converting = "CONVERTING",
  Pending = "PENDING",
  Ready = "READY",
  Complete = "COMPLETE",
  Archived = "ARCHIVED",
  Deleted = "DELETED",
  Failed = "FAILED",
  Submitted = "SUBMITTED"
}

export enum JobType {
  Burn = "BURN",
  Transcribe = "TRANSCRIBE",
  Upload = "UPLOAD"
}

export enum ProgressProcess {
  FetchingFiles = "Fetching files.",
  PreparingFiles = "Preparing files for edit.",
  ModifyingVideo = "Modifying video.",
  Uploading = "Uploading processed video.",
  CleanUp = "Clean up."
}

export interface BurningTask {
  process: ProgressProcess;
  isBurnTask: false;
  progress: number;
}
