import React, { useState } from "react";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { notification } from "antd";
import { Modal } from "../../modal/modal";
import { EN } from "../../../assets/i18n/en";
import { createBusinessUser } from "../../../api/user.service";

import styles from "./new-business-user-modal.module.scss";

interface NewBusinessUserModalProps {
  onClose: () => void;
}

export interface INewBusinessUserForm {
  givenName: string;
  familyName: string;
  email: string;
}

export const NewBusinessUserModal: React.FC<NewBusinessUserModalProps> = ({
  onClose
}) => {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors, getValues } =
    useForm<INewBusinessUserForm>();

  const onSubmit = async ({
    givenName,
    familyName,
    email
  }: INewBusinessUserForm) => {
    setLoading(true);

    try {
      await createBusinessUser({
        givenName,
        familyName,
        email: email.toLowerCase()
      });

      onClose();
    } catch (e) {
      const description =
        (e as any)?.response?.data?.message ||
        (e as any)?.message ||
        EN.error.defaultMessage;

      notification.error({
        message: "Something went wrong",
        description,
        duration: 10
      });
    }

    setLoading(false);
  };

  const values = getValues();

  return (
    <Modal onDismiss={onClose} className={styles["modal"]} hasCloseIcon>
      <h5 className="mb-3">New Business User</h5>
      <form
        className="form-container container"
        onSubmit={handleSubmit(onSubmit)}
      >
        <label>Given Name</label>
        <input
          id="givenName"
          name="givenName"
          ref={register({ required: true })}
          defaultValue={values.givenName}
          disabled={loading}
          className={classNames("form-control", {
            "is-invalid": errors.givenName
          })}
        />
        <label>Family Name</label>
        <input
          id="familyName"
          name="familyName"
          ref={register({ required: true })}
          defaultValue={values.familyName}
          disabled={loading}
          className={classNames("form-control", {
            "is-invalid": errors.familyName
          })}
        />
        <label>Email</label>
        <input
          id="email"
          name="email"
          type="email"
          ref={register({ required: true })}
          defaultValue={values.email}
          disabled={loading}
          className={classNames("form-control", {
            "is-invalid": errors.email
          })}
        />
        <button
          type="submit"
          className="btn btn-primary btn-block mt-3"
          style={{ width: 120 }}
          disabled={loading}
        >
          {loading ? "Saving" : "Save"}
        </button>
      </form>
    </Modal>
  );
};
