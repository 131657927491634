import { Query } from "@datorama/akita";
import {
  AccountMembersStore,
  AccountMembersState,
  accountMembersStore
} from "./account-members.store";

export class AccountMembersQuery extends Query<AccountMembersState> {
  constructor(protected store: AccountMembersStore) {
    super(store);
  }
}

export const accountMembersQuery = new AccountMembersQuery(accountMembersStore);
