import {
  mdiAccountGroupOutline,
  mdiAccountQuestionOutline,
  mdiContentDuplicate,
  mdiAccountGroup,
  mdiGoogle
} from "@mdi/js";
import React, { useState } from "react";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { fetchUsersStats } from "../../../api/stats.service";
import { UsersStats } from "../../../interfaces/stats";
import { dashboardQuery } from "../../../state/dashboard/dashboard.query";
import {
  NumberStatsCard,
  NumberStatsCardSize
} from "../../stats-card/number-stats-card/number-stats-card";
import { StatsCard } from "../../stats-card/stats-card";

export const TotalStatsCards: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState<UsersStats>();

  const loadData = (isEnabled: boolean) => {
    if (!isEnabled) {
      setStats({
        unconfirmedUserCount: 0,
        externalUserCount: 0,
        duplicateEmailCount: 0,
        userCount: 0,
        totalUploadUsers: 0
      });
      return;
    }

    const unsubcribe$ = new Subject();

    fetchUsersStats();

    dashboardQuery
      .select("usersStats")
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ loading, stats }) => {
        setLoading(loading);
        setStats(stats);
      });

    return () => unsubcribe$.next();
  };

  return (
    <StatsCard title="User Stats" subtitle="Lifetime" loadData={loadData}>
      <div className="row">
        <NumberStatsCard
          title="Total Users"
          loading={loading}
          result={stats?.userCount ?? 0}
          icon={mdiAccountGroupOutline}
          size={NumberStatsCardSize.IsThird}
        />
        <NumberStatsCard
          title="Google Sign Ups"
          loading={loading}
          result={stats?.externalUserCount ?? 0}
          icon={mdiGoogle}
          size={NumberStatsCardSize.IsThird}
        />
        <NumberStatsCard
          title="Unconfirmed"
          loading={loading}
          result={stats?.unconfirmedUserCount ?? 0}
          icon={mdiAccountQuestionOutline}
          size={NumberStatsCardSize.IsThird}
        />
      </div>
      <div className="row mb-0">
        <NumberStatsCard
          title="Duplicated"
          loading={loading}
          result={stats?.duplicateEmailCount ?? 0}
          icon={mdiContentDuplicate}
          size={NumberStatsCardSize.IsThird}
        />
        <NumberStatsCard
          title="Users with Media"
          loading={loading}
          result={stats?.totalUploadUsers ?? 0}
          icon={mdiAccountGroup}
          size={NumberStatsCardSize.IsThird}
        />
      </div>
    </StatsCard>
  );
};
