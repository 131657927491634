import { Query } from "@datorama/akita";
import {
  AccountDetailsState,
  AccountListParamsState,
  AccountListState,
  AccountState,
  AccountStore,
  accountStore
} from "./account.store";

export class AccountQuery extends Query<AccountState> {
  get list(): AccountListState {
    return this.getValue().list;
  }

  get details(): AccountDetailsState {
    return this.getValue().details;
  }

  get accountListParams(): AccountListParamsState {
    return this.getValue().params;
  }

  constructor(protected store: AccountStore) {
    super(store);
  }
}

export const accountQuery = new AccountQuery(accountStore);
