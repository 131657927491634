import {
  mdiAccountOutline,
  mdiShieldCheckOutline,
  mdiStar,
  mdiStarOutline
} from "@mdi/js";
import React, { useState } from "react";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { fetchAuthStats } from "../../../api/stats.service";
import { AuthUsageStats } from "../../../interfaces/stats";
import { dashboardQuery } from "../../../state/dashboard/dashboard.query";
import {
  NumberStatsCard,
  NumberStatsCardSize
} from "../../stats-card/number-stats-card/number-stats-card";
import { StatsCard } from "../../stats-card/stats-card";

interface AuthStatsCardsProps {
  notifyOfAuthStatsStatus: (isEnabled: boolean) => void;
}

export const AuthStatsCards: React.FC<AuthStatsCardsProps> = ({
  notifyOfAuthStatsStatus
}) => {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState<AuthUsageStats>();

  const loadData = (isEnabled: boolean) => {
    if (!isEnabled) {
      setStats({
        totalLogins: 0,
        totalSignups: 0,
        totalVerified: 0,
        uniqueLogins: 0
      });
      notifyOfAuthStatsStatus(false);
      return;
    }

    const unsubcribe$ = new Subject();

    fetchAuthStats(
      dashboardQuery.filterStartDate,
      dashboardQuery.filterEndDate
    );

    dashboardQuery
      .select("authStats")
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ loading, stats }) => {
        setLoading(loading);
        setStats(stats);
      });

    notifyOfAuthStatsStatus(true);

    return () => unsubcribe$.next();
  };

  return (
    <StatsCard title="User Stats" loadData={loadData}>
      <div className="row">
        <NumberStatsCard
          title="Sign ups"
          loading={loading}
          result={stats?.totalSignups ?? 0}
          icon={mdiAccountOutline}
          size={NumberStatsCardSize.IsHalf}
        />

        <NumberStatsCard
          title="Verified"
          loading={loading}
          result={stats?.totalVerified ?? 0}
          icon={mdiShieldCheckOutline}
          size={NumberStatsCardSize.IsHalf}
        />
      </div>
      <div className="row mb-0">
        <NumberStatsCard
          title="Total Logins"
          loading={loading}
          result={stats?.totalLogins ?? 0}
          icon={mdiStarOutline}
          size={NumberStatsCardSize.IsHalf}
        />

        <NumberStatsCard
          title="Unique Logins"
          loading={loading}
          result={stats?.uniqueLogins ?? 0}
          icon={mdiStar}
          size={NumberStatsCardSize.IsHalf}
        />
      </div>
    </StatsCard>
  );
};
