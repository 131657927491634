import { Button, notification } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { getAccessToken } from "../../../api/auth.service";
import { EN } from "../../../assets/i18n/en";
import config from "../../../config";
import "./delete-user.scss";

interface ResetUserPasswordProps {
  username?: string;
  onCancel: () => void;
}

export const ResetUserPassword: React.FC<ResetUserPasswordProps> = ({
  username,
  onCancel
}) => {
  const [resetting, setResetting] = useState(false);
  const [newPassword, setNewPassword] = useState("Sub!y123");

  const onClickReset = async () => {
    try {
      setResetting(true);
      const url = `${config.apiUrl}/api/v1/admin/users/${username}/set-password`;

      await axios.put(
        url,
        { password: newPassword },
        { headers: { "x-access-token": await getAccessToken() } }
      );

      notification.info({
        message: `Users' password set to '${newPassword}'`,
        duration: 10
      });
    } catch (err) {
      notification.error({
        message: "Something went wrong",
        description: (err as any)?.message || EN.error.defaultMessage,
        duration: 5
      });
    } finally {
      setResetting(false);
      onCancel();
    }
  };

  const onChangeValue = (event: React.FormEvent<HTMLInputElement>) => {
    setNewPassword(event.currentTarget.value.toString());
  };

  return (
    <>
      <input
        style={{ height: "auto", marginRight: "5px" }}
        placeholder={"New password"}
        value={newPassword}
        onChange={(e) => onChangeValue(e)}
      />
      <Button
        onClick={onClickReset}
        loading={resetting}
        disabled={resetting || newPassword.length < 8}
        className="ant-btn btn btn-primary btn-delete-user"
      >
        Set this password
      </Button>
      <Button
        onClick={onCancel}
        loading={resetting}
        disabled={resetting}
        className="ant-btn btn btn-outline-secondary btn-delete-user"
      >
        Cancel
      </Button>
    </>
  );
};
