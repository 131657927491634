import React from "react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { fetchDealList } from "../../api/deal.service";
import { ModalContext, ModalType } from "../../contexts/modal.context";
import { IDeal } from "../../interfaces/deal";
import { AccountListParamsState } from "../../state/account/account.store";
import { dealListQuery } from "../../state/deal/deal-list/deal-list.query";
import { dealListStore } from "../../state/deal/deal-list/deal-list.store";
import { getSingleQueryStringParameterValue } from "../../utils/querystring";
import { AccountsRouteParams } from "../accounts/accounts";
import { DealModal } from "../deals/deal-modal/deal-modal";
import AdminLayout from "../layouts/adminLayout";
import { DealTable } from "./deal-table/deal-table";

const Deals: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [dealList, setDealList] = useState<IDeal[]>();
  const [params, setParams] = useState<AccountListParamsState>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { showModal, hideModal } = React.useContext(ModalContext);

  let { id } = useParams<AccountsRouteParams>();

  const searchField = getSingleQueryStringParameterValue(
    window.location.search,
    "filter"
  );

  const history = useHistory();

  const onSelect = (params: { accountId: string; dealId: string }) =>
    showModal(
      ModalType.Deal,
      <DealModal
        params={params}
        onClose={(reload?: boolean) => {
          hideModal(ModalType.Deal);
          if (reload) {
            fetchDealList();
          }
        }}
      />
    );

  const onResetSearch = () => {
    history.push("/deals");
  };

  const onSearch = (filter: string, search: string) => {
    let url = `/deals/${search}`;

    if (filter !== "deals") {
      url = url + `?filter=${filter}`;
    }

    history.push(url);
  };

  useEffect(() => {
    let params = {
      filter: "deal",
      search: ""
    };

    if (id != null) {
      params.search = id;

      Boolean(searchField)
        ? (params.filter = searchField)
        : (params.filter = "deal");
    }

    setParams(params);

    dealListStore.updateParams(params);

    fetchDealList();

    const unsubcribe$ = new Subject();

    dealListQuery
      .select()
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ deals, loading }) => {
        setLoading(loading);
        setDealList(deals);
        if (params.filter === "deal" && deals.length === 1) {
          // onSelect({ accountId: deals[0].accountId, dealId: deals[0].id });
        }
      });

    return () => unsubcribe$.next();

    //rerender on route param changes only - everything here goes through the router
  }, [searchField, id]);

  return (
    <AdminLayout parent="Lists" title="Deal List">
      <DealTable
        loading={loading}
        params={params}
        dealList={dealList}
        onSelect={onSelect}
        onSearch={onSearch}
        onResetSearch={onResetSearch}
      />
    </AdminLayout>
  );
};

export default Deals;
