import { AccountListItem } from "../api/account.service";
import { Coupon, TaxRate } from "../interfaces/deal";

export const calculateInvoiceItemTotal = ({
  item,
  coupons,
  taxCodes
}: {
  item: {
    unitAmount: number;
    quantity: number;
    coupons?: (string | undefined)[];
    taxRates?: string[];
  };
  coupons: Coupon[];
  taxCodes: TaxRate[];
}): number => {
  let total = item.unitAmount * item.quantity;

  console.log("item?.coupons:", item?.coupons);
  // Deduct coupons
  if (item?.coupons?.length) {
    for (const itemCoupon of item.coupons) {
      if (itemCoupon) {
        const coupon = coupons.find((c) => c.id === itemCoupon);

        console.log("coupon:", coupon);

        if (coupon) {
          if (coupon.amount_off) {
            total -= coupon.amount_off;
          } else if (coupon.percent_off) {
            total -= total * coupon.amount_off;
          }
        }
      }
    }
  }

  // Deduct Tax

  return total;
};

export const calcMinutesLeft = (value: AccountListItem): number =>
  Math.floor(
    ((value.balance.free || 0) +
      (value.balance.extra || 0) +
      (value.balance.paid || 0) +
      (value.balance.payg || 0)) /
      60
  );
export interface FormatBytes {
  size: number;
  units: string;
}

const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
const k = 1024;

export const bytesToHuman = (bytes: number, decimals = 2): string => {
  const { size, units } = formatBytes(bytes, decimals);
  return `${size} ${units}`;
};

export const formatBytes = (
  bytes: number,
  decimals = 2,
  units?: string
): FormatBytes => {
  if (bytes === 0) return { size: 0, units: "Bytes" };

  let i: number;
  const dm = decimals < 0 ? 0 : decimals;
  if (units && sizes.indexOf(units) !== -1) {
    i = sizes.indexOf(units);
  } else {
    i = Math.floor(Math.log(Math.abs(bytes)) / Math.log(k));
  }
  const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  return { size: formattedSize, units: sizes[i] };
};

export const getBytes = (size: number, units = "GB"): number =>
  size * k ** (sizes.indexOf(units) || 0);
