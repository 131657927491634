import React, { useEffect, useState } from "react";
import { Input, Button, Select, Tooltip } from "antd";
import { MediaListItem } from "../../../api/media.service";
import { MediaListParamsState } from "../../../state/media/media.store";
import classNames from "classnames";
import { toast } from "react-toastify";
import { bytesToHuman } from "../../../utils/calc";
import { formatDateTime } from "../../../utils/date-formatter";
import { NewTable } from "../../new-table";
import { DateTime, Duration } from "luxon";
import { durationToString } from "../../../utils/time";

const { Option } = Select;

interface MediaTableProps {
  loading: boolean;
  mediaList?: MediaListItem[];
  params?: MediaListParamsState;
  onSelect: (mediaId: string) => void;
  onSearch: (filter: string, search: string) => void;
  onResetSearch: () => void;
}
export const MediaTable: React.FC<MediaTableProps> = ({
  loading,
  mediaList,
  params,
  onSelect,
  onSearch,
  onResetSearch
}) => {
  const [searchField, setSearchField] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    setSearchField(params?.filter || "");
    setSearchText(params?.search || "");
  }, [params]);

  const filtering = () => {
    return (
      <div className="row">
        <div className="col col-2">
          <Select
            style={{ width: "100%" }}
            onChange={(value: string) => {
              console.log("value:", value);
              setSearchField(value);
            }}
            value={searchField}
          >
            <Option value={"media"}>Media Id</Option>
            <Option value={"account"}>Account Id</Option>
            <Option value={"user"}>User Id</Option>
          </Select>
        </div>
        <div className="col col-6">
          <Input
            onChange={(e) => {
              if (e?.target?.value.includes("/videos/")) {
                setSearchField("media");
                setSearchText(
                  e?.target?.value.substring(
                    e?.target?.value.indexOf("/videos/") + "/videos/".length,
                    e?.target?.value.length
                  )
                );
              } else {
                setSearchText(e?.target?.value);
              }
            }}
            value={searchText}
          ></Input>
        </div>
        <div className="col col-4">
          <Button
            onClick={() => {
              if (searchText !== "" && searchField !== "") {
                onSearch(searchField, searchText.trim());
              }
            }}
            style={{ marginRight: "8px" }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              setSearchField("");
              setSearchText("");
              onResetSearch();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      {filtering()}

      <hr className="rounded tw-my-2" />

      <NewTable
        columns={[
          {
            label: "ID",
            id: "id",
            width: "130px"
          },
          {
            label: "Account Id",
            id: "accountId",
            width: "130px"
          },
          {
            label: "Name",
            id: "name",
            width: "250px"
          },
          {
            label: "Status",
            id: "status",
            width: "1fr"
          },
          {
            label: "Size",
            id: "size",
            width: "1fr"
          },
          {
            label: "Duration",
            id: "duration",
            width: "1fr"
          },
          {
            label: "Created At",
            id: "createdAt",
            width: "1fr"
          },
          {
            label: "Updated At",
            id: "updatedAt",
            width: "1fr"
          },
          {
            label: "Burnt At",
            id: "burntAt",
            width: "1fr"
          },
          {
            label: "Download At",
            id: "downloadAt",
            width: "1fr"
          },
          {
            label: "Created By",
            id: "userId",
            width: "130px"
          }
        ]}
        items={mediaList || []}
        className="tw-flex tw-min-w-min tw-flex-col tw-overflow-auto"
      >
        <NewTable.Header>
          {(cols) =>
            cols.map((col, i) => {
              const isFirst = i === 0;
              const isLast = cols.length - 1 === i;

              return (
                <NewTable.HeaderCell
                  key={col.id}
                  className={classNames(" !tw-pr-2 tw-font-medium", {
                    "!tw-pl-3": isFirst,
                    "!tw-pl-2": !isFirst,
                    "!tw-pr-3": isLast,
                    "!tw-pr-2": !isLast
                  })}
                >
                  {col.label}
                </NewTable.HeaderCell>
              );
            })
          }
        </NewTable.Header>
        {(mediaList || []).map((item, i) => (
          <Row key={i} item={item} onRowClick={() => onSelect(item.mediaId)} />
        ))}
      </NewTable>
    </>
  );
};

interface RowInnerProps {
  item: MediaListItem;
  onRowClick: (item: MediaListItem) => void;
}
const Row: React.FC<RowInnerProps> = ({ item, onRowClick }) => {
  const [isHovered, setHovered] = React.useState(false);

  const sharedClasses = React.useMemo(() => {
    let classes = "tw-min-h-12 tw-cursor-pointer";
    if (isHovered) classes += " tw-bg-neutral-50 tw-cursor-pointer";
    return classes;
  }, [isHovered]);

  const onClickIdCell = () => {
    navigator.clipboard.writeText(item.mediaId);
    toast.success("ID copied to clipboard");
  };

  const onClickAccountIdCell = () => {
    navigator.clipboard.writeText(item.accountId);
    toast.success("Account ID copied to clipboard");
  };

  const onClickCreatedByIdCell = () => {
    navigator.clipboard.writeText(item.createdBy);
    toast.success("User ID copied to clipboard");
  };

  const onClickCell = () => {
    onRowClick(item);
  };

  return (
    <>
      <NewTable.Cell
        className={classNames(
          sharedClasses,
          "!tw-pl-3 tw-overflow-hidden hover:tw-text-neutral-400"
        )}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickIdCell}
      >
        {item.mediaId}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(
          sharedClasses,
          "tw-overflow-hidden hover:tw-text-neutral-400"
        )}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickAccountIdCell}
      >
        {item.accountId}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses, "tw-overflow-hidden")}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        <Tooltip
          title={`${item.extension} [${item.width}x${item.height}] ${item.language}`}
        >
          {item.name}
        </Tooltip>
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        {(item as any).status}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        <Tooltip title={item.sizeBytes + " bytes"}>
          {bytesToHuman(item.sizeBytes)}
        </Tooltip>
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        <Tooltip title={item.durationSeconds}>
          {`${durationToString(
            Duration.fromObject({ seconds: item.durationSeconds }).shiftTo(
              "hours",
              "minutes",
              "seconds"
            )
          )}`}
        </Tooltip>
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        {formatDateTime(item.createdAt)}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        {DateTime.fromJSDate(new Date(item.updatedAt)).toRelative()}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        {item.burnedAt
          ? DateTime.fromJSDate(new Date(item.burnedAt)).toRelative()
          : "-"}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        {item.downloadedAt
          ? DateTime.fromJSDate(new Date(item.downloadedAt)).toRelative()
          : "-"}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(
          sharedClasses,
          "tw-overflow-hidden hover:tw-text-neutral-400"
        )}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCreatedByIdCell}
      >
        {item.createdBy}
      </NewTable.Cell>
    </>
  );
};
