import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { fetchActiveUsersGraphData } from "../../../api/stats.service";
import { ActiveUsersGraphData } from "../../../interfaces/stats";
import { dashboardQuery } from "../../../state/dashboard/dashboard.query";
import { StatsCard } from "../../stats-card/stats-card";
import { InputNumber } from "antd";
import { dashboardStore } from "../../../state/dashboard/dashboard.store";

interface ActiveUsersChartProps {
  notifyOfActiveUsersChartStatus: (isEnabled: boolean) => void;
}

export const ActiveUsersChart: React.FC<ActiveUsersChartProps> = ({
  notifyOfActiveUsersChartStatus
}) => {
  const [stats, setStats] = useState<ActiveUsersGraphData>();

  const loadData = (isEnabled: boolean) => {
    if (!isEnabled) {
      setStats({
        labels: [],
        activeUserCount: [],
        inactiveUserCount: []
      });
      notifyOfActiveUsersChartStatus(false);
      return;
    }

    const unsubcribe$ = new Subject();

    loadStats();

    dashboardQuery
      .select("activeUsersGraphStats")
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ stats, loading }) => {
        if (loading) {
          setStats(undefined);
        } else {
          setStats(stats);
        }
      });

    notifyOfActiveUsersChartStatus(true);

    return () => unsubcribe$.next();
  };

  const loadStats = () => {
    fetchActiveUsersGraphData(
      dashboardQuery.filterStartDate,
      dashboardQuery.filterEndDate,
      dashboardQuery.activeDays
    );
  };

  return (
    <StatsCard
      title="Active/Inactive Users"
      subtitle={`Uploaded a video in the last ${dashboardQuery.activeDays} days`}
      loadData={loadData}
    >
      <div className="row mb-0">
        <InputNumber
          min={1}
          max={365}
          defaultValue={30}
          size={"small"}
          onChange={(days: number | undefined) => {
            if (days && dashboardQuery.activeDays !== days) {
              dashboardStore.setActiveDays(days);
              loadStats();
            }
          }}
        />
      </div>
      <div className="row mb-0">
        <Line
          data={{
            labels: stats?.labels ?? [],
            datasets: [
              {
                label: "Active Users",
                lineTension: 0.1,
                data: stats?.activeUserCount ?? [],
                borderColor: "#39efa5",
                fill: false
              },
              {
                label: "Inactive Users",
                lineTension: 0.1,
                data: stats?.inactiveUserCount ?? [],
                borderColor: "#FF5714",
                fill: false
              }
            ]
          }}
          options={{
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true
                  }
                }
              ]
            }
          }}
        />
      </div>
    </StatsCard>
  );
};
