import { notification } from "antd";
import axios from "axios";
import queryString from "query-string";
import config from "../config";
import { orderQuery } from "../state/order/order.query";
import { getAccessToken } from "./auth.service";
import { OrderListParamsState, orderStore } from "../state/order/order.store";
import { Order } from "@getsubly/common";

const baseURL = `${config.apiUrl}/api/v1`;
const adminBaseURL = `${baseURL}/admin`;

export enum FileType {
  Video = "VIDEO",
  Audio = "AUDIO",
  Image = "IMAGE",
  Thumbnail = "THUMBNAIL",
  Transcript = "TRANSCRIPT",
  Waveform = "WAVEFORM",
  TXT = "TXT",
  SRT = "SRT",
  TTML = "TTML",
  XML = "XML",
  VTT = "VTT",
  ASS = "ASS",
  DOCX = "DOCX",
  Subtitle = "SUBTITLE",
  Transcription = "TRANSCRIPTION",
  Font = "FONT"
}

export interface IFileMetadata {
  burnt?: boolean;
  burnHash?: string;
  downloadName?: string;
  overridden?: boolean;

  height?: number;
  width?: number;
  language?: string;
  translated?: boolean;
  translatedCharCount?: number;
  uploaded?: boolean;
  sampleAspectRatio?: string;
  displayAspectRatio?: string;
  bitRate?: number;
  colorSpace?: string;
  colorPrimaries?: string;
  colorTransfer?: string;
  duplicated?: boolean;

  isAudioDescription?: boolean;
}

export interface OrderFile {
  id: string;
  key: string;
  url: string;
  filename: string;
  original_filename: string;
  extension: string;
  type: FileType;
  sizeBytes: number;
  uploadedDate: string;
  durationSeconds?: number;
  burnt?: boolean;
  burnHash?: string;
  width?: number;
  height?: number;
  language?: string;
  translated?: boolean;
  bucket?: string;
  noSound?: boolean;
  displayAspectRatio?: string;
  sampleAspectRatio?: string;
  bitRate?: number;
  colorSpace?: string;
  colorPrimaries?: string;
  colorTransfer?: string;
  downloadName?: string;
  metadata?: IFileMetadata;
}

export const fetchOrderList = async () => {
  try {
    const params = orderQuery.orderListParams;

    orderStore.updateList({
      loading: true,
      orderList: []
    });

    const query = buildQueryString(params);

    const { data } = await axios.get<{
      message: string;
      orders: Order[];
    }>(`${adminBaseURL}/orders?${query}`, {
      headers: {
        "x-access-token": await getAccessToken()
      }
    });

    orderStore.updateList({
      loading: false,
      orderList: data.orders
    });
  } catch (error) {
    handleError(error);
  } finally {
    orderStore.updateList({
      loading: false
    });
  }
};

export const fetchOrder = async (orderId: string) => {
  try {
    orderStore.updateDetails({
      loading: true,
      orderDetails: undefined,
      orderFiles: []
    });
    const { data } = await axios.get<{
      message: string;
      order: Order;
      files: OrderFile[];
    }>(`${adminBaseURL}/orders/${orderId}`, {
      headers: {
        "x-access-token": await getAccessToken()
      }
    });

    orderStore.updateDetails({
      loading: false,
      orderDetails: data.order,
      orderFiles: data.files
    });
  } catch (error) {
    handleError(error);
    orderStore.updateDetails({
      loading: false
    });
  }
};

export const acceptOrder = async (orderId: string) => {
  try {
    orderStore.updateDetails({
      loading: true,
      orderDetails: undefined
    });
    const { data } = await axios.put<{
      message: string;
      order: Order;
    }>(
      `${adminBaseURL}/orders/${orderId}/accept`,
      {},
      { headers: { "x-access-token": await getAccessToken() } }
    );

    orderStore.updateDetails({
      loading: false,
      orderDetails: data.order
    });
  } catch (error) {
    handleError(error);
    orderStore.updateDetails({
      loading: false
    });
  }
};

export const rejectOrder = async (orderId: string) => {
  try {
    orderStore.updateDetails({
      loading: true,
      orderDetails: undefined
    });
    const { data } = await axios.put<{
      message: string;
      order: Order;
    }>(
      `${adminBaseURL}/orders/${orderId}/reject`,
      {},
      { headers: { "x-access-token": await getAccessToken() } }
    );

    orderStore.updateDetails({
      loading: false,
      orderDetails: data.order
    });
  } catch (error) {
    handleError(error);
    orderStore.updateDetails({
      loading: false
    });
  }
};

export const completeOrder = async (orderId: string) => {
  try {
    orderStore.updateDetails({ loading: true, orderDetails: undefined });

    const { data } = await axios.put<{
      message: string;
      order: Order;
    }>(
      `${adminBaseURL}/orders/${orderId}/complete`,
      {},
      { headers: { "x-access-token": await getAccessToken() } }
    );

    orderStore.updateDetails({
      loading: false,
      orderDetails: data.order
    });
  } catch (error) {
    handleError(error);
    orderStore.updateDetails({
      loading: false
    });
  }
};

// Private functions

const handleError = (e: any) => {
  console.error(e);

  notification.error({
    message: "Something went wrong",
    description: e.message ?? e,
    duration: 10
  });
};

const buildQueryString = (params: OrderListParamsState) => {
  let queryStringBuilder = {};

  if (params?.filter && params?.search) {
    queryStringBuilder = {
      ...queryStringBuilder,
      filter: params.filter,
      search: params.search
    };
  }

  return queryString.stringify(queryStringBuilder);
};
