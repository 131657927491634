import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { formatDateTime } from "../../../utils/date-formatter";
import { JobsModal } from "../jobs-modal/jobs-modal";
import { JobListItem } from "../../../api/job.service";
import { ModalContext, ModalType } from "../../../contexts/modal.context";

interface JobsTableProps {
  loading: boolean;
  jobList?: JobListItem[];
}
export const JobsTable: React.FC<JobsTableProps> = ({ loading, jobList }) => {
  const { showModal, hideModal } = React.useContext(ModalContext);
  const [jobId, setJobId] = useState<string>("");

  useEffect(() => {
    if (jobId !== "") {
      showModal(
        ModalType.Jobs,
        <JobsModal
          jobId={jobId}
          onClose={() => {
            setJobId("");
            hideModal(ModalType.Jobs);
          }}
        />
      );
    }
  }, [hideModal, jobId, showModal]);

  return (
    <>
      <Table
        size="small"
        bordered
        loading={loading}
        dataSource={jobList}
        pagination={{ pageSize: 50 }}
        rowKey="id"
        onRowClick={(record: JobListItem, index: number, event: Event) => {
          console.log(`Open - ${record.id} - ${index}`);
          setJobId(record.id);
        }}
        columns={[
          {
            title: "ID",
            dataIndex: "id",
            key: "id"
          },
          {
            title: "Media Id",
            dataIndex: "mediaId"
          },
          {
            title: "Type",
            dataIndex: "type",
            filters: [
              {
                text: "Upload",
                value: "UPLOAD"
              },
              {
                text: "Transcribe",
                value: "TRANSCRIBE"
              },
              {
                text: "Burn",
                value: "BURN"
              }
            ],
            onFilter: (value: string, record: JobListItem) =>
              record.type.indexOf(value) === 0
          },
          {
            title: "Status",
            dataIndex: "status",
            filters: [
              {
                text: "Failed",
                value: "FAILED"
              },
              {
                text: "Converting",
                value: "CONVERTING"
              },
              {
                text: "Pending",
                value: "PENDING"
              },
              {
                text: "Uploaded",
                value: "UPLOADED"
              },
              {
                text: "Ready",
                value: "READY"
              },
              {
                text: "Complete",
                value: "COMPLETE"
              }
            ],
            onFilter: (value: string, record: JobListItem) =>
              record.status.indexOf(value) === 0
          },
          {
            title: "Start Date",
            dataIndex: "startDate",
            sorter: (a: JobListItem, b: JobListItem) =>
              a.startDate > b.startDate ? 1 : 0,
            render: (value) => {
              return formatDateTime(value);
            }
          },
          {
            title: "End Date",
            dataIndex: "endDate",
            render: (value) => {
              return formatDateTime(value);
            }
          },
          {
            title: "Message",
            dataIndex: "message"
          }
        ]}
      />
    </>
  );
};
