import { Alert } from "antd";
import React, { useState } from "react";
import Logo from "../../../assets/images/logo-white.svg";
import settings from "../../../config/settings/settings";
import * as ROUTES from "../../../routes";
import { getRandomInt } from "../../../utils";
import { Aside } from "../../aside/aside";
import "./auth-wrapper.scss";

export enum AuthWrapperType {
  SignIn = "SignIn",
  SignOut = "SignOut",
  SignUp = "SignUp",
  OAuth = "OAuth"
}
interface AuthWrapperProps {
  type?: AuthWrapperType;
  useAlert?: boolean;
  alertMessage?: string;
  handleClose?: () => void;
}
export const AuthWrapper: React.FC<AuthWrapperProps> = ({
  type,
  useAlert,
  alertMessage,
  handleClose,
  children
}) => {
  const [selectedInfo] = useState(settings.customers[getRandomInt(3)]);

  const title = () => {
    switch (type) {
      case AuthWrapperType.SignIn:
        return "Sign in to Subly";
      case AuthWrapperType.SignOut:
        return "See you soon.";
      case AuthWrapperType.SignUp:
        return "Get an account to start creating your next video with subtitles!";
      case AuthWrapperType.OAuth:
        return "Wait a moment...";

      default:
        return "";
    }
  };

  return (
    <div className="app" id="auth-wrapper">
      <a href={ROUTES.WEBSITE_URL} target="_blank" rel="noopener noreferrer">
        <img
          src={Logo}
          className="img-responsive logo"
          style={{ position: "fixed" }}
          alt="Subly"
        />
      </a>
      <div className="auth-aside">
        <Aside {...selectedInfo} />
      </div>
      <div className="card card-wrapper">
        <div className="card-body card-inner">
          <div className="p-15">
            <div className="auth-logo">
              <h2 className="m-v-0 p-t-40">{title()}</h2>
              {useAlert && (
                <Alert
                  style={{ margin: "1rem 0 0.5rem" }}
                  message="Something went wrong!"
                  description={alertMessage}
                  type="error"
                  closable
                  onClose={handleClose}
                />
              )}
            </div>
            {type === AuthWrapperType.SignIn && (
              <p className="m-t-15 m-b-30 font-size-13"></p>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
