import { Query } from "@datorama/akita";
import {
  mediaStore,
  MediaStore,
  MediaState,
  MediaListState,
  MediaDetailsState,
  MediaListParamsState
} from "./media.store";

export class MediaQuery extends Query<MediaState> {
  get list(): MediaListState {
    return this.getValue().list;
  }

  get details(): MediaDetailsState {
    return this.getValue().details;
  }

  get mediaListParams(): MediaListParamsState {
    return this.getValue().params;
  }

  constructor(protected store: MediaStore) {
    super(store);
  }
}

export const mediaQuery = new MediaQuery(mediaStore);
