import { CookieConsentsProvider } from "@enzsft/react-cookie-consents";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  withRouter
} from "react-router-dom";
import Dashboard from "./components/dashboard/dashboard";
import Users from "./components/users/users";
import Media from "./components/media/media";
import { OAuthCallback } from "./components/auth/oauth-callback/oauth-callback";
import SignIn from "./components/auth/sign-in/sign-in";
import { CookieBanner } from "./components/cookieBanner/cookie-banner";
import Page404 from "./components/general/page404";
import config from "./config";
import { AuthContext, AuthProvider } from "./contexts/auth.context";
import { SettingsProvider } from "./contexts/settings.context";
// Routes
import * as ROUTES from "./routes";
import Jobs from "./components/jobs/jobs";
import Accounts from "./components/accounts/accounts";
import Orders from "./components/orders/orders";
import ApiAccounts from "./components/api-accounts/api-accounts";
import { ApiAccountDetails } from "./components/api-accounts/api-account-details";
import BusinessAccounts from "./components/business-accounts/business-accounts";
import Deals from "./components/deals/deals";
import { ModalProvider } from "./contexts/modal.context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = config.stripe.clientKey
  ? loadStripe(config.stripe.clientKey)
  : null;

const SecuredRoute = (props: any) => {
  const { component: Component, ...rest } = props;
  const { isAuthenticated } = useContext(AuthContext);

  if (!isAuthenticated()) {
    let redirect = `${window.location.pathname}${window.location.search}`;

    if (Boolean(redirect) && redirect !== "/") {
      redirect = "?redirect=" + encodeURIComponent(redirect);
    }

    return <Redirect to={ROUTES.LOGIN_PATH + redirect} />;
  }

  return (
    <Route {...rest}>
      <Component {...props} />
    </Route>
  );
};

const App = () => (
  <CookieConsentsProvider cookieName="cookieConsents" expiryInDays={365}>
    <Elements stripe={stripePromise}>
      <Router>
        <AuthProvider>
          <SettingsProvider>
            <ModalProvider>
              <Switch>
                <SecuredRoute
                  path={ROUTES.LANDING}
                  exact
                  component={Dashboard}
                />
                <SecuredRoute
                  path={ROUTES.USERS_ID_PATH}
                  exact
                  component={Users}
                />
                <SecuredRoute
                  path={ROUTES.USERS_PATH}
                  exact
                  component={Users}
                />
                <SecuredRoute
                  path={ROUTES.ACCOUNTS_PATH}
                  exact
                  component={Accounts}
                />
                <SecuredRoute
                  path={ROUTES.ORDERS_PATH}
                  exact
                  component={Orders}
                />
                <SecuredRoute
                  path={ROUTES.ACCOUNTS_ID_PATH}
                  exact
                  component={Accounts}
                />
                <SecuredRoute
                  path={ROUTES.MEDIA_PATH}
                  exact
                  component={Media}
                />
                <SecuredRoute
                  path={ROUTES.MEDIA_ID_PATH}
                  exact
                  component={Media}
                />
                <SecuredRoute path={ROUTES.JOBS_PATH} exact component={Jobs} />
                <SecuredRoute
                  path={ROUTES.BUSINESS_ACCOUNTS_PATH}
                  exact
                  component={BusinessAccounts}
                />
                <SecuredRoute
                  path={ROUTES.BUSINESS_ACCOUNTS_ID_PATH}
                  exact
                  component={BusinessAccounts}
                />
                <SecuredRoute
                  path={ROUTES.DEALS_PATH}
                  exact
                  component={Deals}
                />
                <SecuredRoute
                  path={ROUTES.DEALS_ID_PATH}
                  exact
                  component={Deals}
                />
                <SecuredRoute
                  path={ROUTES.API_ACCOUNTS_PATH}
                  exact
                  component={ApiAccounts}
                />
                <SecuredRoute
                  path={ROUTES.API_ACCOUNTS_ID_PATH}
                  exact
                  component={ApiAccountDetails}
                />

                <Route path={ROUTES.LOGIN_PATH} component={SignIn} />

                <Route
                  path={ROUTES.OAUTH_CALLBACK_PATH}
                  component={OAuthCallback}
                />

                <Route component={withRouter(Page404)} />
              </Switch>
            </ModalProvider>
          </SettingsProvider>
        </AuthProvider>
      </Router>
      <ToastContainer />
      <CookieBanner />
    </Elements>
  </CookieConsentsProvider>
);

export default App;
