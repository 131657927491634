import axios from "axios";
import config from "../config";
import { getAccessToken } from "./auth.service";
import { notification } from "antd";
import { EN } from "../assets/i18n/en";
import { jobsStore } from "../state/jobs/jobs.store";
import { DateTime } from "luxon";

const baseURL = `${config.apiUrl}/api/v1/admin`;

// Users
interface JobListResponse {
  message: string;
  count: number;
  jobList: JobListItem[];
}
export interface JobListItem {
  id: string;
  mediaId: string;
  type: string;
  status: string;
  startDate: DateTime;
  endDate?: DateTime;
  message: string;
}

export const fetchJobList =
  async () /* (take: number, skip: number, type?: string, status?: string) */ => {
    try {
      jobsStore.updateList({
        loading: true,
        jobList: []
      });

      const { data } = await axios.get<JobListResponse>(
        `${baseURL}/jobs`, // ?take=${take}&skip=${skip}&type=${type}&status=${status}`,
        {
          headers: {
            "x-access-token": await getAccessToken()
          }
        }
      );

      jobsStore.updateList({
        loading: false,
        jobList: data.jobList
      });
    } catch (error) {
      handleError(error);
    } finally {
      jobsStore.updateList({
        loading: false
      });
    }
  };

interface JobDetailsResponse {
  message: string;
  job: JobDetails;
}

export interface JobDetails {
  id: string;
  mediaId: string;
  accountId: string;
  media: string;
  type: string;
  status: string;
  startDate: DateTime;
  endDate?: DateTime;
  message: string;
  inputs: JobDetailsFile[];
  outputs: JobDetailsFile[];
}

export interface JobDetailsFile {
  fileId: string;
  type: string;
  filename: string;
  extension: string;
  s3Uri: string;
  sizeBytes: number;
  durationSeconds?: number;
  metadata: any;
  uploadedAt: DateTime;
}

export const fetchJobDetails = async (jobId: string) => {
  try {
    jobsStore.updateDetails({
      loading: true
    });
    const { data } = await axios.get<JobDetailsResponse>(
      `${baseURL}/jobs/${jobId}`,
      {
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );
    jobsStore.updateDetails({
      loading: false,
      jobDetails: data.job
    });
  } catch (error) {
    handleError(error);
  } finally {
    jobsStore.updateDetails({
      loading: false
    });
  }
};

// Private functions

const handleError = (e: any) => {
  console.error(e);

  notification.error({
    message: "Something went wrong",
    description: e?.message || EN.error.defaultMessage,
    duration: 10
  });
};
