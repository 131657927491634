import { Button, Spin, Tabs, notification } from "antd";
import axios from "axios";
import produce from "immer";
import React, { useEffect, useState } from "react";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { getAccessToken } from "../../../api/auth.service";
import {
  fetchUserDetails,
  updateCognitoAttribute,
  UserDetails
} from "../../../api/user.service";
import config from "../../../config";
import { ModalContext, ModalType } from "../../../contexts/modal.context";
import { usersQuery } from "../../../state/users/users.query";
import cognitoAttributeNames from "../../../utils/cognitoAttributeNames";
import { Modal } from "../../modal/modal";
import { UserCognitoDetails } from "../users-details/cognito-details";
import { UserDatabaseDetails } from "../users-details/database-details";
import { UserHubspotDetails } from "../users-details/hubspot-details";
import { ConvertUserModal } from "./convert-user";
import { DeleteUser } from "./delete-user";
import { ResetUserPassword } from "./reset-user-password";
import styles from "./users-modal.module.scss";
import { EN } from "../../../assets/i18n/en";

const { TabPane } = Tabs;
const baseURL = `${config.apiUrl}/api/v1/admin/users`;

interface UsersModalProps {
  username?: string;
  onClose: () => void;
}
export const UsersModal: React.FC<UsersModalProps> = ({
  username,
  onClose
}) => {
  const title = `User details (${username})`;

  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState<UserDetails>();
  const [startReset, setStartReset] = useState(false);
  const [loadingLink, setLoadingLink] = useState(false);
  const { showModal, hideModal } = React.useContext(ModalContext);

  useEffect(() => {
    if (!username) {
      return;
    }

    fetchUserDetails(username);

    const unsubcribe$ = new Subject();
    usersQuery
      .select()
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ details }) => {
        setLoading(details.loading);
        setUserDetails(details.userDetails);
      });

    return () => unsubcribe$.next();
  }, [username]);

  const setUserEducation = async (value: boolean, username?: string) => {
    const endpointName = Boolean(value) ? "add-education" : "remove-education";

    const result = await axios({
      url: `${baseURL}/${username}/${endpointName}`,
      method: "put",
      headers: {
        "x-access-token": await getAccessToken()
      }
    });

    if (result.status !== 200) {
      throw new Error(`setUserEducation Failed with status ${result.status}`);
    }

    const newCustomSettingsValue = JSON.stringify(result.data.settings);

    const updatedUserDetails = produce(userDetails, (draft) => {
      const attribute =
        draft &&
        draft.cognito.attributes.find(
          (x) => x.name === cognitoAttributeNames.customSettings
        );

      if (attribute !== undefined) {
        attribute.value = newCustomSettingsValue;
      }
    });

    setUserDetails(updatedUserDetails);
  };

  const setCognitoAttribute = async (name: string, value: string) => {
    if (!username) {
      return;
    }
    if (name === "email") {
      value = value.toLowerCase();
    }
    await updateCognitoAttribute(username, name, value);

    //we're changing state at a deep level - won't cause a rerender unless we pass a different state object
    const updatedUserDetails = produce(userDetails, (draft) => {
      const attribute =
        draft && draft.cognito.attributes.find((x) => x.name === name);

      if (attribute !== undefined) {
        attribute.value = value;
      }
    });

    setUserDetails(updatedUserDetails);
  };

  const onGenerateMagicLink = async () => {
    try {
      setLoadingLink(true);
      const url = `${config.apiUrl}/api/v1/admin/users/${username}/generate-magic-link`;

      const res = await axios.put(
        url,
        { email: userDetails?.database?.email },
        { headers: { "x-access-token": await getAccessToken() } }
      );
      const magicLink = res.data.magicLink;
      navigator.clipboard.writeText(magicLink);
      notification.info({
        message: `Magic link copied to clipboard`,
        duration: 10
      });
    } catch (err) {
      notification.error({
        message: "Something went wrong",
        description: (err as any)?.message || EN.error.defaultMessage,
        duration: 5
      });
    } finally {
      setLoadingLink(false);
    }
  };

  return (
    <Modal onDismiss={onClose} className={styles["modal"]} hasCloseIcon>
      <h5 className="mb-3">{title}</h5>
      {loading ? (
        <Spin />
      ) : userDetails ? (
        <Tabs defaultActiveKey="1">
          <TabPane tab="Cognito" key="1">
            <UserCognitoDetails
              details={userDetails.cognito}
              setCognitoAttribute={setCognitoAttribute}
              setUserEducation={setUserEducation}
            />
          </TabPane>
          <TabPane tab="Database" key="2">
            <UserDatabaseDetails details={userDetails.database} />
          </TabPane>
          <TabPane tab="Hubspot" key="3" disabled={!userDetails.hubspot}>
            <UserHubspotDetails details={userDetails.hubspot} />
          </TabPane>
        </Tabs>
      ) : (
        "Details missing"
      )}
      <div className="d-flex align-items-center justify-content-end mt-5">
        {startReset && (
          <ResetUserPassword
            username={username}
            onCancel={() => setStartReset(false)}
          />
        )}
        {!startReset && (
          <>
            {userDetails?.cognito.status === "EXTERNAL_PROVIDER" && (
              <Button
                type="primary"
                className="btn btn-primary"
                disabled={loading}
                onClick={() =>
                  showModal(
                    ModalType.ConvertUser,
                    <ConvertUserModal
                      userDetails={userDetails}
                      closeModal={() => {
                        hideModal(ModalType.ConvertUser);
                        onClose();
                      }}
                    />
                  )
                }
              >
                Convert
              </Button>
            )}
            <DeleteUser
              username={username}
              onDeleted={() => onClose()}
              loading={loading}
            />
            <Button
              onClick={() => setStartReset(!startReset)}
              disabled={loading}
              className="btn btn-outline-secondary"
            >
              Set new password
            </Button>
            <Button
              onClick={onGenerateMagicLink}
              disabled={loading || loadingLink}
              className="btn btn-outline-secondary"
            >
              Generate Magic Link
            </Button>
          </>
        )}
        <Button
          type="primary"
          className="btn btn-primary"
          loading={loading}
          onClick={() => onClose()}
        >
          OK
        </Button>
      </div>
    </Modal>
  );
};
