import React from "react";
import { UserDetailsHubspot } from "../../../api/user.service";

interface UserHubspotDetailsProps {
  details: UserDetailsHubspot;
}

export const UserHubspotDetails: React.FC<UserHubspotDetailsProps> = ({
  details
}) => {
  return (
    <>
      <a href={details.url} target="_blank" rel="noopener noreferrer">
        {details.vid}
      </a>
      {details.properties.map((p) => (
        <p>
          {p.name}: {p.value}
        </p>
      ))}
    </>
  );
};
