import { Button } from "antd";
import React from "react";
import styles from "./convert-user.module.scss";
import { Modal } from "../../modal/modal";
import { ConvertGoogleUser, UserDetails } from "../../../api/user.service";
import cx from "classnames";
import { useForm } from "react-hook-form";
import { emailPattern } from "../../../utils/regex-patterns";

interface ConvertUserProps {
  userDetails?: UserDetails;
  closeModal: () => void;
}
export interface ConvertUserForm {
  email: string;
}

export const ConvertUserModal: React.FC<ConvertUserProps> = ({
  userDetails,
  closeModal
}) => {
  const { register, handleSubmit, errors, getValues } =
    useForm<ConvertUserForm>();

  const convertSameEmail = () => {
    ConvertGoogleUser(
      userDetails?.database.username,
      userDetails?.database.email
    );
    closeModal();
  };

  const convertDifferentEmail = ({ email }: ConvertUserForm) => {
    ConvertGoogleUser(userDetails?.database.username, email);
    closeModal();
  };
  const state = getValues() as unknown as ConvertUserForm;

  return (
    <>
      <Modal onDismiss={closeModal} className={styles["modal"]} hasCloseIcon>
        <h2 className="text-center mb-4">Convert User</h2>
        <div className="container">
          <div className="container">
            <div className="d-flex justify-content-between">
              <Button
                className="d-block flex-fill btn-primary"
                onClick={convertSameEmail}
              >
                Convert using same Email Address: {userDetails?.database.email}
              </Button>
            </div>
          </div>

          <div className="d-flex justify-content-center my-3">
            <h4>OR</h4>
          </div>

          <div className="container">
            <div className="col">
              <form onSubmit={handleSubmit(convertDifferentEmail)}>
                <div className="form-container container">
                  <label htmlFor="email">Convert to new Email Address</label>
                  <div className="row">
                    <div className="col">
                      <input
                        className={cx("form-control", {
                          "is-invalid": errors.email
                        })}
                        type="email"
                        id="email"
                        name="email"
                        ref={register({
                          required: true,
                          pattern: emailPattern
                        })}
                        defaultValue={state?.email}
                      />
                    </div>
                    <div className="col">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        //style={{ width: 200 }}
                      >
                        Convert using new Email
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
