import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import { fetchJobDetails, JobDetails } from "../../../api/job.service";
import { Subject } from "rxjs";
import { jobsQuery } from "../../../state/jobs/jobs.query";
import { takeUntil } from "rxjs/operators";
import { JobDetailsComponent } from "../job-details/jobs-details";
import { Modal } from "../../modal/modal";
import styles from "./jobs-modal.module.scss";

interface JobsModalProps {
  onClose: () => void;
  jobId?: string;
}
export const JobsModal: React.FC<JobsModalProps> = ({ jobId, onClose }) => {
  const title = `Job details (${jobId})`;

  const [loading, setLoading] = useState(false);
  const [jobDetails, setJobDetails] = useState<JobDetails>();

  useEffect(() => {
    if (!jobId) {
      return;
    }

    fetchJobDetails(jobId);

    const unsubcribe$ = new Subject();
    jobsQuery
      .select()
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ details }) => {
        setLoading(details.loading);
        setJobDetails(details.jobDetails);
      });

    return () => unsubcribe$.next();
  }, [jobId]);

  return (
    <Modal onDismiss={onClose} className={styles["modal"]} hasCloseIcon>
      <h5 className="mb-3">{title}</h5>
      {loading ? <Spin /> : <JobDetailsComponent details={jobDetails} />}
    </Modal>
  );
};
