export const parseObjectToHtmlText = (
  obj?: Record<string, any>,
  multiline = true
): string => {
  if (!obj) {
    return "";
  }

  return Object.entries(obj)
    .map(([key, value]) => {
      if (typeof value === "object") {
        return `${key}: ${parseObjectToHtmlText(value)}`;
      }

      return `${key}: ${value}`;
    })
    .join(multiline ? "<br/>" : ", ");
};
