import React from "react";
import { Table } from "antd";
import { Invitation } from "../../../interfaces/member";
import { formatDate } from "../../../utils/date-formatter";
import { Button } from "antd/lib/radio";
import { ModalContext, ModalType } from "../../../contexts/modal.context";
import { NewBusinessAccountInviteModal } from "../new-business-account-modal/new-business-account-invite-modal";
import { TrashCanIcon } from "../../icons/icons";
import { DeleteInvitation } from "../../../api/account.service";

interface AccountInviteListProps {
  accountId: string | undefined;
  invitations?: Invitation[];
  loading: boolean;
}

export const AccountInviteList: React.FC<AccountInviteListProps> = ({
  accountId,
  invitations,
  loading
}) => {
  const { showModal, hideModal } = React.useContext(ModalContext);

  if (loading || !accountId) {
    return <span>Loading Invitations</span>;
  }

  const DestroyInvitation = (inviteId: string) => {
    DeleteInvitation(accountId, inviteId);
  };

  const NewInvite = () => {
    showModal(
      ModalType.NewBusinessAccountInvite,
      <NewBusinessAccountInviteModal
        onClose={() => hideModal(ModalType.NewBusinessAccountInvite)}
        accountName={accountId}
      />
    );
  };

  return (
    <>
      <div>
        <div>
          {" "}
          <p>Invitations sent</p>
          <Table
            size="small"
            bordered
            loading={loading}
            dataSource={(invitations || []).filter((d) => {
              return true;
            })}
            pagination={false}
            rowKey="id"
            columns={[
              {
                title: "Invitation ID",
                dataIndex: "id",
                key: "id"
              },
              {
                title: "Email",
                dataIndex: "email",
                key: "email"
              },
              {
                title: "Created At",
                dataIndex: "createdAt",
                key: "createdAt",
                render: (value) => {
                  return <>{formatDate(value)}</>;
                }
              },
              {
                title: "Updated At",
                dataIndex: "updatedAt",
                key: "updatedAt",
                render: (value) => {
                  return <>{formatDate(value)}</>;
                }
              },
              {
                title: "Owner?",
                dataIndex: "isOwner",
                key: "isOwner",
                render: (value) => {
                  return <>{value || false ? "YES" : "NO"}</>;
                }
              },
              {
                title: "Role",
                dataIndex: "role['name']",
                key: "role['name']"
              },
              {
                title: "Delete?",
                render: (record) => {
                  if (!record.isOwner) {
                    return <TrashCanIcon />;
                  }
                },
                onCellClick: (record) => {
                  DestroyInvitation(record.id);
                }
              }
            ]}
          />
        </div>
        <br />
        <div>
          <Button className="btn btn-primary" onClick={NewInvite}>
            New Invite
          </Button>
        </div>
      </div>
    </>
  );
};
