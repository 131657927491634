import React from "react";
import {
  mdiClockOutline,
  mdiFire,
  mdiTranscribe,
  mdiAlertCircle
} from "@mdi/js";
import { StatsCard } from "../../stats-card/stats-card";
import {
  NumberStatsCard,
  NumberStatsCardSize
} from "../../stats-card/number-stats-card/number-stats-card";
import { IApiAccountStats } from "../../../interfaces/stats";

interface ApiAccountStatsCardProps {
  stats?: IApiAccountStats;
  loading: boolean;
  error?: boolean;
}

export const ApiAccountStatsCard: React.FC<ApiAccountStatsCardProps> = ({
  stats,
  loading,
  error = false
}) => {
  return (
    <>
      <StatsCard title="Transcribe Stats">
        <div className="row">
          <NumberStatsCard
            title="Transcription Minutes"
            loading={loading}
            error={error}
            result={stats?.transcribe.totalMinutes ?? 0.0}
            icon={mdiClockOutline}
            formatOptions={{ format: "0,0.00" }}
            size={NumberStatsCardSize.IsThird}
          />
          <NumberStatsCard
            title="Num Transcriptions"
            loading={loading}
            error={error}
            result={stats?.transcribe.numTransactions ?? 0}
            icon={mdiTranscribe}
            size={NumberStatsCardSize.IsThird}
          />
          <NumberStatsCard
            title="Transcription Errors"
            loading={loading}
            error={error}
            result={stats?.transcribe.numFailed ?? 0}
            icon={mdiAlertCircle}
            size={NumberStatsCardSize.IsThird}
          />
        </div>
      </StatsCard>
      <StatsCard title="Burn Stats">
        <div className="row">
          <NumberStatsCard
            title="Burn Minutes"
            loading={loading}
            error={error}
            result={stats?.burn.totalMinutes ?? 0.0}
            icon={mdiClockOutline}
            formatOptions={{ format: "0,0.00" }}
            size={NumberStatsCardSize.IsThird}
          />
          <NumberStatsCard
            title="Num Burn Jobs"
            loading={loading}
            error={error}
            result={stats?.burn.numSuccess ?? 0}
            icon={mdiFire}
            size={NumberStatsCardSize.IsThird}
          />
          <NumberStatsCard
            title="Burn Errors"
            loading={loading}
            error={error}
            result={stats?.burn.numFailed ?? 0}
            icon={mdiAlertCircle}
            size={NumberStatsCardSize.IsThird}
          />
        </div>
      </StatsCard>
    </>
  );
};
