import React from "react";

export enum ModalType {
  NewTrial,
  Deal,
  NewDeal,
  FinaliseDeal,
  Account,
  BusinessAccount,
  NewBusinessAccount,
  NewBusinessUser,
  NewBusinessAccountInvite,
  NewBusinessAccountMember,
  Media,
  User,
  Jobs,
  SetAccountCredit,
  SetAccountTeamCapacity,
  NewMediaShare,
  DeleteAccount,
  ConvertUser,
  Order
}

interface VisibleModal {
  type: ModalType;
  component: JSX.Element;
}

interface ModalContext {
  showModal: (type: ModalType, component: JSX.Element) => void;
  hideModal: (modal: ModalType) => void;
  hideAll: () => void;
}

export const ModalContext = React.createContext<ModalContext>({
  showModal: () => null,
  hideModal: () => null,
  hideAll: () => null
});

export const ModalProvider: React.FC = ({ children }) => {
  const [visibleModals, setVisibleModals] = React.useState<VisibleModal[]>([]);

  const showModal = (type: ModalType, component: JSX.Element) => {
    const isModalVisible = visibleModals.some((m) => m.type === type);

    if (isModalVisible) {
      return;
    }

    setVisibleModals((modals) => [...modals, { type, component }]);
  };

  const hideModal = (modal: ModalType) => {
    setVisibleModals((modals) => modals.filter((m) => m.type !== modal));
  };

  const hideAll = () => {
    setVisibleModals([]);
  };

  const renderModals = visibleModals.map((m) => {
    return <React.Fragment key={m.type}>{m.component}</React.Fragment>;
  });

  return (
    <ModalContext.Provider
      value={{
        showModal,
        hideModal,
        hideAll
      }}
    >
      {children}
      {renderModals}
    </ModalContext.Provider>
  );
};
