import React from "react";
import { Alert, Button } from "antd";
import Select from "react-select";
import classNames from "classnames";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { createDeal } from "../../../api/deal.service";
import {
  Coupon,
  CreateInvoiceItemParams,
  CreateInvoiceParams,
  TaxRate
} from "../../../interfaces/deal";
import { parseSecondsToText } from "../../../utils/time";
// import { calculateInvoiceItemTotal } from "../../../utils/calc";

type SelectOptionValue = {
  value: string;
  label: string;
};

interface DealForm {
  description: string;
  teamCapacity: number;
  creditSeconds: number;
  invoice: Omit<CreateInvoiceParams, "items">;
  invoiceItems: DealFormInvoiceItem[];
  discounts: SelectOptionValue[];
  taxRates: SelectOptionValue[];
}

interface DealFormInvoiceItem
  extends Omit<CreateInvoiceItemParams, "discounts" | "taxRates"> {
  discounts?: SelectOptionValue[];
  taxRates?: SelectOptionValue[];
}

interface NewDealFormProps {
  accountId: string;
  init: {
    customer: {
      currency: string;
    };
    coupons: Coupon[];
    taxCodes: TaxRate[];
  };
  onSave: (dealId: string) => void;
}
export const NewDealForm: React.FC<NewDealFormProps> = ({
  accountId,
  init,
  onSave
}) => {
  const [alertMessage, setAlertMessage] = React.useState<string>();
  const [creating, setCreating] = React.useState<boolean>();
  const { register, handleSubmit, errors, control, watch } =
    useForm<DealForm>();
  let {
    fields: invoiceItemFields,
    append: appendInvoiceItem,
    remove: removeInvoiceItem
  } = useFieldArray<DealFormInvoiceItem>({
    name: "invoiceItems",
    control
  });

  const onSubmit = async (data: DealForm) => {
    setAlertMessage("");
    setCreating(true);

    console.log("data:", data);

    const deal = await createDeal(accountId, {
      params: {
        description: data.description,
        teamCapacity: data.teamCapacity,
        creditSeconds: data.creditSeconds
      },
      invoiceParams: {
        ...data.invoice,
        items: data.invoiceItems.map((i) => ({
          ...i,
          currency: init.customer.currency ?? "usd",
          quantity: i.quantity * 1,
          unitAmount: i.unitAmount * 100
        })),
        defaultTaxRates: data.taxRates?.map((tr) => tr.value),
        discounts: data.discounts?.map((tr) => tr.value)
      }
    });

    setCreating(false);
    setAlertMessage("");

    console.log("deal:", deal);

    if (deal?.id) {
      console.log("deal.id:", deal.id);
      onSave(deal.id);
    }
  };

  return (
    <div>
      {alertMessage && (
        <Alert
          message={alertMessage}
          closable
          onClose={() => setAlertMessage("")}
        />
      )}
      <h1 className="text-center">Create a New Deal</h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <div className="form-group col-6">
            <label htmlFor="description">Description (Internal)</label>
            <textarea
              id="description"
              placeholder="Summary of deal..."
              className={classNames("form-control", {
                "is-invalid": errors.description
              })}
              autoCapitalize="word"
              name="description"
              ref={register({ required: true })}
            />
          </div>
        </div>

        <h4>Limits</h4>
        <div className="form-row">
          <div className="form-group col-2">
            <label htmlFor="givenName">Team Capacity</label>
            <input
              id="teamCapacity"
              type="number"
              placeholder="1"
              className={classNames("form-control", {
                "is-invalid": errors.teamCapacity
              })}
              name="teamCapacity"
              ref={register({ required: true })}
            />
          </div>

          <div className="form-group col-3">
            <label htmlFor="creditSeconds">
              Total Credit ({parseSecondsToText(watch("creditSeconds"))})
            </label>
            <input
              id="creditSeconds"
              name="creditSeconds"
              type="number"
              placeholder="600000"
              className={classNames("form-control", {
                "is-invalid": errors.creditSeconds
              })}
              ref={register({ required: true })}
            />
          </div>
        </div>

        <h4>Invoice</h4>
        <fieldset key={"invoice"} name={"invoice"}>
          <div className="form-row">
            <div className="form-group col-2">
              <label htmlFor="invoice.description">Memo</label>
              <input
                id="invoice.description"
                placeholder="1"
                className={classNames("form-control", {
                  "is-invalid": errors.invoice?.description
                })}
                autoCapitalize="word"
                name="invoice.description"
                ref={register({ required: true })}
              />
            </div>
          </div>
        </fieldset>
        <h5>Invoice Items</h5>
        {invoiceItemFields.map((field, index) => {
          const fieldName = `invoiceItems[${index}]`;
          const itemErrors = errors.invoiceItems
            ? errors.invoiceItems[index]
            : undefined;
          return (
            <div key={fieldName} className="form-row">
              <div className="form-group col-3">
                <fieldset key={`${fieldName}.description`} name={fieldName}>
                  <Controller
                    as={
                      <label style={{ width: "100%" }}>
                        Description:
                        <input
                          type="text"
                          name={`${fieldName}.description`}
                          className={classNames("form-control", {
                            "is-invalid": itemErrors?.description
                          })}
                          ref={register({ required: true })}
                          defaultValue={field.description}
                        />
                      </label>
                    }
                    name={`${fieldName}.description`}
                    control={control}
                  />
                </fieldset>
              </div>
              <div className="form-group col-1">
                <fieldset key={`${fieldName}.quantity`} name={fieldName}>
                  <Controller
                    as={
                      <label style={{ width: "100%" }}>
                        Quantity:
                        <input
                          type="number"
                          name={`${fieldName}.quantity`}
                          className={classNames("form-control", {
                            "is-invalid": itemErrors?.quantity
                          })}
                          ref={register({ required: true })}
                          defaultValue={field.quantity}
                        />
                      </label>
                    }
                    name={`${fieldName}.quantity`}
                    control={control}
                  />
                </fieldset>
              </div>
              <div className="form-group col-1">
                <fieldset key={`${fieldName}.unitAmount`} name={fieldName}>
                  <Controller
                    name={`${fieldName}.unitAmount`}
                    control={control}
                    as={
                      <label style={{ width: "100%" }}>
                        Price:
                        <input
                          prefix="US$"
                          type="number"
                          min="0.00"
                          max="10000.00"
                          step="0.01"
                          name={`${fieldName}.unitAmount`}
                          className={classNames("form-control", {
                            "is-invalid": itemErrors?.unitAmount
                          })}
                          ref={register({ required: true })}
                          defaultValue={field.unitAmount}
                        />
                      </label>
                    }
                  />
                </fieldset>
                <fieldset key={`${fieldName}.currency`} name={fieldName} hidden>
                  <Controller
                    as={
                      <input
                        type="text"
                        name={`${fieldName}.currency`}
                        className={classNames("form-control", {
                          "is-invalid": itemErrors?.currency
                        })}
                        ref={register()}
                        defaultValue={init?.customer?.currency}
                      />
                    }
                    name={`${fieldName}.currency`}
                    control={control}
                  />
                </fieldset>
              </div>
              {/* <div className="form-group col-2">
                <fieldset key={fieldName} name={fieldName}>
                  <Controller
                    name={`invoiceItems[${index}].discounts`}
                    control={control}
                    as={
                      <label style={{ width: "100%" }}>
                        Discount:
                        <Select
                          name={`invoiceItems[${index}].discounts`}
                          placeholder=""
                          isSearchable={true}
                          options={init.coupons.map((c) => ({
                            value: c.id,
                            label: c.name
                          }))}
                          isMulti={true}
                          theme={(theme: any) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                              ...theme.colors,
                              primary: "var(--purple)"
                            }
                          })}
                        />
                      </label>
                    }
                  />
                </fieldset>
              </div> */}
              {/* <div className="form-group col-2">
                <fieldset key={fieldName} name={fieldName}>
                  <Controller
                    as={
                      <label>
                        Discountable:
                        <input
                          type="checkbox"
                          name={`invoiceItems[${index}].discountable`}
                          className={classNames("form-control", {
                            "is-invalid": itemErrors?.discountable
                          })}
                          ref={register()}
                        />
                      </label>
                    }
                    name={`invoiceItems[${index}].discountable`}
                    control={control}
                  />
                </fieldset>
              </div> */}
              {/* <div className="form-group col-2">
                <fieldset key={fieldName} name={fieldName}>
                  <Controller
                    name={`invoiceItems[${index}].taxRates`}
                    control={control}
                    as={
                      <label style={{ width: "100%" }}>
                        Tax Rate:
                        <Select
                          placeholder=""
                          isSearchable={true}
                          options={init.taxCodes.map((c) => ({
                            value: c.id,
                            label: `${c.name} - ${c.region} (${c.percentage}%)`
                          }))}
                          isMulti={true}
                          theme={(theme: any) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                              ...theme.colors,
                              primary: "var(--purple)"
                            }
                          })}
                        />
                      </label>
                    }
                  />
                </fieldset>
              </div> */}

              <div className="form-group col-2">
                {/* <label>
                  Total:
                </label> */}
              </div>
              <Button
                className="col-1"
                onClick={() => removeInvoiceItem(index)}
              >
                Remove
              </Button>
            </div>
          );
        })}
        <Button onClick={() => appendInvoiceItem({})}>Add Invoice Item</Button>

        <h6>Coupons</h6>

        <div className="form-row">
          <div className="form-group col-6">
            <fieldset key={"discounts"} name={"discounts"}>
              <Controller
                as={
                  <Select
                    placeholder=""
                    isSearchable={true}
                    options={init?.coupons.map((c) => ({
                      value: c.id,
                      label: c.name
                    }))}
                    isMulti={true}
                    theme={(theme: any) => ({
                      ...theme,
                      borderRadius: 4,
                      colors: {
                        ...theme.colors,
                        primary: "var(--purple)"
                      }
                    })}
                  />
                }
                name={`discounts`}
                control={control}
              />
            </fieldset>
          </div>
        </div>

        <h6>Default Tax</h6>

        <div className="form-row">
          <div className="form-group col-6">
            <fieldset key={"taxRates"} name={"taxRates"}>
              <Controller
                as={
                  <Select
                    placeholder=""
                    isSearchable={true}
                    options={init?.taxCodes.map((c) => ({
                      value: c.id,
                      label: `${c.name} - ${c.region} (${c.percentage}%)`
                    }))}
                    isMulti={true}
                    theme={(theme: any) => ({
                      ...theme,
                      borderRadius: 4,
                      colors: {
                        ...theme.colors,
                        primary: "var(--purple)"
                      }
                    })}
                  />
                }
                name={`taxRates`}
                control={control}
              />
            </fieldset>
          </div>
        </div>

        {/* <h5>Total</h5> */}

        <button
          type="submit"
          className="btn btn-primary btn-block mt-3"
          style={{ width: 120 }}
        >
          {creating ? "Creating" : "Create"}
        </button>
      </form>
    </div>
  );
};
