import { Button, notification } from "antd";
import React, { useState } from "react";
import { getAccessToken } from "../../../api/auth.service";
import { EN } from "../../../assets/i18n/en";
import config from "../../../config";
import axios from "axios";
import { USERS_PATH } from "../../../routes";
import { useHistory } from "react-router-dom";
import "./delete-user.scss";

interface DeleteUserProps {
  username?: string;
  onDeleted: () => void;
  loading: boolean;
}

export const DeleteUser: React.FC<DeleteUserProps> = ({
  username,
  onDeleted,
  loading
}) => {
  const history = useHistory();
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const onClickDelete = async () => {
    if (!window.confirm("Are you sure you wish to delete this user?")) {
      return;
    }

    try {
      setDeleting(true);

      const url = `${config.apiUrl}/api/v1/admin/users/${username}/delete`;

      await axios.delete(url, {
        headers: { "x-access-token": await getAccessToken() }
      });

      notification.info({
        message: "User deleted",
        duration: 10
      });

      onDeleted();

      history.push(USERS_PATH);
    } catch (err) {
      notification.error({
        message: "Something went wrong",
        description: (err as any)?.message || EN.error.defaultMessage,
        duration: 5
      });
    } finally {
      setDeleting(false);
      setDeleted(true);
    }
  };

  return deleted ? (
    <></>
  ) : (
    <Button
      onClick={onClickDelete}
      loading={deleting}
      disabled={deleting || loading}
      className="ant-btn btn btn-danger btn-delete-user"
    >
      {deleting ? "Deleting" : `Delete user`}
    </Button>
  );
};
