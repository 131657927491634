import React from "react";
import { UserDetailsDatabaseMedia } from "../../../api/user.service";
import { Collapse, Tabs } from "antd";
import { MediaJobTable } from "./media-job-table";
import { MediaFileTable } from "./media-file-table";

const { Panel } = Collapse;
const { TabPane } = Tabs;

interface MediaListDetailsProps {
  mediaList: UserDetailsDatabaseMedia[];
}

export const MediaListDetails: React.FC<MediaListDetailsProps> = ({
  mediaList
}) => {
  const mediaPanels = mediaList.map((m, i) => {
    return (
      <Panel header={`${m.name} - ${m.status}`} key={i}>
        <p>id: {m.id}</p>
        <p>name: {m.name}</p>
        <p>status: {m.status}</p>
        <p>createdAt: {m.createdAt}</p>
        <p>updatedAt: {m.updatedAt}</p>
        <p>deletedAt: {m?.deletedAt}</p>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Jobs" key="1">
            <MediaJobTable jobs={m.jobs} />
          </TabPane>
          <TabPane tab="Files" key="2">
            <MediaFileTable files={m.files} />
          </TabPane>
        </Tabs>
      </Panel>
    );
  });

  return <Collapse>{mediaPanels}</Collapse>;
};
