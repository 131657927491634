import React, { useState } from "react";
import { DatePicker } from "antd";
import { RangePickerValue } from "antd/lib/date-picker/interface";
import moment from "moment";

import { ApiAccountStatsCard } from "./api-account-stats-card";
import { AccountListItem } from "../../../api/account.service";
import { StatsCard } from "../../stats-card/stats-card";
import { fetchApiAccountStats } from "../../../api/stats.service";
import { IApiAccountStats } from "../../../interfaces/stats";

interface IApiAccountStatsProps {
  account: AccountListItem;
}

export const ApiAccountStats: React.FC<IApiAccountStatsProps> = ({
  account
}) => {
  const [filtersDates, setFiltersDates] = useState<[Date, Date]>([
    new Date(),
    new Date()
  ]);
  const [stats, setStats] = useState<IApiAccountStats>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const onRangePickerChange = async (dates: RangePickerValue) => {
    const startDate = dates[0] && dates[0].utc().startOf("D").toDate();
    const endDate = dates[1] && dates[1].utc().endOf("D").toDate();

    if (!startDate || !endDate) {
      return;
    }

    setFiltersDates([startDate, endDate]);

    setIsError(false);
    setIsLoading(true);

    try {
      const res = await fetchApiAccountStats({
        accountId: account.accountId,
        startDate,
        endDate
      });

      setStats(res);
    } catch (e) {
      setIsError(true);
    }

    setIsLoading(false);
  };

  return (
    <div>
      <div>Name: {account.name}</div>
      {account.owner && <div>Email: {account.owner.email}</div>}
      <StatsCard
        title="API Account Stats"
        subtitle={
          <DatePicker.RangePicker
            value={[
              moment(filtersDates[0]).utc().startOf("D"),
              moment(filtersDates[1]).utc().endOf("D")
            ]}
            onChange={onRangePickerChange}
            allowClear={false}
            format="DD/MM/YYYY"
            separator="-"
          />
        }
      >
        <ApiAccountStatsCard
          stats={stats}
          loading={isLoading}
          error={isError}
        />
      </StatsCard>
    </div>
  );
};
