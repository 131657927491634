import { Button } from "antd";
import React from "react";
import { IAccountCredit } from "../../interfaces/account";
import { Modal } from "../modal/modal";
import styles from "./destroy-account-modal.module.scss";
import { destroyAccount } from "../../api/account.service";

interface DestroyAccountModalProps {
  accountId: string;
  defaultValue: IAccountCredit;
  closeModal: (reload?: boolean) => void;
}
export const DestroyAccountModal: React.FC<DestroyAccountModalProps> = ({
  accountId,
  defaultValue,
  closeModal
}) => {
  const DestroyAccount = () => {
    destroyAccount(accountId);
    closeModal(true);
  };

  return (
    <Modal onDismiss={closeModal} className={styles["modal"]} hasCloseIcon>
      <h5 className="mb-3">Are you sure?</h5>
      <div className="d-flex align-items-center justify-content-end mt-5">
        <Button className="d-block flex-fill mr-3" onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button
          className="d-block flex-fill btn-danger"
          onClick={DestroyAccount}
        >
          Delete Account
        </Button>
      </div>
    </Modal>
  );
};
