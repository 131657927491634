import { Table } from "antd";
import React from "react";
import { Asset } from "../../../api/account.service";

interface AccountModalAssetDetailsProps {
  assets: Asset[];
}

export const AccountModalAssetDetails: React.FC<AccountModalAssetDetailsProps> =
  ({ assets }) => {
    const columns = [
      {
        title: "Id",
        dataIndex: "id",
        key: "id"
      },
      {
        dataIndex: "url",
        key: "url",
        render: (value: string) => {
          return (
            <>
              <a href={value}>Download</a>
            </>
          );
        }
      },
      {
        title: "Filename",
        dataIndex: "filename",
        key: "filename"
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type"
      },
      {
        title: "Metadata",
        dataIndex: "metadata",
        key: "metadata",
        render: (value: any) => <>{JSON.stringify(value)}</>
      }
    ];

    return (
      <>
        <Table
          rowKey="id"
          dataSource={assets.map((a) => {
            return { key: a.id, ...a };
          })}
          columns={columns}
        />
      </>
    );
  };
