import { notification } from "antd";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { EN } from "../../../assets/i18n/en";
import cx from "classnames";
import { emailPattern } from "../../../utils/regex-patterns";
import {
  createBusinessAccount,
  CreateBusinessAccountParams
} from "../../../api/account.service";
import Select from "react-select";
import countries from "typed-countries";
import { SelectOption } from "../../../utils/select";
import { Modal } from "../../modal/modal";
import styles from "./new-business-account-modal.module.scss";

interface NewBusinessAccountModalProps {
  onClose: () => void;
}

export interface CreateBusinessAccountForm {
  accountName: string;
  firstName: string;
  surname: string;
  email: string;
  address: string;
  city?: string;
  state?: string;
  postalCode: string;
  salesRep: string;
  country: SelectOption;
}

export const NewBusinessAccountModal: React.FC<NewBusinessAccountModalProps> =
  ({ onClose }) => {
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, errors, control, reset, getValues } =
      useForm<CreateBusinessAccountForm>();

    const onSubmit = async ({
      accountName,
      firstName,
      surname,
      email,
      address,
      city,
      state,
      postalCode,
      salesRep,
      country
    }: CreateBusinessAccountForm) => {
      setLoading(true);

      try {
        const params: CreateBusinessAccountParams = {
          accountName,
          firstName,
          surname,
          email,
          address,
          city,
          state,
          postalCode,
          salesRep,
          country: country.value
        };

        await createBusinessAccount(params);

        notification.success({
          message: `You have added a business account for ${params.accountName}`,
          duration: 10
        });

        const initialValues = { country: { label: "", value: "" } };

        reset(initialValues);
        setLoading(false);
        onClose();
      } catch (e) {
        const description = e.message || EN.error.defaultMessage;

        notification.error({
          message: "Something went wrong",
          description,
          duration: 10
        });

        setLoading(false);
      }
    };

    const state = getValues() as unknown as CreateBusinessAccountForm;

    return (
      <Modal onDismiss={onClose} className={styles["modal"]} hasCloseIcon>
        <h5 className="mb-3">New Business Account</h5>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-container container">
            <div className="row">
              <div className="col-lg">
                <label htmlFor="accountName">Account Name</label>
                <input
                  id="accountName"
                  name="accountName"
                  ref={register({ required: true })}
                  defaultValue={state?.accountName}
                  className={cx("form-control", {
                    "is-invalid": errors.accountName
                  })}
                />
                <h4>Account holder</h4>
                <label htmlFor="firstName">First Name</label>
                <input
                  className={cx("form-control", {
                    "is-invalid": errors.firstName
                  })}
                  id="firstName"
                  name="firstName"
                  ref={register({ required: true })}
                  defaultValue={state?.firstName}
                />
                <label htmlFor="surname">Surname</label>
                <input
                  className={cx("form-control", {
                    "is-invalid": errors.surname
                  })}
                  id="surname"
                  name="surname"
                  ref={register({ required: true })}
                  defaultValue={state?.surname}
                />
                <label htmlFor="email">Email</label>
                <input
                  className={cx("form-control", {
                    "is-invalid": errors.email
                  })}
                  type="email"
                  id="email"
                  name="email"
                  ref={register({ required: true, pattern: emailPattern })}
                  defaultValue={state?.email}
                />
                <h4>Subly contact</h4>
                <label htmlFor="salesRep">Sales rep</label>
                <input
                  className={cx("form-control", {
                    "is-invalid": errors.salesRep
                  })}
                  id="salesRep"
                  name="salesRep"
                  placeholder="Sales rep"
                  ref={register({ required: true })}
                  defaultValue={state?.salesRep}
                />
              </div>
              <div className="col-lg">
                <label htmlFor="address">Address</label>
                <textarea
                  className={cx("form-control", {
                    "is-invalid": errors.address
                  })}
                  id="address"
                  name="address"
                  placeholder="Address"
                  rows={3}
                  ref={register({ required: true })}
                  defaultValue={state?.address}
                />
                <label htmlFor="city">City / town (optional)</label>
                <input
                  className={cx("form-control", {
                    "is-invalid": errors.city
                  })}
                  id="city"
                  name="city"
                  ref={register()}
                  defaultValue={state?.city}
                />
                <label htmlFor="state">State (optional)</label>
                <input
                  className={cx("form-control", {
                    "is-invalid": errors.state
                  })}
                  id="state"
                  name="state"
                  ref={register()}
                  defaultValue={state?.state}
                />
                <label htmlFor="postalCode">Postal Code</label>
                <input
                  className={cx("form-control", {
                    "is-invalid": errors.postalCode
                  })}
                  id="postalCode"
                  name="postalCode"
                  placeholder="Postal Code"
                  ref={register({ required: true })}
                  defaultValue={state?.postalCode}
                />
                <label htmlFor="country">Country</label>
                <fieldset key={"country"} name={"country"}>
                  <Controller
                    as={
                      <Select
                        className={cx("country-select", {
                          "is-invalid": errors.country
                        })}
                        maxMenuHeight={150}
                        placeholder=""
                        isSearchable={true}
                        options={countries.map((c) => ({
                          value: c.iso,
                          label: c.name
                        }))}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary: "var(--purple)"
                          }
                        })}
                      />
                    }
                    control={control}
                    rules={{ required: true }}
                    defaultValue={state?.country}
                    id="country"
                    name="country"
                  />
                </fieldset>
              </div>
            </div>
            <div className="row m-t-20">
              <div className="col-lg new-business-account-form-actions">
                <button
                  type="submit"
                  className="btn btn-primary btn-block mt-3"
                  style={{ width: 120 }}
                >
                  {loading ? "Saving" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    );
  };
