import React, { useEffect, useState } from "react";
import { Input, Button, Select } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { formatDateTime } from "../../../utils/date-formatter";
import { fetchMoreUserList, UserListItem } from "../../../api/user.service";
import { UserListParamsState } from "../../../state/users/users.store";
import { NewTable } from "../../new-table/new-table";
import classNames from "classnames";
import { toast } from "react-toastify";

const { Option } = Select;

interface UsersTableProps {
  loading: boolean;
  params?: UserListParamsState;
  userList?: UserListItem[];
  onSelect: (username: string) => void;
  onSearch: (filter: string, search: string) => void;
  onResetSearch: () => void;
}
export const UsersTable: React.FC<UsersTableProps> = ({
  loading,
  params,
  userList,
  onSelect,
  onSearch,
  onResetSearch
}) => {
  const [searchField, setSearchField] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    setSearchField(params?.filter || "");
    setSearchText(params?.search || "");
  }, [params]);

  const filtering = () => (
    <div className="row">
      <div className="col col-2">
        <Select
          style={{ width: "100%" }}
          onChange={(value: string) => {
            console.log("value:", value);
            setSearchField(value);
          }}
          value={searchField}
        >
          <Option value={"username"}>Username</Option>
          <Option value={"email"}>Email</Option>
          <Option value={"phone_number"}>Phone Number</Option>
          <Option value={"name"}>name</Option>
          <Option value={"given_name"}>Given Name</Option>
          <Option value={"family_name"}>Family Name</Option>
          <Option value={"preferred_username"}>Preferred Username</Option>
          <Option value={"cognito:user_status"}>Status</Option>
          <Option value={"status"}>is 'Enabled'</Option>
          <Option value={"sub"}>sub</Option>
        </Select>
      </div>
      <div className="col col-6">
        <Input
          onChange={(e) => {
            setSearchText(e?.target?.value);
          }}
          value={searchText}
        />
      </div>
      <div className="col col-4">
        <Button
          onClick={() => {
            if (searchText !== "" && searchField !== "") {
              onSearch(searchField, searchText.trim());
            }
          }}
          style={{ marginRight: "8px" }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            setSearchField("");
            setSearchText("");
            onResetSearch();
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );

  return (
    <>
      {filtering()}

      <hr className="rounded tw-my-2" />
      <NewTable
        columns={[
          {
            label: "ID",
            id: "id",
            width: "300px"
          },
          {
            label: "Name",
            id: "name",
            width: "1fr"
          },
          {
            label: "Email",
            id: "email",
            width: "1fr"
          },
          {
            label: "Registered At",
            id: "createdAt",
            width: "140px"
          }
        ]}
        items={userList || []}
        className="tw-flex tw-min-w-min tw-flex-col tw-overflow-auto"
      >
        <NewTable.Header>
          {(cols) =>
            cols.map((col, i) => {
              const isFirst = i === 0;
              const isLast = cols.length - 1 === i;

              return (
                <NewTable.HeaderCell
                  key={col.id}
                  className={classNames(" !tw-pr-2 tw-font-medium", {
                    "!tw-pl-3": isFirst,
                    "!tw-pl-2": !isFirst,
                    "!tw-pr-3": isLast,
                    "!tw-pr-2": !isLast
                  })}
                >
                  {col.label}
                </NewTable.HeaderCell>
              );
            })
          }
        </NewTable.Header>
        {(userList || []).map((item, i) => (
          <Row key={i} item={item} onRowClick={() => onSelect(item.id)} />
        ))}
      </NewTable>

      <hr className="rounded tw-my-2" />
      <div className="row">
        <div className="col col-12" style={{ textAlign: "center" }}>
          <Button
            type="primary"
            className="btn btn-primary btn-sm"
            disabled={!Boolean(params?.paginationToken)}
            onClick={() => fetchMoreUserList()}
          >
            Load More Users
          </Button>
        </div>
      </div>
    </>
  );
};

interface RowInnerProps {
  item: UserListItem;
  onRowClick: (item: UserListItem) => void;
}
const Row: React.FC<RowInnerProps> = ({ item, onRowClick }) => {
  const [isHovered, setHovered] = React.useState(false);

  const sharedClasses = React.useMemo(() => {
    let classes = "tw-min-h-12 tw-cursor-pointer";
    if (isHovered) classes += " tw-bg-neutral-50 tw-cursor-pointer";
    return classes;
  }, [isHovered]);

  const onClickIdCell = () => {
    navigator.clipboard.writeText(item.id);
    toast.success("ID copied to clipboard");
  };

  const onClickCell = () => {
    onRowClick(item);
  };

  return (
    <>
      <NewTable.Cell
        className={classNames(
          sharedClasses,
          "!tw-pl-3 tw-overflow-hidden hover:tw-text-neutral-400"
        )}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickIdCell}
      >
        {item.id}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses, "tw-overflow-hidden")}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        {item.givenName} {item.familyName} ({item.name})
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        {item.email}{" "}
        {item.verification_status && (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        )}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        {formatDateTime(item.createdAt)}
      </NewTable.Cell>
    </>
  );
};
