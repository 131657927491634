import React from "react";
import { Button, Table } from "antd";
import { AccountMember } from "../../../interfaces/member";
import { formatDate, formatDaysFromNow } from "../../../utils/date-formatter";
import { TrashCanIcon } from "../../icons/icons";
import { DeleteAccountMember } from "../../../api/member.service";
import { ModalContext, ModalType } from "../../../contexts/modal.context";
import { NewBusinessAccountMemberModal } from "../new-business-account-modal/new-business-account-member-modal";

interface AccountMemberListProps {
  accountId: string | undefined;
  members?: AccountMember[];
  loading: boolean;
}

export const AccountMemberList: React.FC<AccountMemberListProps> = ({
  accountId,
  members,
  loading
}) => {
  const { showModal, hideModal } = React.useContext(ModalContext);

  if (loading || !accountId) {
    return <span>Loading Members</span>;
  }

  const DestroyMember = (userId: string) => {
    DeleteAccountMember(accountId, userId);
  };

  const NewMember = () => {
    showModal(
      ModalType.NewBusinessAccountMember,
      <NewBusinessAccountMemberModal
        onClose={() => hideModal(ModalType.NewBusinessAccountMember)}
        accountId={accountId}
      />
    );
  };

  return (
    <>
      <div>
        <div>
          <p>Current Members</p>
          <Table
            size="small"
            bordered
            loading={loading}
            dataSource={(members || []).filter(() => {
              return true;
            })}
            pagination={false}
            rowKey="id"
            columns={[
              {
                title: "Name",
                dataIndex: "name",
                key: "name"
              },
              {
                title: "Email",
                dataIndex: "email",
                key: "email"
              },
              {
                title: "ID",
                dataIndex: "id",
                key: "id"
              },
              {
                title: "Last Active",
                dataIndex: "lastActive",
                key: "lastActive",
                render: (value: Date) => {
                  return <>{value ? formatDaysFromNow(value) : ""}</>;
                }
              },
              {
                title: "Number of Uploads",
                dataIndex: "numberOfUploads",
                key: "numberOfUploads",
                render: (value: number) => {
                  return <>{value}</>;
                }
              },

              {
                title: "Number of Downloads",
                dataIndex: "numberOfDownloads",
                key: "numberOfDownloads",
                render: (value: number) => {
                  return <>{value}</>;
                }
              },
              {
                title: "Created At",
                dataIndex: "createdAt",
                key: "createdAt",
                render: (value) => {
                  return <>{formatDate(value)}</>;
                }
              },
              {
                title: "Owner?",
                dataIndex: "isOwner",
                key: "isOwner",
                render: (value) => {
                  return <>{value || false ? "YES" : "NO"}</>;
                }
              },
              {
                title: "Role",
                dataIndex: "role",
                key: "role"
              },
              {
                title: "Delete?",
                render: (record) => {
                  if (!record.isOwner) {
                    return <TrashCanIcon />;
                  }
                },
                onCellClick: (record) => {
                  DestroyMember(record.id);
                }
              }
            ]}
          />
        </div>
        <br />
        <div>
          <Button className="btn btn-primary" onClick={NewMember}>
            New Member
          </Button>
        </div>
      </div>
    </>
  );
};
