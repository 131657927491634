import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { takeUntil } from "rxjs/internal/operators/takeUntil";
import { Subject } from "rxjs/internal/Subject";
import { fetchMediaList, MediaListItem } from "../../api/media.service";
import { mediaQuery } from "../../state/media/media.query";
import {
  MediaListParamsState,
  mediaStore
} from "../../state/media/media.store";
import AdminLayout from "../layouts/adminLayout";
import { MediaTable } from "./media-table/media-table";
import { getSingleQueryStringParameterValue } from "../../utils/querystring";
import { ModalContext, ModalType } from "../../contexts/modal.context";
import { MediaModal } from "./media-modal/media-modal";

export interface MediaRouteParams {
  id: string;
}

const Media: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [mediaList, setMediaList] = useState<MediaListItem[]>();
  const [params, setParams] = useState<MediaListParamsState>();
  const { showModal, hideModal } = React.useContext(ModalContext);

  let { id } = useParams<MediaRouteParams>();

  const searchField = getSingleQueryStringParameterValue(
    window.location.search,
    "filter"
  );

  const history = useHistory();

  const onSelect = (mediaId: string) =>
    showModal(
      ModalType.Media,
      <MediaModal
        mediaId={mediaId}
        onClose={() => hideModal(ModalType.Media)}
      />
    );

  const onResetSearch = () => {
    history.push("/media");
  };

  const onSearch = (filter: string, search: string) => {
    let url = `/media/${search}`;

    if (filter !== "media") {
      url = url + `?filter=${filter}`;
    }

    history.push(url);
  };

  useEffect(() => {
    let params = {
      filter: "",
      search: ""
    };

    if (id != null) {
      params.search = id;

      Boolean(searchField)
        ? (params.filter = searchField)
        : (params.filter = "media");
    }

    setParams(params);

    mediaStore.updateParams(params);

    fetchMediaList();

    const unsubcribe$ = new Subject();

    mediaQuery
      .select()
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ list }) => {
        setLoading(list.loading);
        setMediaList(list.mediaList);

        if (params.filter === "media" && list.mediaList.length === 1) {
          // onSelect(params.search);
        }
      });

    return () => unsubcribe$.next();

    //rerender on route param changes only - everything here goes through the router
  }, [searchField, id]);

  return (
    <AdminLayout parent="Lists" title="Media List">
      <MediaTable
        loading={loading}
        params={params}
        mediaList={mediaList}
        onSelect={onSelect}
        onSearch={onSearch}
        onResetSearch={onResetSearch}
      />
    </AdminLayout>
  );
};

export default Media;
