import { Button, Checkbox, InputNumber, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { useState } from "react";
import { addTrial } from "../../api/billing.service";
import { IProduct } from "../../interfaces/product";
import { Modal } from "../modal/modal";
import styles from "./new-trial-modal.module.scss";

interface NewTrialModalProps {
  products: IProduct[];
  accountId: string;
  closeModal: (reload?: boolean) => void;
}
export const NewTrialModal: React.FC<NewTrialModalProps> = ({
  products,
  accountId,
  closeModal
}) => {
  const [productId, setProductId] = useState<string>("");
  const [trialDays, setTrialDays] = useState<number>(0);
  const [cancelAtPeriodEnd, setCancelAtPeriodEnd] = useState<boolean>(false);
  const [isAmbassador, setIsAmbassador] = useState<boolean>(false);
  const [isInfluencer, setIsInfluencer] = useState<boolean>(false);
  const [cancelExistingSubscription, setCancelExistingSubscription] =
    useState<boolean>(false);

  const onTrialDaysChange = (n: number | undefined) => {
    setTrialDays(n || 0);
  };

  const onAddTrial = () => {
    addTrial(accountId, {
      productId,
      trialDays,
      cancelAtPeriodEnd,
      isAmbassador,
      isInfluencer,
      cancelExistingSubscription
    });
    closeModal(true);
  };

  return (
    <Modal onDismiss={closeModal} className={styles["modal"]} hasCloseIcon>
      <h5 className="mb-3">Trial</h5>

      <label style={{ width: "100%" }}>
        Product:
        <Select
          style={{ width: "20%" }}
          onChange={(value: string) => {
            setProductId(value);
          }}
          value={productId}
        >
          {products.map((p) => (
            <Select.Option key={p.id} value={p.id}>
              {p.name}
            </Select.Option>
          ))}
        </Select>
      </label>

      <label style={{ width: "100%" }}>
        Trial days:
        <InputNumber min={0} onChange={onTrialDaysChange} value={trialDays} />
      </label>

      <label style={{ width: "100%" }}>
        Cancel at end:
        <Checkbox
          onChange={(e: CheckboxChangeEvent) => {
            setCancelAtPeriodEnd(!cancelAtPeriodEnd);
          }}
          checked={cancelAtPeriodEnd}
        />
      </label>

      <label style={{ width: "100%" }}>
        Is ambassador:
        <Checkbox
          onChange={(e: CheckboxChangeEvent) => {
            setIsAmbassador(!isAmbassador);
          }}
          checked={isAmbassador}
        />
      </label>

      <label style={{ width: "100%" }}>
        Is Influencer:
        <Checkbox
          onChange={(e: CheckboxChangeEvent) => {
            setIsInfluencer(!isInfluencer);
          }}
          checked={isInfluencer}
        />
      </label>

      <label style={{ width: "100%" }}>
        Cancel any existing subscription:
        <Checkbox
          onChange={(e: CheckboxChangeEvent) => {
            setCancelExistingSubscription(!cancelExistingSubscription);
          }}
          checked={cancelExistingSubscription}
        />
      </label>

      <div className="d-flex align-items-center justify-content-end mt-5">
        <Button className="d-block flex-fill mr-3" onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button className="d-block flex-fill" onClick={onAddTrial}>
          Start Trial
        </Button>
      </div>
    </Modal>
  );
};
