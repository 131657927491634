export default {
  // Api
  apiUrl: process.env.REACT_APP_API_URL,
  // Contentful
  contentfulSpaceId: process.env.REACT_APP_CONTENTFUL_SPACE_ID || "",
  contentfulAccessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || "",
  // Pusher
  pusher: {
    clientKey: process.env.REACT_APP_PUSHER_KEY || "",
    cluster: process.env.REACT_APP_PUSHER_CLUSTER || ""
  },
  // GTM
  gtmId: process.env.REACT_APP_GTM_ID || "",
  // Sentry
  sentry: process.env.REACT_APP_SENTRY_ID,
  // OAuth
  oauth: {
    google: `${window.location.origin}/auth/google/callback`
  },
  // Stripe
  stripe: {
    apiUrl: process.env.REACT_APP_STRIPE_API_URL || "",
    clientKey: process.env.REACT_APP_STRIPE_CLIENT_KEY
  }
};
