import { Query } from "@datorama/akita";
import {
  ProductListState,
  productListStore,
  ProductListStore
} from "./product-list.store";

export class ProductListQuery extends Query<ProductListState> {
  constructor(protected store: ProductListStore) {
    super(store);
  }
}

export const productListQuery = new ProductListQuery(productListStore);
