import axios from "axios";
import config from "../config";
import { BasicStripeCustomer } from "../interfaces/billing";
import { accountStore } from "../state/account/account.store";
import { getAccessToken } from "./auth.service";
import { accountQuery } from "../state/account/account.query";
import { productListStore } from "../state/billing/product-list/product-list.store";
import { IProduct } from "../interfaces/product";
import { IAccountCredit } from "../interfaces/account";

interface GetIntentResponse {
  message: string;
  intent: string;
}
export const getIntent = async () => {
  return await axios.get<GetIntentResponse>("/intent", {
    baseURL: config.stripe.apiUrl,
    headers: { Authorization: await getAccessToken() }
  });
};

interface GetCustomerResponse {
  message: string;
  customer: BasicStripeCustomer;
}
export const getCustomer = async (options = { force: false }) => {
  const account = accountQuery.getValue();

  if (account.loaded && !options.force) {
    return;
  }

  accountStore.update({ loading: true });

  const {
    data: { customer }
  } = await axios.get<GetCustomerResponse>("/customer", {
    baseURL: config.stripe.apiUrl,
    headers: { Authorization: await getAccessToken() }
  });

  const freeRemainCredit = customer.freeRemainCredit || 0;
  const paidRemainCredit = customer.paidRemainCredit || 0;
  const totalMinutes = (freeRemainCredit + paidRemainCredit) / 60;

  accountStore.update({
    paymentMethods: customer.paymentMethods,
    credit: totalMinutes,
    loading: false,
    loaded: true
  });
};

interface TopUpParams {
  amount: number;
  time: number;
  methodId: string;
}
export const topUp = async (params: TopUpParams) => {
  return await axios.post("/top-up", params, {
    baseURL: config.stripe.apiUrl,
    headers: { Authorization: await getAccessToken() }
  });
};

const baseURL = `${config.apiUrl}/api/v1`;
const adminBaseURL = `${baseURL}/admin`;

export const fetchProducts = async () => {
  try {
    const { loading } = productListStore.getValue();

    if (loading) return;

    productListStore.update({
      loading: true
    });

    const { data } = await axios.get<{
      products: IProduct[];
    }>(`/products`, {
      baseURL: adminBaseURL,
      headers: {
        "x-access-token": await getAccessToken()
      }
    });

    productListStore.update({
      products: data.products
    });
  } catch (error) {
    // handleError(error);
  } finally {
    productListStore.update({
      loading: false
    });
  }
};

export const addTrial = async (
  accountId: string,
  params: {
    productId: string;
    trialDays: number;
    cancelAtPeriodEnd?: boolean;
    isAmbassador?: boolean;
    isInfluencer?: boolean;
    cancelExistingSubscription?: boolean;
  }
) => {
  await axios.put(`/accounts/${accountId}/add-trial`, params, {
    baseURL: adminBaseURL,
    headers: {
      "x-access-token": await getAccessToken()
    }
  });
};

export const setCredit = async (accountId: string, params: IAccountCredit) => {
  await axios.put(`/accounts/${accountId}/set-credit`, params, {
    baseURL: adminBaseURL,
    headers: {
      "x-access-token": await getAccessToken()
    }
  });
};

export const setTeamCapacity = async (
  accountId: string,
  params: { capacity: number }
) => {
  await axios.put(`/accounts/${accountId}/set-team-capacity`, params, {
    baseURL: adminBaseURL,
    headers: {
      "x-access-token": await getAccessToken()
    }
  });
};
