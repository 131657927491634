import { Button, Divider, Form, Input } from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";
import React from "react";
import { GoogleButton } from "../google-button/google-button";

interface SignInFormProps {
  form: WrappedFormUtils<any>;
  loading: boolean;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}
export const SignInForm: React.FC<SignInFormProps> = ({
  form,
  loading,
  onSubmit
}) => {
  return (
    <div id="signin-form">
      <GoogleButton>Sign In with Google</GoogleButton>

      <Divider orientation="center">or</Divider>
      <Form layout="vertical" hideRequiredMark onSubmit={onSubmit}>
        <Form.Item label="Email">
          {form.getFieldDecorator("email", {
            rules: [
              {
                required: true,
                message: "Please input your e-mail address"
              }
            ]
          })(
            <Input
              size="default"
              placeholder="Email Address"
              className="form-control"
              style={{ padding: "1.3rem" }}
              autoCapitalize="off"
              type="email"
            />
          )}
        </Form.Item>
        <Form.Item label="Password">
          {form.getFieldDecorator("password", {
            rules: [
              {
                required: true,
                message: "Please input your password"
              }
            ]
          })(
            <Input
              size="default"
              type="password"
              placeholder="Password"
              className="form-control"
              style={{ padding: "1.3rem" }}
            />
          )}
        </Form.Item>
        <div className="m-t-20 text-right">
          <Button
            type="primary"
            className="btn btn-primary btn-block btn-lg"
            style={{ height: "auto" }}
            htmlType="submit"
            loading={loading}
          >
            Sign in
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default SignInForm;
