import React, { useContext, useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { AuthContext } from "../../../contexts/auth.context";
import { AuthWrapper } from "../auth-wrapper/auth-wrapper";
import "./oauth-callback.scss";
import { Button } from "antd";

export const OAuthCallback: React.FC = () => {
  const { oauthLogin } = useContext(AuthContext);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [hasError, setHasError] = useState(false);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const code = query.get("code");
  const state = query.get("state");

  useEffect(() => {
    const getToken = async () => {
      if (!code) {
        return;
      }

      const res = await oauthLogin(code);

      if (res && res.success) {
        setLoggedIn(true);
        return;
      }

      setHasError(true);
    };

    getToken();
    // eslint-disable-next-line
  }, [code]);

  if (!code) {
    return <Redirect to="/login" />;
  }

  if (isLoggedIn) {
    if (state && state !== "null") {
      return (
        <Redirect
          to={{
            pathname: state
          }}
        />
      );
    }
    return <Redirect to="/" />;
  }

  if (hasError) {
    return (
      <AuthWrapper>
        <div className="oauth-callback">
          <h2>Ooops, we have an error</h2>
          <p>There was a problem authenticating you. Please try again.</p>
          <Button className="btn btn-primary" href="/login">
            Go back
          </Button>
        </div>
      </AuthWrapper>
    );
  }

  return (
    <AuthWrapper>
      <div className="oauth-callback">
        <h2>We are preparing your account</h2>
        <p>Please wait a moment...</p>
        <div className="la-timer la-gray">
          <div></div>
        </div>
      </div>
    </AuthWrapper>
  );
};
