export const LANDING = "/";

export const USERS_ID_PATH = "/users/:id";
export const USERS_PATH = "/users";
export const MEDIA_ID_PATH = "/media/:id";
export const MEDIA_PATH = "/media";
export const ACCOUNTS_ID_PATH = "/accounts/:id";
export const ACCOUNTS_PATH = "/accounts";
export const JOBS_PATH = "/jobs";

export const BUSINESS_ACCOUNTS_PATH = "/business";
export const BUSINESS_ACCOUNTS_ID_PATH = "/business/:id";

export const API_ACCOUNTS_PATH = "/api";
export const API_ACCOUNTS_ID_PATH = "/api/:accountId";

export const ACCOUNT_PATH = "/account";
export const ACCOUNT_EDIT_PATH = "/account/edit";
export const ACCOUNT_TOP_UP_PATH = "/account/top-up";
export const ACCOUNT_PAYMENT_METHOD_PATH = "/account/payment-method";

export const DEALS_PATH = "/deals";
export const DEALS_ID_PATH = "/deals/:id";

export const VIDEOS = "/videos";
export const VIDEO_DETAILS = "/videos/:mediaId";

export const LOGIN_PATH = "/login";
export const SIGN_UP_PATH = "/signup";
export const PARTNER_PATH = "/partner/:partner";
export const PARTNER_VERIFICATION_PATH = "/partner/:partner/verify/:id/:code";
export const OAUTH_CALLBACK_PATH = "/auth/:provider/callback";

export const FORGET_PASSWORD_PATH = "/forgot-password";
export const RESET_PASSWORD_PATH = "/reset/:id/:code";
export const VERIFICATION_PATH = "/verify/:id/:code";

export const ORDERS_PATH = "/orders";

export const HELP = "/help";
export const FEEDBACK = "/feedback";
export const DOCUMENTATION = "/documentation";
export const CHANGELOG = "/changelog";

// Public website
export const PRIVACY_URL = "https://getsubly.com/privacy-policy/";
export const TERMS_URL = "https://getsubly.com/terms-of-service/";
export const GDPR_URL = "https://getsubly.com/gdpr/";
export const WEBSITE_URL = "https://getsubly.com/";
export const SUPPORT_URL =
  "https://www.getsubly.com/subly-frequently-asked-questions";
