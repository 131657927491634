export enum LocalStorage {
  UserAuth = "user_auth",
  UserDetails = "user_details"
}

export const setStorageKey = (key: LocalStorage, values: any) => {
  sessionStorage.setItem(key, JSON.stringify(values));
};

export const getStorageKey = (key: LocalStorage) => {
  const values = sessionStorage.getItem(key) || "{}";
  return JSON.parse(values);
};

export const getAccountId = () => {
  const userDetails = getStorageKey(LocalStorage.UserDetails);
  return userDetails.account_id;
};

export const removeStorage = () => {
  Object.values(LocalStorage).forEach((v) => {
    sessionStorage.removeItem(v);
  });
};
