import React, { useState } from "react";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Line } from "react-chartjs-2";
import {
  mdiAccountOutline,
  mdiAccountAlertOutline,
  mdiArrowUpCircleOutline
} from "@mdi/js";
import { dashboardQuery } from "../../../state/dashboard/dashboard.query";
import { fetchActiveUsersStatsData } from "../../../api/stats.service";
import { StatsCard } from "../../stats-card/stats-card";
import { ActiveUsersStats } from "../../../interfaces/stats";
import {
  NumberStatsCard,
  NumberStatsCardSize
} from "../../stats-card/number-stats-card/number-stats-card";

interface ActiveUsersStatsCardsProps {
  notifyOfActiveUsersStatsStatus: (isEnabled: boolean) => void;
}

export const ActiveUsersStatsCards: React.FC<ActiveUsersStatsCardsProps> = ({
  notifyOfActiveUsersStatsStatus
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | undefined>(false);
  const [stats, setStats] = useState<ActiveUsersStats>();

  const loadData = (isEnabled: boolean) => {
    if (!isEnabled) {
      setStats({
        activeUserCount: 0,
        activeUserTimeline: [],
        newUserUploadCount: 0,
        uploadUserCount: 0
      });
      notifyOfActiveUsersStatsStatus(false);
      return;
    }

    const unsubcribe$ = new Subject();

    fetchActiveUsersStatsData(
      dashboardQuery.filterStartDate,
      dashboardQuery.filterEndDate
    );

    dashboardQuery
      .select("activeUsersStats")
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ loading, stats, error }) => {
        setError(error);
        setLoading(loading);
        setStats(stats);
      });

    notifyOfActiveUsersStatsStatus(true);
  };

  return (
    <StatsCard title="Active User Stats" loadData={loadData}>
      <div className="row">
        <NumberStatsCard
          title="Active Users"
          loading={loading}
          error={error}
          result={stats?.activeUserCount ?? 0}
          icon={mdiAccountOutline}
          size={NumberStatsCardSize.IsThird}
        />

        <NumberStatsCard
          title="Users with 2+ Uploads"
          loading={loading}
          error={error}
          result={stats?.uploadUserCount ?? 0}
          icon={mdiArrowUpCircleOutline}
          size={NumberStatsCardSize.IsThird}
        />

        <NumberStatsCard
          title="New Users with 5+ Uploads"
          loading={loading}
          error={error}
          result={stats?.newUserUploadCount ?? 0}
          icon={mdiAccountAlertOutline}
          size={NumberStatsCardSize.IsThird}
        />
      </div>
      <div className="row mb-0">
        <Line
          data={{
            labels: (stats?.activeUserTimeline ?? []).map(({ label }) => label),
            datasets: [
              {
                label: "Active Users",
                lineTension: 0.1,
                data: (stats?.activeUserTimeline ?? []).map(
                  ({ activeUserCount }) => activeUserCount
                ),
                borderColor: "#39efa5",
                fill: false
              }
            ]
          }}
          options={{
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true
                  }
                }
              ]
            }
          }}
        />
      </div>
    </StatsCard>
  );
};
