import { AccountDetails } from "./../../api/account.service";
import { Store, StoreConfig } from "@datorama/akita";
import { AccountListItem } from "../../api/account.service";
import { PaymentMethod } from "../../interfaces/billing";
import { User } from "../../interfaces/user";

export enum AccountTypeEnum {
  PERSONAL = "PERSONAL",
  BUSINESS = "BUSINESS",
  API = "API"
}

export interface AccountListParamsState {
  filter?: string;
  search?: string;
  limit?: number;
  paginationToken?: string;
  accountType?: AccountTypeEnum;
}

export interface AccountListState {
  loading: boolean;
  accountList: AccountListItem[];
}

export interface AccountDetailsState {
  loading: boolean;
  accountDetails?: AccountDetails;
}

export interface AccountState {
  user?: User;
  paymentMethods: PaymentMethod[];
  credit?: number;
  loading: boolean;
  loaded: boolean;
  params: AccountListParamsState;
  list: AccountListState;
  details: AccountDetailsState;
}

@StoreConfig({ name: "account" })
export class AccountStore extends Store<AccountState> {
  constructor() {
    super({
      credit: 0,
      loaded: false,
      loading: true,
      paymentMethods: []
    });
  }

  updateList(list: Partial<AccountListState>) {
    this.update((s) => ({
      ...s,
      list: {
        ...s.list,
        ...list
      }
    }));
  }

  updateParams(params: AccountListParamsState) {
    this.update((s) => ({
      ...s,
      params: {
        ...s.params,
        ...params
      }
    }));
  }

  updateDetails(details: Partial<AccountDetailsState>) {
    this.update((s) => ({
      ...s,
      details: {
        ...s.details,
        ...details
      }
    }));
  }
}

export const accountStore = new AccountStore();
