import React, { useState, useEffect } from "react";
import { Button, Spin, Tabs } from "antd";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { OrderModalOrderDetails } from "./order-modal-order-details";
import { OrderModalFiles } from "./order-modal-files";
import { Modal } from "../../modal/modal";
import styles from "./order-modal.module.scss";
import {
  fetchOrder,
  acceptOrder,
  rejectOrder,
  completeOrder,
  OrderFile
} from "../../../api/order.service";
import { orderQuery } from "../../../state/order/order.query";
import { toast } from "react-toastify";
import { Order } from "@getsubly/common";
import { downloadArchive } from "../../../api/media.service";

interface OrderModalProps {
  orderId?: string;
  onClose: () => void;
  onUpdated?: () => void;
}
export const OrderModal: React.FC<OrderModalProps> = ({
  orderId,
  onClose,
  onUpdated
}) => {
  const { TabPane } = Tabs;
  const title = `Order details (${orderId})`;

  const [loading, setLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState<Order>();
  const [orderFiles, setOrderFiles] = useState<OrderFile[]>([]);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    if (!orderId) {
      return;
    }

    fetchOrder(orderId);

    const unsubscribe$ = new Subject();
    orderQuery
      .select()
      .pipe(takeUntil(unsubscribe$))
      .subscribe(({ details }) => {
        setLoading(details.loading);
        setOrderDetails(details.orderDetails);
        setOrderFiles(details.orderFiles);
      });

    return () => unsubscribe$.next();
  }, [orderId]);

  const handleAcceptOrder = () => {
    if (orderId)
      acceptOrder(orderId).then(() => {
        toast.success("Order accepted");
        onUpdated?.();
      });
  };

  const handleRejectOrder = () => {
    if (orderId)
      rejectOrder(orderId).then(() => {
        toast.success("Order rejected");
        onUpdated?.();
      });
  };

  const handleCompleteOrder = () => {
    if (orderId)
      completeOrder(orderId).then(() => {
        toast.success("Order marked as complete");
        onUpdated?.();
      });
  };

  const handleDownloadArchive = async () => {
    const mediaId = orderDetails?.mediaId;

    if (!mediaId) return;

    try {
      setIsDownloading(true);

      const url = await downloadArchive(mediaId);

      if (url) {
        saveAs(url, `${mediaId}.zip`);
      }
    } catch (error) {
      console.error("Failed to download archive:", error);
      toast.error("Failed to download archive: " + (error as any).message);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Modal onDismiss={onClose} className={styles["modal"]} hasCloseIcon>
      <h5 className="mb-3">{title}</h5>
      {loading ? (
        <Spin />
      ) : (
        <Tabs defaultActiveKey="1">
          <TabPane tab="Order" key="1">
            <OrderModalOrderDetails order={orderDetails} />
          </TabPane>
          <TabPane tab="Files" key="2">
            <OrderModalFiles order={orderDetails} files={orderFiles} />
          </TabPane>
        </Tabs>
      )}
      <div className="d-flex align-items-center justify-content-end mt-5">
        {orderDetails?.status === "pending" && (
          <Button
            type="primary"
            className="btn btn-primary"
            onClick={handleAcceptOrder}
          >
            Accept
          </Button>
        )}
        {orderDetails?.status === "in-progress" && (
          <Button
            type="primary"
            className="btn btn-primary tw-mr-auto"
            onClick={handleCompleteOrder}
          >
            Mark as complete
          </Button>
        )}
        {orderDetails?.status === "pending" && (
          <Button
            type="danger"
            className="btn btn-danger tw-mr-auto"
            onClick={handleRejectOrder}
          >
            Reject
          </Button>
        )}
        <Button
          type="primary"
          className="btn btn-primary"
          onClick={handleDownloadArchive}
          disabled={isDownloading}
        >
          Download files
        </Button>
        <Button
          type="primary"
          className="btn btn-primary"
          onClick={() => onClose()}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};
