import React from "react";
import { UserDetailsDatabaseFile } from "../../../api/user.service";
import { Table } from "antd";

interface MediaFileTableProps {
  files: UserDetailsDatabaseFile[];
}

export const MediaFileTable: React.FC<MediaFileTableProps> = ({ files }) => {
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type"
    },
    {
      title: "Filename",
      dataIndex: "filename",
      key: "filename"
    },
    {
      title: "Extension",
      dataIndex: "extension",
      key: "extension"
    },
    {
      title: "Size (bytes)",
      dataIndex: "sizeBytes",
      key: "sizeBytes"
    },
    {
      title: "Duration (seconds)",
      dataIndex: "durationSeconds",
      key: "durationSeconds"
    },
    {
      title: "S3 Url",
      dataIndex: "s3Uri",
      key: "s3Uri"
    },
    {
      title: "Metadata",
      dataIndex: "metadata",
      key: "metadata"
    }
  ];

  return (
    <Table
      scroll={{ x: true }}
      dataSource={files.map((f) => {
        return { key: f.id, ...f };
      })}
      columns={columns}
    />
  );
};
