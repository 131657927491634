import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import AdminLayout from "../layouts/adminLayout";
import { accountQuery } from "../../state/account/account.query";
import { AccountListState } from "../../state/account/account.store";
import { AccountListItem } from "../../api/account.service";
import { fetchAccountList } from "../../api/account.service";
import {
  accountStore,
  AccountTypeEnum
} from "../../state/account/account.store";
import { ApiAccountStats } from "./api-account-stats/api-account-stats";

const getAccountFromList = (
  accountId: string,
  accountList?: AccountListState
): AccountListItem | undefined =>
  accountList?.accountList?.find((account) => account.accountId === accountId);

export const ApiAccountDetails: React.FC = () => {
  let { accountId } = useParams<{ accountId: string }>();
  const [accountItem, setAccountItem] = useState(
    getAccountFromList(accountId, accountQuery.getValue().list)
  );

  useEffect(() => {
    const unsubcribe$ = new Subject();

    accountQuery
      .select()
      .pipe(takeUntil(unsubcribe$))
      .subscribe(({ list }) => {
        setAccountItem(getAccountFromList(accountId, list));
      });

    return () => unsubcribe$.next();
  }, [accountId]);

  useEffect(() => {
    if (!accountItem) {
      console.log("no account item for", accountId);

      accountStore.updateParams({
        filter: "account",
        search: "",
        accountType: AccountTypeEnum.API
      });

      fetchAccountList();
    }
  }, [accountItem, accountId]);

  return (
    <AdminLayout parent="API Accounts List" title="API Account Details">
      {accountItem && <ApiAccountStats account={accountItem} />}
    </AdminLayout>
  );
};
