import React, { useEffect, useState } from "react";
import { Input, Button, Select, Tooltip } from "antd";
import { AccountListItem } from "../../../api/account.service";
import { AccountListParamsState } from "../../../state/account/account.store";
import { formatDate } from "../../../utils/date-formatter";
import { NewTable } from "../../new-table";
import classNames from "classnames";
import { toast } from "react-toastify";
import { bytesToHuman, calcMinutesLeft } from "../../../utils/calc";

const { Option } = Select;

interface AccountTableProps {
  loading: boolean;
  accountList?: AccountListItem[];
  params?: AccountListParamsState;
  onSelect: (value: string) => void;
  onSearch: (filter: string, search: string) => void;
  onResetSearch: () => void;
}
export const AccountTable: React.FC<AccountTableProps> = ({
  loading,
  accountList,
  params,
  onSelect,
  onSearch,
  onResetSearch
}) => {
  const [searchField, setSearchField] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    setSearchField(params?.filter || "");
    setSearchText(params?.search || "");
  }, [params]);

  const filtering = () => {
    return (
      <div className="row">
        <div className="col col-2">
          <Select
            style={{ width: "100%" }}
            onChange={(value: string) => {
              console.log("value:", value);
              setSearchField(value);
            }}
            value={searchField}
          >
            <Option value={"account"}>Account Id</Option>
            <Option value={"name"}>Name</Option>
            <Option value={"ownersId"}>Owner Id</Option>
            <Option value={"ownersEmail"}>Owner's Email</Option>
          </Select>
        </div>
        <div className="col col-6">
          <Input
            onChange={(e) => {
              setSearchText(e?.target?.value);
            }}
            value={searchText}
          ></Input>
        </div>
        <div className="col col-4">
          <Button
            onClick={() => {
              if (searchText !== "" && searchField !== "") {
                onSearch(searchField, searchText.trim());
              }
            }}
            style={{ marginRight: "8px" }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              setSearchField("");
              setSearchText("");
              onResetSearch();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      {filtering()}

      <hr className="rounded tw-my-2" />

      <NewTable
        columns={[
          {
            label: "ID",
            id: "id",
            width: "300px"
          },
          {
            label: "Name (type)",
            id: "name",
            width: "1fr"
          },
          {
            label: "Plan",
            id: "plan",
            width: "1fr"
          },
          {
            label: "Renewal Date",
            id: "renewalDate",
            width: "1fr"
          },
          {
            label: "PAYG?",
            id: "isPayg",
            width: "1fr"
          },
          {
            label: "Balance",
            id: "balance",
            width: "1fr"
          },
          {
            label: "Storage",
            id: "storage",
            width: "1fr"
          },
          {
            label: "Owner",
            id: "owner",
            width: "1fr"
          }
        ]}
        items={accountList || []}
        className="tw-flex tw-min-w-min tw-flex-col tw-overflow-auto"
      >
        <NewTable.Header>
          {(cols) =>
            cols.map((col, i) => {
              const isFirst = i === 0;
              const isLast = cols.length - 1 === i;

              return (
                <NewTable.HeaderCell
                  key={col.id}
                  className={classNames(" !tw-pr-2 tw-font-medium", {
                    "!tw-pl-3": isFirst,
                    "!tw-pl-2": !isFirst,
                    "!tw-pr-3": isLast,
                    "!tw-pr-2": !isLast
                  })}
                >
                  {col.label}
                </NewTable.HeaderCell>
              );
            })
          }
        </NewTable.Header>
        {(accountList || []).map((item, i) => (
          <Row
            key={i}
            item={item}
            onRowClick={() => onSelect(item.accountId)}
          />
        ))}
      </NewTable>
    </>
  );
};

interface RowInnerProps {
  item: AccountListItem;
  onRowClick: (item: AccountListItem) => void;
}
const Row: React.FC<RowInnerProps> = ({ item, onRowClick }) => {
  const [isHovered, setHovered] = React.useState(false);

  const sharedClasses = React.useMemo(() => {
    let classes = "tw-min-h-12 tw-cursor-pointer";
    if (isHovered) classes += " tw-bg-neutral-50 tw-cursor-pointer";
    return classes;
  }, [isHovered]);

  const onClickIdCell = () => {
    navigator.clipboard.writeText(item.accountId);
    toast.success("ID copied to clipboard");
  };

  const onClickCell = () => {
    onRowClick(item);
  };

  return (
    <>
      <NewTable.Cell
        className={classNames(
          sharedClasses,
          "!tw-pl-3 tw-overflow-hidden hover:tw-text-neutral-400"
        )}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickIdCell}
      >
        {item.accountId}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses, "tw-overflow-hidden")}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        {`${item.name} (${item.accountType.type})`}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        {item.billing.trialPlanName
          ? item.billing.trialPlanName + " (TRIAL)"
          : item.billing?.planName || "-"}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        {item.billing?.trialEndAt
          ? formatDate(new Date(item.billing.trialEndAt)) + " (TRIAL END)"
          : item.billing?.renewAt
          ? formatDate(new Date(item.billing.renewAt))
          : "-"}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        {item.isPayg ? "YES" : ""}
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        <Tooltip
          title={Object.entries(item.balance)
            .map((entry) => `${entry[0]}: ${entry[1]}`)
            .join("\r\n")}
        >
          {`${calcMinutesLeft(item)} mins left`}
        </Tooltip>
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        <Tooltip title={item.storageBytesUsed + " bytes"}>
          {bytesToHuman(item.storageBytesUsed)}
        </Tooltip>
      </NewTable.Cell>
      <NewTable.Cell
        className={classNames(sharedClasses)}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={onClickCell}
      >
        {`${item.owner?.name} (${item.owner?.email})`}
      </NewTable.Cell>
    </>
  );
};
